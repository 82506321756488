import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import { set, get } from 'idb-keyval';

import {realClearRedux, realCreateRedux} from "@redux/tomograms/actions";

import loadRaw from "../../../libs/secondaryFunctions/loadRaw";

import {unzipToArrayBuffer} from "@utils/index";
import patientGetArchiveRequest from "@api/patient/getArchive";
import syntheticDataGetRequest from "@api/synthetic-data/get";

import useReduxLoaders from "@hooks/useReduxLoaders";
import {editableProtocolSetRedux} from "@redux/protocol/actions";
import {roiPointActiveSetRedux} from "@redux/ROIPoint/actions";
import {syntheticClearRedux, syntheticToggleRedux} from "@redux/syntheticTomograms/actions";
import {labelMeshClearRedux} from "@redux/labelTomogram/actions";

import {syntheticStore, rawStore} from "../../../idbStore/init";
import {buildFileUrl} from "@api/settings";


const useInspection = () => {
	
	const dispatch = useDispatch();
	const {patientId, dataId} = useParams();
	
	const {tomogramLoader, surfaceLoader, surfaceMapLoader} = useReduxLoaders();
	const [isArchiveLoading, setIsArchiveLoading] = useState(false);
	const [progress, setProgress] = useState(null);
	const [data, setData] = useState(null);
	
	const {cacheEnabled} = useSelector((state) => state.cache);
	const {activeTmSliceIndexes} = useSelector((state) => state.tomograms);
	const surfaceMaps = useSelector((state) => state.surfaceMaps);
	
	const getDICOM = async (data) => {
		
		const arch = await get(data.id,rawStore);
		let files;
		if (arch ===undefined){
			const archive = await patientGetArchiveRequest(decodeURIComponent(data.path),setProgress);
			files = await unzipToArrayBuffer(archive);
			await set(data.id, files, syntheticStore);
		} else {
			
			files = arch;
		}
		const __obj = await loadRaw(files);
		
		await dispatch(realCreateRedux(__obj));
		setIsArchiveLoading(false);
		
		dispatch(syntheticToggleRedux(false));
		setProgress(null);
	};
	
	const __getArchiveWithCache = async (data) => {
		const arch = await get(data.id,syntheticStore);
		
		let files;
		if (arch ===undefined){
			
			files = await __getArchive__(data);
			if (cacheEnabled) {
				await set(data.id, files, syntheticStore);
			}
		} else {
			
			files = arch;
		}
		return files;
	};
	
	const __getArchive__ = async (data) => {
		// const archive = await patientGetArchiveRequest(decodeURIComponent('/__data/sub-15_nii_brain__no_pial.zip'),setProgress);
		// const archive = await patientGetArchiveRequest(decodeURIComponent('/__data/sub-15_nii_brain.zip'),setProgress);
		// const archive = await patientGetArchiveRequest(decodeURIComponent('/__data/sub-15_inflated_nii_tiny.zip'),setProgress);
		const archive = await patientGetArchiveRequest(decodeURIComponent(buildFileUrl(data.path)),setProgress);
		const files = await unzipToArrayBuffer(archive);
		
		return files;
	};
	
	const getArchive = async (data) => {
		
		const files = await __getArchiveWithCache(data);
		
		let loader = null;
		
		for (const file of files) {
			
			if (/.nii/.test(file.url) || /.mgz/.test(file.url)) {
				loader = tomogramLoader;
			} else if (/.pial/.test(file.url) || /.inflated/.test(file.url)) {
				loader = surfaceLoader;
			} else if (/.thickness/.test(file.url)) {
				loader = surfaceMapLoader;
			} else {
				loader = null;
			}
			
			loader !== null && loader(file, data.id);
		}
		
		setIsArchiveLoading(false);
		dispatch(syntheticToggleRedux(true));
		setProgress(null);
	};
	
	useEffect(() => {
		syntheticDataGetRequest(dataId)
			.then(({isSuccess,data}) => {
				if (isSuccess) {
					setData(data);
					// console.log(data)
					setIsArchiveLoading(true);
					
					// data.origin === 'raw'
					false
						? getDICOM(data).catch(e => console.error(e))
						: getArchive(data).catch(e => console.error(e));
				}
			}).catch(e => console.error(e));
		
		return () => {
			dispatch(realClearRedux());
			dispatch(syntheticClearRedux());
			dispatch(labelMeshClearRedux());
			dispatch(editableProtocolSetRedux({protocolId:null}));
			dispatch(roiPointActiveSetRedux(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patientId]);
	
	useEffect(() => {
	}, [activeTmSliceIndexes]);
	useEffect(() => {
	}, [surfaceMaps]);
	
	return {
		isArchiveLoading,
		progress,
		data,
	};
};
export default useInspection;