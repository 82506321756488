const translates = {
	en: {
		origin: 'Origin',
		data: 'Data',
		modality: 'Modality',
		submitButtonTitle: 'Create',
		submitButtonProcessTitle: 'loading',
	},
	ru: {
		origin: 'Origin',
		data: 'Инструментальные исследования',
		modality: 'Модальность',
		submitButtonTitle: 'Создать',
		submitButtonProcessTitle: 'загрузка',
	}
};
export default translates;