import React, {useState} from "react";
import {useSelector} from "react-redux";

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import SurfaceCanvas from "../canvas/SurfaceCanvas";
import TomogramContainer from "../TomogramContainer";

import perspectives from "./perspectives";

import './index.scss';


const TomogramsGrid = ({className = ""}) => {
	
	const [isFullScreen3D, setIsFullScreen3D] = useState(false);
	const {rayCast} = useSelector((state) => state.rayCast);
	const handleDoubleClick = (e)=>{
		setIsFullScreen3D(!isFullScreen3D);
		e.stopPropagation();
	};
	return (
		<div className={"TomogramsGrid " + className}>
			
			{/* 3D */}
			<div className={"TomogramsGrid__item"}>
				<div className={`canvasDiv model ${!isFullScreen3D ? "_inactive" : "_active"}`}>
					<div className={'canvasDiv__tomogram'} onDoubleClick={handleDoubleClick}>
						<SurfaceCanvas/>
					</div>
					<span
						className={'canvasDiv__caption'}>3D <span>( rayCast: x={rayCast.x} y={rayCast.y} z={rayCast.z} )</span></span>
					<span className={'canvasDiv__fullscreen-icon'} onClick={() => setIsFullScreen3D(!isFullScreen3D)}>
						{isFullScreen3D ? <FullscreenExitIcon/> : <FullscreenIcon/>}
					</span>
				</div>
			</div>
			
			{/* Perspectives */}
			{perspectives.map((perspective, i) => (
				<div className={"TomogramsGrid__item"} key={i}>
					<TomogramContainer perspective={perspective}/>
				</div>
			))}
		
		</div>
	);
};
export default React.memo(TomogramsGrid);