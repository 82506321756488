import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

import usePatient from "@hooks/usePatient";
import {useDispatch, useSelector} from "react-redux";
import {patientCreateRedux, patientDeleteRedux} from "@redux/patient/actions";


const usePatientSingle = () => {
	
	const dispatch = useDispatch();
	const {patientId} = useParams();
	const [patientRequestError, setPatientRequestError] = useState("");
	const [patientCodeError, setPatientCodeError] = useState("");
	const [patientStatusError, setPatientStatusError] = useState("");
	
	const {loading, getSingleSubject} = usePatient({patientId});
	const {patient} = useSelector((state) => state.patient);
	
	const handleError = (e) => {
		setPatientRequestError('произошла ошибка запроса (' + e.message + ')');
		console.error(e)
	};
	
	const tryAgain = () => {
		setPatientRequestError("");
		getSingleSubject()
			.catch(handleError);
	};
	
	const handleSuccess = (response) => {
		const {isSuccess, code, status, data} = response;
		if (isSuccess){
			dispatch(patientCreateRedux(data));
		} else {
			setPatientCodeError(code);
			setPatientStatusError(status);
		}
	};
	
	useEffect(() => {
		return function cleanup() {
			dispatch(patientDeleteRedux());
		};
	},[dispatch]);
	
	
	useEffect(() => {
		setPatientRequestError("");
		getSingleSubject()
			.then(handleSuccess)
			.catch(handleError)
			
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patientId]);
	
	return {
		loading,
		tryAgain,
		patient,
		patientRequestError,
		patientCodeError,
		patientStatusError,
	};
};

export default usePatientSingle;