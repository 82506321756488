import * as THREE from "three";

import CoreUtils from "../libs/ami-legacy/core/core.utils";
import {RASToLPS} from "./index";


const matrixTransformWithWorldCenter = (spacing, point, lps2IJK, worldCenter) => {
	let RASToLPSMatrix = new THREE.Matrix4()
		.set(
			-spacing.x, 0, 0, worldCenter.x,
			0, -spacing.y, 0, worldCenter.y,
			0, 0, spacing.z, worldCenter.z,
			0, 0, 0, 1
		);
	
	const v3 = RASToLPS(RASToLPSMatrix, point);
	return CoreUtils.worldToData(lps2IJK, v3);
};
export default matrixTransformWithWorldCenter;