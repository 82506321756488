import {useCallback, useEffect} from 'react';
import {useDispatch} from "react-redux";

import rawDataDeleteRequest from "@api/raw-data/delete";

import {processingsByDataIdSetRedux} from "@redux/processing/actions";
import {patientArchiveDeleteRedux} from "@redux/patient/actions";
import useRawData from "@hooks/useRawData";


const useDataAccordion = ({dataItem}) => {
	
	const dispatch = useDispatch();
	const {getRawDataProcessings} = useRawData();
	
	const handleDeleteClick = async () => {
		await rawDataDeleteRequest(dataItem.id);
		dispatch(patientArchiveDeleteRedux(dataItem.id));
	};
	
	const getDataProcessings = useCallback(async ()=>{
		const {isSuccess, data} = await getRawDataProcessings(dataItem.id);
		isSuccess && dispatch(processingsByDataIdSetRedux({dataId:dataItem.id,proccessingList:data}));
	},[ dispatch, dataItem.id, getRawDataProcessings]);
	
	useEffect(()=>{
		getDataProcessings()
			.catch(console.error)
	},[getDataProcessings]);
	
	return {
		handleDeleteClick
	};
};
export default useDataAccordion;