export const translates = {
	en: {
		submitTitle: 'Create',
		submitProcessTitle: 'Loading',
	},
	ru: {
		submitTitle: 'Создать',
		submitProcessTitle: 'Загрузка',
	}
};
export const groupTranslates = {
	en: {
		personal_data: 'Personal data',
		medical_data: 'Medical data',
	},
	ru: {
		personal_data: 'Персональные данные',
		medical_data: 'Медицинские данные',
	}
};
export const fieldTranslates = {
	en: {
		first_name: 'First name',
		last_name: 'Last name',
		gender: 'Gender',
		diagnosis: 'Diagnosis',
		comorbidity: 'Comorbidity',
	},
	ru: {
		first_name: 'Имя',
		last_name: 'Фамилия',
		gender: 'Пол',
		diagnosis: 'Диагноз',
		comorbidity: 'Сопутствующий диагноз',
	}
};