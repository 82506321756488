import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PROTOCOLS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const protocolGetRoiRequest = async (protocolId) => {
	
	const result = await axiosService.get(buildUrl(`/${PROTOCOLS_ROOT}/${protocolId}/rois`));
	
	return buildResponse(result);
};
export default protocolGetRoiRequest;