const fields = [
	/*{name: "origin", label: "origin", type: "select", required: true, items:[{key:"raw", value:"raw"},{key:"synth", value:"synth"}], disabled:true},*/
	{name: "raw_data", label: "Инструментальные исследования", type: "file", required: false},
	{name: "modality", label: "Модальность", type: "select", required: true,  items:[{key:"T1", value:"T1"},{key:"T2", value:"T2"},{key:"FLAIR", value:"FLAIR"}]},
];

const initialValues = {
	/*origin: 'raw',*/
	raw_data: undefined,
	modality: "T1",
};

export {fields, initialValues};