// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(41, 45, 57, 0.5);
  z-index: 100000000;
}

.preloader {
  margin: auto;
  text-align: center;
  background: #292d39;
  color: white;
  padding: 16px 32px;
}
.preloader__title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}
.preloader__text {
  font-size: 16px;
  line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Viewer/components/Preloader/Preloader.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,iCAAA;EACA,kBAAA;AACJ;;AACA;EACI,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AAEJ;AADI;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AAGR;AADI;EACI,eAAA;EACA,iBAAA;AAGR","sourcesContent":[".preloaderOverlay {\n    position:fixed;\n    top:0;\n    left:0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    background: rgba(41,45,57,.5);\n    z-index:100000000;\n}\n.preloader {\n    margin: auto;\n    text-align: center;\n    background:#292d39;\n    color:white;\n    padding:16px 32px;\n    &__title {\n        font-size: 20px;\n        line-height: 24px;\n        font-weight: 500;\n        margin-bottom: 16px;\n    }\n    &__text {\n        font-size: 16px;\n        line-height: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
