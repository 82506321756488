import {toast} from "react-toastify";
import {useDispatch} from "react-redux";

import {processingsByDataIdAddRedux} from "@redux/processing/actions";
import rawDataStartProcessRequest from "@api/raw-data/startProcess";


const params = ['all','blurring','other'];

const useRunProcessButton = ({dataId}) => {
	
	const dispatch = useDispatch();
	
	
	const createProcessing = async (dataId, newProcessing)=>{
		
		const {isSuccess, data} = await rawDataStartProcessRequest(dataId, newProcessing);
		
		if ( isSuccess ){
			
			await dispatch(processingsByDataIdAddRedux({dataId,processing:data}));
			
			return data;
		} else {
			return null;
		}
	};
	
	const handleCountButtonClick = async (selectedIndex, event)=>{
		
		event.stopPropagation();
		
		const createdProcessing = await createProcessing(dataId,{modality: params[selectedIndex]});
		
		createdProcessing === null
			? toast.error("невозможно запустить расчет!", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000})
			: toast.success(`проверки пройдены расчет запущен (${params[selectedIndex]})!`, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});
	};
	
	return {
		handleCountButtonClick
	};
};

export default useRunProcessButton;