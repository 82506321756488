import React, {} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import CircularProgressWithLabel from "@ui/CircularProgressWithLabel";
import InspectionLayout from "@ui/layout/InspectionLayout";

import ViewerMenu from "@components/ViewerMenu";

import TomogramsGrid from "../../../components/TomogramsGrid";
import InspectionRoiPointListHead from "./components/ROIPointListHead/ROIPointListHead";
import InspectionProtocolStateRow from "./components/ProtocolStateRow/ProtocolStateRow";
import InspectionProtocolList from "./components/ProtocolList/ProtocolList";
import CommentsBlock from "../Protocols/SingleProtocol/components/CommentsBlock/CommentsBlock";

import ROIPointList from "@ROIPoint/List/ROIPointList";
import useInspection from "./useInspection";

import Toolbar from "../../Viewer/components/Toolbar";
import "./../../Viewer/index.scss";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const PatientSingleInspection = () => {
	
	const navigate = useNavigate();
	
	const {isArchiveLoading, progress} = useInspection();
	const {protocolsTitle, backButtonTitle} = useTranslate({translates});
	
	const {real} = useSelector((state) => state.tomograms);
	const {roiPointActive} = useSelector((state) => state.ROIPoint);
	
	const handleBackButtonClick = () => navigate(-1);
	
	return (
		<>
			{progress !== null ? (
				<Stack alignItems={'center'} justifyContent={'center'} sx={{position: "relative", height: '100vh'}}>
					<Stack alignItems={'center'}>
						<CircularProgressWithLabel value={progress} />
						{isArchiveLoading && <div style={{marginTop:"10px"}}>loading...</div>}
					</Stack>
				</Stack>
			):(
				<InspectionLayout>{{
					main: (
						<div className="workMainDiv" style={{width:'calc(100vw - 320px)'}}>
							<div className="canvasContainer">
								<Button variant='contained' size='small' onClick={handleBackButtonClick} sx={{mt: 2, ml:2, position:'absolute', zIndex:10}}>{backButtonTitle}</Button>
								{real.length <= 0 && !isArchiveLoading && <div>nothing to show</div>}
								{real.length > 0 && !isArchiveLoading && (<>
									<ViewerMenu/>
									<TomogramsGrid/>
									<Toolbar cls={'fixed'}/>
								</>)}
							</div>
						</div>
					),
					aside: (
						<Box sx={{p: 2, boxSizing: "border-box"}}>
							<Stack sx={{height: 300, width:'100%'}}>
								<InspectionRoiPointListHead isArchiveLoading={isArchiveLoading}/>
								<ROIPointList sx={{width: '100%', bgcolor: 'background.paper', mb: 1}}/>
							</Stack>
							<InspectionProtocolStateRow/>
							<Divider sx={{my: 2}}/>
							<Typography component='h3' variant='h6'>{protocolsTitle}</Typography>
							
							<InspectionProtocolList/>
							<Divider sx={{my: 2}}/>
							{roiPointActive !==null && (<CommentsBlock/>)}
						</Box>
					)
				}}
				</InspectionLayout>
			)}
		</>
	);
};
export default PatientSingleInspection;
