import {useState} from "react";
import {useNavigate} from "react-router-dom";

import routes from "../../../routes";
import {fields, initialValues, groups} from "./fields";
import profileCreatePatientRequest from "@api/profile/createPatient";


const usePatientCreateForm = () => {
	
	const navigate = useNavigate();
	const [progress, setProgress] = useState(null);
	
	const onSubmit = async (values, {setSubmitting, setStatus, setErrors}) => {
		// if (!localStorage.getItem('login')) return false;
		
		// const profile = JSON.parse(localStorage.getItem('login'));
		
		// const {isSuccess, data, message, errors} = await doctorCreatePatientRequest(profile.id, values, setProgress);
		const {isSuccess, data, message, errors} = await profileCreatePatientRequest(values, setProgress);
		if (isSuccess) {
			isSuccess && navigate(`${routes.createPatientSuccess}/${data.id}`);
		} else {
			setStatus(message);
			setTimeout(() => setStatus(""), 3000);
			errors && setErrors(errors.json);
		}
		
		setSubmitting(false);
	};
	return {
		onSubmit,
		fields,
		initialValues,
		groups,
		progress,
	};
};
export default usePatientCreateForm;