import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {processingsSetRedux} from "@redux/processing/actions";
import doctorGetProcessingsRequest from "@api/doctor/getProcessings";


const useProcessingListHome = () => {
	
	const dispatch = useDispatch();
	const {processings} = useSelector((state) => state.processing);
	const {profile} = useSelector((state) => state.profile);
	
	const getProcessings = async () => {
		
		const result = await doctorGetProcessingsRequest(profile.id);

		dispatch(processingsSetRedux(result.data))
	};
	
	useEffect(() => {
		getProcessings()
			.catch((error) => console.error(error));
	}, []);
	
	return {
		processings,
	};
};
export default useProcessingListHome;