import React, {} from 'react';

import ProtocolList from "@components/Protocol/List/ProtocolList";

import PageTitle from "@ui/PageTitle";
import DrawerLayout from "@ui/layout/DrawerLayout";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const PatientSingleProtocols = () => {
	const {pageTitle} = useTranslate({translates});
	return (
		<DrawerLayout>
			<PageTitle text={pageTitle} sx={{mb: 2}}/>
			<ProtocolList/>
		</DrawerLayout>
	);
};
export default PatientSingleProtocols;
