import * as THREE from "three";

import {makeAsyncRequest} from "@utils/index";


const FreeSurferLoader = function () {};

Object.assign(FreeSurferLoader.prototype, THREE.EventDispatcher.prototype, {
  constructor: FreeSurferLoader,

  loadAsync: function (url) {
    return makeAsyncRequest('GET', url, "text/plain; charset=x-user-defined","arraybuffer")
      .then( response => {
        return this.parse(response);
      })
      .catch( err => {
        console.error('Augh, there was an error!', err.statusText);
      });
  },

  littleEndian: function () {
    let buffer = new ArrayBuffer(2);
    new DataView(buffer).setInt16(0, 256, true);

    return new Int16Array(buffer)[0] === 256;
  },

  parse: function (data) {
    let littleEndian = this.littleEndian();
    let reader = new DataView(data);
    let offset = 0;
    let geometry = undefined;

    function readInt24(offset, littleEndian = false) {
      let v = 0;
      let b1 = reader.getUint8(offset);
      let b2 = reader.getUint8(offset + 1);
      let b3 = reader.getUint8(offset + 2);
      if (littleEndian) {
        v += b3 << 16;
        v += b2 << 8;
        v += b1;
      } else {
        v += b1 << 16;
        v += b2 << 8;
        v += b3;
      }
      return v;
    }

    let surfType = readInt24(0);
    if (surfType > 0xffff00) {
      littleEndian = false;
    } else {
      littleEndian = true;
      surfType = readInt24(0, littleEndian);
    }
    offset += 3;

    let vertCount = 0;
    let faceCount = 0;
    switch (surfType) {
      case FreeSurferLoader.TRIANGLE_FILE_MAGIC_NUMBER: {
        let b1 = 0;
        let b2 = 0;
        while (b1 !== 10 && b2 !== 10) {
          b1 = b2;
          b2 = reader.getUint8(offset);
          offset++;
        }
        offset++;
        vertCount = reader.getInt32(offset, littleEndian);
        offset += 4;
        faceCount = reader.getInt32(offset, littleEndian);
        offset += 4;

        let geometryVertices = [];
        geometry = new THREE.BufferGeometry();
        for (let v = 0; v < vertCount; v++) {
          geometryVertices.push(reader.getFloat32(offset + 0, littleEndian));
          geometryVertices.push(reader.getFloat32(offset + 4, littleEndian));
          geometryVertices.push(reader.getFloat32(offset + 8, littleEndian));
          offset += 12;
        }
        const positionsArray = new Float32Array(geometryVertices);
        const positionsAttribute = new THREE.BufferAttribute(positionsArray, 3);
        geometry.setAttribute("position", positionsAttribute);

        let geometryFace = [];
        for (let f = 0; f < faceCount; f++) {
          geometryFace.push(reader.getInt32(offset + 0, littleEndian));
          geometryFace.push(reader.getInt32(offset + 4, littleEndian));
          geometryFace.push(reader.getInt32(offset + 8, littleEndian));
          offset += 12;
        }
        geometry.setIndex(geometryFace);
        geometry.computeBoundingBox();
        geometry.computeVertexNormals();
        break;
      }
      case FreeSurferLoader.NEW_QUAD_FILE_MAGIC_NUMBER: {
        throw Error("Parser not defined for  NEW_QUAD_FILE_MAGIC_NUMBER");
      }
      default: {
        throw Error("Unknown FreeSurfer MAGICNUMBER: " + surfType.toString(16));
      }
    }
    return geometry;
  },
});

FreeSurferLoader.QUAD_FILE_MAGIC_NUMBER = -1 & 0x00ffffff;
FreeSurferLoader.TRIANGLE_FILE_MAGIC_NUMBER = -2 & 0x00ffffff;
FreeSurferLoader.NEW_QUAD_FILE_MAGIC_NUMBER = -3 & 0x00ffffff;

export { FreeSurferLoader };
