import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import Typography from "@mui/material/Typography";
import UIModal from "@ui/UIModal/UIModal";

import PatientEditForm from "../EditForm/PatientEditForm";


const editableProps = ['first_name', 'last_name', 'gender', 'diagnosis', 'comorbidity'];

const PatientEditModal = ({open, setOpen}) => {
	
	const {patient} = useSelector((state) => state.patient);
	
	const [initialPatient, setInitialPatient] = useState(null);
	
	useEffect(() => {
		
		const obj = editableProps.reduce((acc, prop) => ({...acc, [prop]: patient[prop]}), {});
		setInitialPatient(obj)
		
	}, [patient]);
	
	return (
		<UIModal isOpen={open} setIsOpen={setOpen}>
			<Typography variant="h6" component="h2" sx={{mb: 2}}>Редактирование пациента</Typography>
			{initialPatient !== null && <PatientEditForm initial={initialPatient} patientId={patient.id} setOpen={setOpen}/>}
		</UIModal>
	);
};
export default PatientEditModal;