import {Vector3} from "three";


const getTextPosition = (rN, point) => {
	if (rN !== 'r1' && rN !== 'r2' && rN !== 'r3') {
		throw new Error("wrong rN");
	}
	switch (rN) {
		case 'r1':
			return new Vector3(point.x, point.y, point.z - 2);
		case 'r2':
			return new Vector3(point.x + 0.5, point.y, point.z);
		case 'r3':
			return new Vector3(point.x - 0.5, point.y - 0.5, point.z);
		default:
			return new Vector3()
	}
};
export default getTextPosition;