import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import profileGetRequest from "@api/profile/get";
import {profileSetRedux} from "@redux/profile/actions";


const useProfilePage = () => {
	
	const dispatch = useDispatch();
	
	const [isLoading,setIsLoading] = useState(false);
	
	const {profile} = useSelector((state) => state.profile);
	
	const getProfile = useCallback(async () => {
		setIsLoading(true);
		const {isSuccess, data} = await profileGetRequest();
		isSuccess && dispatch(profileSetRedux(data));
		setIsLoading(false);
		
	},[dispatch]);
	
	useEffect(()=>{
		
		profile === null && getProfile()
			.catch((error)=>console.log(error))
			.finally(()=>setIsLoading(false));
		
	},[profile,getProfile]);
	
	return {
		profile,
		isLoading,
	};
};
export default useProfilePage;