import React from 'react';

import List from "@mui/material/List";
import ListEmptyStateWrap from "@ui/ListEmptyStateWrap/ListEmptyStateWrap";
import CommentListItem from "@components/Comment/Item/CommentListItem";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const CommentsList = ({comments}) => {
	const {empty} = useTranslate({translates});
	return (
		<ListEmptyStateWrap length={comments.length} emptyText={empty}>
			<List dense={true}>
			{ comments.map((comment, i) => (
				<CommentListItem comment={comment} key={i}/>
			))}
			</List>
		</ListEmptyStateWrap>
	);
};
export default CommentsList;