import React from 'react';
import ListSubheader from "@mui/material/ListSubheader";
import NavItem from "./NavItem";
import List from "@mui/material/List";

const NavList = ({navTitle, navList}) => {
	return (
		<List subheader={navTitle && <ListSubheader>{navTitle}</ListSubheader>}>
			{navList.map((item, index) => (
				<NavItem item={item} key={index}/>
			))}
		</List>
	);
};

export default NavList;