import React from 'react';

import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FavoriteIcon from "@mui/icons-material/Favorite";

import NavList from "./components/NavList";

import routes from "../../routes";
import useTranslate from "@hooks/useTranslate";

import {listMainTranslates, listLibTranslates, libTitleTranslates} from './translates'
import {MAIN_DRAWER_WIDTH} from "../../constants";


const NavDrawer = () => {
	
	const {title} = useTranslate({translates:libTitleTranslates});
	const {allPatients} = useTranslate({translates:listMainTranslates});
	const {myPatients, favorite} = useTranslate({translates:listLibTranslates});
	
	const listMain = [{url:routes.patientChoose, text:allPatients, icon:PeopleAltIcon}];
	const listLibrary = [{url:routes.myPatients,text:myPatients, icon:PeopleAltIcon}, {url:routes.favorite,text:favorite, icon:FavoriteIcon}];
	
	return (
		<Drawer
			sx={{
				width: MAIN_DRAWER_WIDTH,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: MAIN_DRAWER_WIDTH,
					boxSizing: 'border-box',
				},
			}}
			variant="permanent"
			anchor="left"
		>
			<Toolbar />
			<Divider />
			<NavList navList={listMain}/>
			<Divider />
			<NavList navTitle={title} navList={listLibrary}/>
		</Drawer>
	);
};
export default NavDrawer;