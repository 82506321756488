import React, {useEffect} from "react";
import {ToastContainer} from "react-toastify";

import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

import AppRouter from "./AppRouter";

// import * as AMI from 'ami.js'
import useApp from "./useApp";

// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';

import "./App.css";
import {useSelector} from "react-redux";

// import helpersStack from "./libs/loaders/helpers.stack";


const darkTheme = createTheme({
	palette: {
		mode: "light"
		// mode: 'dark',
	},
});

function App() {
	
	const { checkCacheEnabled, setAppLang } = useApp();
	const {profile} = useSelector((state) => state.profile);
	//
	checkCacheEnabled();
	
	//
	setAppLang();
	
	useEffect(()=>{},[profile]);
	
	return (
		<ThemeProvider theme={darkTheme}>
			<div className="App">
				<AppRouter/>
			</div>
			<ToastContainer/>
		</ThemeProvider>
	);
}

export default App;
