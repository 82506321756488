import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {MEDRECORD_ROOT} from "../constants";
import collectFormData from "../collectFormData";
import buildResponse from "../buildResponse";


const medrecordCreateRequest = async (values) => {
	
	const formData = collectFormData({...values});
	const config = {headers: {'Content-Type': 'multipart/form-data'}};
	
	const result = await axiosService.post(buildUrl(`/${MEDRECORD_ROOT}`), formData, config);
	
	return buildResponse(result);
};
export default medrecordCreateRequest;