import update from 'immutability-helper';
import {
	STACK_CACHE_ADD
} from "./constants";


const initialStackCacheState = {
	stackCache: {},
};

export const stackCache = (state = initialStackCacheState, action) => {
	switch (action.type) {
		case STACK_CACHE_ADD:
			return update(state,{ stackCache:{[action.payload.cacheId]:{$set:action.payload.data}} });
		default:
			return state;
	}
};
