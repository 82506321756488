/*
* a - obj - {x,y,z} || [x,y,z]
* b - obj - {x,y,z} || [x,y,z]
* */
const getVectorDistance = (a, b) => {
	const _a = Array.isArray(a) ? { x:a[0], y:a[1], z:a[2] } : a;
	const _b = Array.isArray(b) ? { x:b[0], y:b[1], z:b[2] } : b;

	const dx = _a.x - _b.x;
	const dy = _a.y - _b.y;
	const dz = _a.z - _b.z;

	return Math.sqrt(dx * dx + dy * dy + dz * dz);
};
export default getVectorDistance;