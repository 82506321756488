import React from "react";
import {useSelector} from "react-redux";

import Segment from "../../elements/Segment";
import Orb from "../../elements/Orb";
import Ruler from "../../elements/Ruler";
import Caption from "../../elements/Caption";


const ExtraPoints = ({rN}) => {
	
	const {real, activeRealIndex} = useSelector((state) => state.tomograms);
	const {extraPoints} = useSelector((state) => state.drawLayer);
	
	return (
		<>
			{extraPoints.map((point, i) =>
				<React.Fragment key={i}>
					{(point.type === 'text') && <Caption point={point} rN={rN}
					                                     visibility={real[activeRealIndex][rN].stackHelper.index === point.tmData[rN]}/>}
					{(point.type === 'ruler') && <Ruler start={point.start} end={point.end} rN={rN}
					                                    visibility={real[activeRealIndex][rN].stackHelper.index === point.tmData[rN]}/>}
					{(point.type === 'line') && <Segment key={i} start={point.start} end={point.end}
					                                     visibility={real[activeRealIndex][rN].stackHelper.index === point.tmData[rN]}/>}
					{(point.type === 'point') &&
					<Orb key={i} point={point} visibility={real[activeRealIndex][rN].stackHelper.index === point.tmData[rN]}/>}
				</React.Fragment>
			)}
		</>
	);
};
export default ExtraPoints;