import * as THREE from "three";
import {Lut} from "three/examples/jsm/math/Lut";


const makeIdentityLutTexture = function() {
    

    return function(filter, colormap) {
        let lut = new Lut(colormap,6);
        const colors2 = [];
        lut.lut.map( color => colors2.push(color.r*255, color.g*255, color.b*255, 255) );
        const identityLUT = new Uint8Array([ 0, 0, 0, 0, ...colors2 ]); // first item is background
        
        const texture = new THREE.DataTexture(identityLUT, 8, 1, THREE.RGBAFormat);
        texture.minFilter = filter;
        texture.magFilter = filter;
        texture.needsUpdate = true;
        texture.flipY = false;
        return texture;
    };
}();
export default makeIdentityLutTexture;