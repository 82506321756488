import React, {} from 'react';

import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import ProcessingListHome from "@components/Processing/ListHome/ProcessingListHome";

import useProcessingBlock from "./useProcessingBlock";


const ProcessingBlock = () => {
	
	const {trans} = useProcessingBlock();
	return (
		<Card sx={{mb:4}}>
			<CardHeader title={trans.blockTitle}/>
			<Divider/>
			<CardContent>
				<ProcessingListHome/>
			</CardContent>
		</Card>
	);
};
export default ProcessingBlock;