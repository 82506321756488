import React, {} from 'react';

import IconButton from '@mui/material/IconButton';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import useROIPointItem from "./useGltfROIPointItem";


const GltfROIPointItem = ({point}) => {
	
	const {handleDeleteClick, handleItemClick, isActive} = useROIPointItem({point});
	
	return (
		<ListItemButton selected={isActive} onClick={handleItemClick} sx={{cursor: "pointer"}} divider>
			<ListItemIcon>
				<Tooltip disableFocusListener disableTouchListener title={<div><div><b>blurring</b>: value;</div><div><b>thickness</b>: value;</div></div>}>
					<IconButton size="small">
						<RadioButtonCheckedIcon color={isActive ? 'success' : "disabled"}/>
					</IconButton>
				</Tooltip>
			</ListItemIcon>
			<ListItemText primary={`id: ${point.id || 'empty'}`}/>
			<IconButton size="small" onClick={handleDeleteClick}>
				<DeleteIcon fontSize="inherit"/>
			</IconButton>
		</ListItemButton>
	);
};
export default GltfROIPointItem;
