import React from 'react';
import {useNavigate} from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";


const NavItem = ({item}) => {
	
	const navigate = useNavigate();
	
	return (
		<ListItem disablePadding>
			<ListItemButton onClick={()=>navigate(item.url)} selected={item.url === window.location.pathname}>
				<ListItemIcon>
					<item.icon/>
				</ListItemIcon>
				<ListItemText primary={item.text} />
			</ListItemButton>
		</ListItem>
	);
};
export default NavItem;