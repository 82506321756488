import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useControls, buttonGroup} from "leva";

import {lutCounterSetRedux, realUpdateActiveIndexRedux } from "@redux/tomograms/actions";
import {labelMeshShowToggleRedux} from "@redux/labelTomogram/actions";
import {syntheticToggleRedux} from "@redux/syntheticTomograms/actions";
import {geometryUpdateColorRedux} from "@redux/geometry/actions";

import useChangeLut from "../useChangeLut";


function RealTomogramsFolder() {

	const dispatch = useDispatch();
	const {changeLut} = useChangeLut();

	const { activeRealIndex, real, lutCounter} = useSelector((state) => state.tomograms);
	const {showSynthetic, synthetic} = useSelector((state) => state.syntheticTomograms);
	
	const {labelMeshShow, labelMesh} = useSelector((state) => state.labelTomogram);
	
	const geometryState = useSelector((state) => state.geometry);
	const {activeGeometry, colormap} = useSelector((state) => state.geometry);
	const {mapsList, activeSurfaceMap} = useSelector((state) => state.surfaceMaps);

	const increase = (factor) => {
		dispatch(lutCounterSetRedux(lutCounter + factor));
	};
	const decrease = (factor) => {
		if ((lutCounter - factor) < 1) return;
		dispatch(lutCounterSetRedux(lutCounter - factor));
	};
	// eslint-disable-next-line no-empty-pattern
	const [{},set] = useControls('Tomograms', () => ({
		showSynthetic: {
			label: 'Show synthetic',
			value: showSynthetic,
			onChange: (v) => {
				dispatch(syntheticToggleRedux(v))
			},
			disabled: synthetic.length === 0
		},
		showLabel: {
			label: 'Show label',
			value: labelMeshShow,
			onChange: (v) => {
				dispatch(labelMeshShowToggleRedux(v))
			},
			disabled: labelMesh === null
		},
		_real: {
			label: 'real',
			value: (real.length === 0) ? 'choose tomogram' : activeRealIndex,
			options: (real.length === 0) ? ['choose tomogram'] : real.reduce((acc, tm, i) => ({
				...acc,
				[tm.filename.split(".")[0]]: i
			}), {}),
			onChange: (v) => {
				((v !== 'choose tomogram') && (v !== activeRealIndex)) && dispatch(realUpdateActiveIndexRedux(v))
			},
			disabled: real.length === 0
		},
		_lut: {
			label: 'Lut intensity',
			value: lutCounter || 1,
			disabled: true,
		},
		' ': buttonGroup({
			label: null,
			opts: {
				'+1': () => increase(1),
				'+0.1': () => increase(0.1),
				'-0.1': () => decrease(0.1),
				'-1': () => decrease(1),
			},
		}),
	}), [
		real,
		synthetic,
		labelMesh,
		lutCounter,
	]);

	useEffect(() => {
		if (mapsList.length !== 0 && (mapsList[activeSurfaceMap].left || mapsList[activeSurfaceMap].right)) {
			if (geometryState[activeGeometry].left || geometryState[activeGeometry].right) {
				changeLut(activeSurfaceMap, activeGeometry).then(data=>{
					dispatch(geometryUpdateColorRedux({data, type: activeGeometry}));
				});
				
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lutCounter, colormap]);

	useEffect(() => {
		// set({ _synthetic:activeSyntheticIndex})
	}, [synthetic, labelMesh]);
	useEffect(() => {
		set({_real: activeRealIndex})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [real]);
	useEffect(() => {
		set({_lut: lutCounter})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lutCounter]);

	return <></>
}

export default RealTomogramsFolder;
