const translates = {
	en: {
		protocolsTitle: 'Protocols',
		backButtonTitle: 'Back',
	},
	ru: {
		protocolsTitle: 'Протоколы',
		backButtonTitle: 'Назад',
	}
};
export default translates;