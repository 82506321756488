import React, {} from "react";
import {useSelector} from "react-redux";
import * as THREE from "three";

// import Axes from "../../elements/Axes";


const RayCast = ({position = new THREE.Vector3(), color = "blue"}) => {
	
	const {rayCastHelperShow} = useSelector((state) => state.rayCast);
	
	const rayCastGeometry = new THREE.SphereGeometry(0.1);
	const rayCastMaterial = new THREE.MeshBasicMaterial({color: color});
	
	return (
		<>
			<primitive position={position} object={new THREE.Mesh(rayCastGeometry, rayCastMaterial)}/>
			{/*{rayCastHelperShow && <Axes position={position} height={1000} radius={0.05} color={color}/>}*/}
			{rayCastHelperShow && <axesHelper args={[50]} position={position} height={1000} radius={0.05} color={color} />}
		</>
	);
};
export default RayCast;