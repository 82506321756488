import update from 'immutability-helper';
import {
	PROCESSINGS_SET,
	PROCESSINGS_ADD,
	PROCESSING_UPDATE,
	PROCESSING_DELETE,
	PROCESSINGS_BY_DATA_ID_SET,
	PROCESSINGS_BY_DATA_ID_ADD, PROCESSING_BY_DATA_ID_UPDATE, PROCESSING_BY_DATA_ID_DELETE
} from "./constants";


const initialProcessingState = {
	processings:[],
	processingsByDataId:{},
};

export const processing = (state = initialProcessingState, action) => {
	switch (action.type) {
		case PROCESSINGS_SET:
			return update(state,{ processings:{$set:action.payload} });
			
		case PROCESSINGS_BY_DATA_ID_SET:
			return update(state,{ processingsByDataId:{[action.payload.dataId]:{$set:action.payload.proccessingList}} });
			
		case PROCESSINGS_ADD:
			return update(state,{ processings:{$push:[action.payload]} });
		
		case PROCESSINGS_BY_DATA_ID_ADD:
			if (state.processingsByDataId[action.payload.dataId] === undefined){
				return update(state,{ processingsByDataId:{[action.payload.dataId]:{$set:[action.payload.processing]}} });
			} else {
				return update(state,{ processingsByDataId:{[action.payload.dataId]:{$push:[action.payload.processing]}} });
			}
		
		case PROCESSING_UPDATE:
			const processingIndexToUpdate = state.processings.findIndex(processing => processing.id === action.payload.processingId);
			return update(state, {processings: {[processingIndexToUpdate]: { status:{$set: action.payload.data.status}}}});
		
		case PROCESSING_BY_DATA_ID_UPDATE:
			const _processingIndexToUpdate = state.processingsByDataId[action.payload.dataId].findIndex(processing => processing.id === action.payload.processingId);
			return update(state, {processingsByDataId: {[action.payload.dataId]: { [_processingIndexToUpdate]: { $set: action.payload.data}}}});
		
		case PROCESSING_DELETE:
			const processingIndexToDelete = state.processings.findIndex(processing => processing.id === action.payload);
			return update(state, {processings: {$splice: [[processingIndexToDelete, 1]]}});
			
		case PROCESSING_BY_DATA_ID_DELETE:
			const _processingIndexToDelete = state.processingsByDataId[action.payload.dataId].findIndex(processing => processing.id === action.payload.processingId);
			return update(state, {processingsByDataId: {[action.payload.dataId]: {$splice: [[_processingIndexToDelete, 1]]}}});
			
		default:
			return state;
	}
};
