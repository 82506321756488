import { combineReducers } from "redux";

import {tomograms} from "./tomograms/reducers";
import {loader} from "./loader/reducers";
import {geometry} from "./geometry/reducers";
import {surfaceMaps} from "./surfaceMaps/reducers";
import {fmrtMaps} from "./fmrtMaps/reducers";
import {drawLayer} from "./drawLayer/reducers";
import {protocol} from "./protocol/reducers";
import {ROIPoint} from "./ROIPoint/reducers";
import {patient} from "./patient/reducers";
import {processing} from "./processing/reducers";
import {rayCast} from "./rayCast/reducers";
import {comment} from "./comment/reducers";
import {favorite} from "./favorite/reducers";
import {profile} from "./profile/reducers";
import {lang} from "./lang/reducers";
import {stackCache} from "./stackCache/reducers";
import {labelTomogram} from "./labelTomogram/reducers";
import {syntheticTomograms} from "./syntheticTomograms/reducers";
import {cache} from "./cache/reducers";
import {gltf} from "./gltf/reducers";

const rootReducer = combineReducers({
  loader,
  tomograms,
  surfaceMaps,
  geometry,
  fmrtMaps,
  drawLayer,
  protocol,
  ROIPoint,
  patient,
  processing,
  rayCast,
  comment,
  favorite,
  profile,
  lang,
  stackCache,
  labelTomogram,
  syntheticTomograms,
  cache,
  gltf,
});

export default rootReducer;
