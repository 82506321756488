import React from "react";
import {useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DrawerLayout from "@ui/layout/DrawerLayout";

import routes from "../routes";


const LibraryPage = () => {
	
	const navigate = useNavigate();
	
	const handlePatientOpenClick = () => navigate(routes.myPatients);
	const handleFavoriteOpenClick = () => navigate(routes.favorite);
	
	return (
		<DrawerLayout>
			<Stack alignItems={'center'} justifyContent={'center'} sx={{height:'calc(100vh - 68.5px)'}}>
				<Box sx={{width: "280px", textAlign: "center"}}>
					<Button sx={{mt: 2}} variant="contained" onClick={handlePatientOpenClick}>Открыть своих пациентов</Button>
					<Button sx={{mt: 2}} variant="contained" onClick={handleFavoriteOpenClick}>Открыть Избранное</Button>
				</Box>
			</Stack>
		</DrawerLayout>
	);
};
export default LibraryPage;
