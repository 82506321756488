import React from "react";
import { Leva } from "leva";

import RealTomogramsFolder from "./folders/RealTomogramsFolder";
import SyntheticTomogramsFolder from "./folders/SyntheticTomogramsFolder";
import Model3DFolder from "./folders/Model3DFolder";


const ViewerMenu = () => {
	return (
		<>
			<Model3DFolder/>
			<RealTomogramsFolder/>
			<SyntheticTomogramsFolder/>
			<Leva flat /*collapsed*/ titleBar={{ title:'Controls', drag:true, filter:false }} hideCopyButton />
		</>
	);
};
export default ViewerMenu;