// const translates = {
// 	en:{
// 		formTitle:'Registration',
// 		submitButtonTitle:'Register',
// 		submitButtonProcessTitle:'loading',
// 		or:'or',
// 		loginLinkTitle:'return to login',
// 		fields:{
// 			username:'Username',
// 			first_name:'First name',
// 			last_name:'Last name',
// 			organization:'Organization',
// 			password:'Password',
// 		}
// 	},
// 	ru:{
// 		formTitle:'Регистрация',
// 		submitButtonTitle:'Зарегистрироваться',
// 		submitButtonProcessTitle:'загрузка',
// 		or:'или',
// 		loginLinkTitle:'вернуться ко входу',
// 		fields:{
// 			username:'Имя пользователя',
// 			first_name:'Имя',
// 			last_name:'Фамилия',
// 			organization:'Организация',
// 			password:'Пароль',
// 		}
// 	}
// };
// export default translates;


const translates = {
	en: {
		formTitle: 'Service Unavailable',
		message: 'Sorry, this function is temporarily unavailable. Please contact the service developers for more information.',
		fields: {}  // Убираем все поля
	},
	ru: {
		formTitle: 'Функция недоступна',
		message: 'Извините, данная функция временно недоступна. Пожалуйста, обратитесь к разработчикам сервиса.',
		fields: {}  // Убираем все поля
	}
};

export default translates;