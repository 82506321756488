import update from 'immutability-helper';
import {
	LANG_SET
} from "./constants";


const initialLangState = {
	lang: null,
};

export const lang = (state = initialLangState, action) => {
	switch (action.type) {
		
		case LANG_SET:
			return update(state, {
				lang: {$set: action.payload},
			});
		
		default:
			return state;
	}
};
