import update from 'immutability-helper';
import {
	CREATE_MODE_TOGGLE,
	EXTRA_OBJECT_CREATE,
	EXTRA_OBJECT_DELETE,
	FIRST_LINE_POINT_MODE_TOGGLE,
} from "./constants";


const initialDrawLayerState = {
	modes: ['point', 'line', 'text', 'ruler'],
	createMode: null, // null || 'point' || 'text' || 'line' || 'ruler'
	isFirstLinePoint: false,
	extraPoints: [],
};

export const drawLayer = (state = initialDrawLayerState, action) => {
	switch (action.type) {

		case CREATE_MODE_TOGGLE:
			return update(state, {createMode: {$set: action.payload}});

		case FIRST_LINE_POINT_MODE_TOGGLE:
			return update(state, {isFirstLinePoint: {$set: action.payload}});

		case EXTRA_OBJECT_CREATE:
			return update(state, {extraPoints: {$push: [action.payload]}});

		case EXTRA_OBJECT_DELETE:
			return update(state, {extraPoints: {$splice: [[action.payload, 1]]}});

		default:
			return state;
	}
};
