import React from "react";

import Typography from "@mui/material/Typography";
import UIModal from "@ui/UIModal/UIModal";

import PatientCreateForm from "../CreateForm/PatientCreateForm";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const PatientCreateModal = ({open, setOpen}) => {
	const {modalTitle} = useTranslate({translates});
	return (
		<UIModal isOpen={open} setIsOpen={setOpen}>
			<Typography variant="h6" component="h2" sx={{mb: 1}}>{modalTitle}</Typography>
			<PatientCreateForm/>
		</UIModal>
	);
};
export default PatientCreateModal;