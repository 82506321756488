import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {zoomSizeSetRedux} from "@redux/tomograms/actions";

import useChangeTomogram from "./useChangeTomogram";


const useTomogramContainer = () => {
	
	const dispatch = useDispatch();
	
	const {changeRealTomogram, changeSyntheticTomogram, changeLabelTomogram} = useChangeTomogram();
	
	const {zoomSize} = useSelector((state) => state.tomograms);
	const [eventMouseMove, setEventMouseMove] = useState(null);
	
	const changeZoomMove = (e, r, position, setPs) => {
		if (eventMouseMove === "zoom") {
			if (e.movementY < 0) {
				dispatch(zoomSizeSetRedux({r: r, size: zoomSize[r] + 0.03}))
			} else if (e.movementY > 0) {
				dispatch(zoomSizeSetRedux({r: r, size: zoomSize[r] - 0.03}))
			}
		} else if (eventMouseMove === "position") {
			// if (e.movementY < 0) {
			//   position[0] += 1;
			//   setPs(...Array(position));
			// } else if (e.movementY > 0) {
			//   position[0] -= 1;
			//   setPs(...Array(position));
			// }
		}
	};
	
	const changeDown = (e) => {
		if (e.button === 2) {
			setEventMouseMove("zoom");
		} else if (e.button === 0) {
			setEventMouseMove("position");
		}
	};
	
	const changeUp = (e) => {
		setEventMouseMove(null);
	};
	
	const onWheel = (e, name) => {
		changeRealTomogram(e, name);
		changeSyntheticTomogram(e, name);
		changeLabelTomogram(e,name);
	};
	
	return {
		changeZoomMove,
		changeDown,
		changeUp,
		onWheel,
		eventMouseMove,
	};
};
export default useTomogramContainer;