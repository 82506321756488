import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {toast} from "react-toastify";

import {toggleIsLoadingRedux} from "@redux/loader/actions";
import {unzipToArrayBuffer} from "@utils/index";
import useReduxLoaders from "@hooks/useReduxLoaders";


const useViewer = () => {
	
	const dispatch = useDispatch();
	
	const {tomogramLoader, surfaceLoader, surfaceMapLoader} = useReduxLoaders();
	const {isLoading} = useSelector((state) => state.loader);
	
	const [isDragOver, setIsDragOver] = useState(false);
	
	const onDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragOver(true);
	};
	
	const onDragLeave = e => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragOver(false);
	};
	
	const handleUpload = async e => {
		
		e.preventDefault();
		e.stopPropagation();
		
		let transferFiles = e.dataTransfer ? [...e.dataTransfer.files] : [...e.target.files];
		
		if (transferFiles.length === 0) return;
		
		if (!(/.zip/.test(transferFiles[0].name))) {
			toast("File must be zip archive!", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});
			setIsDragOver(false);
			return;
		}
		
		await setIsDragOver(false);
		await dispatch(toggleIsLoadingRedux(true));
		
		console.log(transferFiles[0])
		const files = await unzipToArrayBuffer(transferFiles[0]);
		console.log(files)
		let loader = null;
		
		for (const file of files) {
			
			if (/.nii/.test(file.url) || /.mgz/.test(file.url)) {
				console.log(file.url)
				loader = tomogramLoader;
			} else if (/.pial/.test(file.url) || /.inflated/.test(file.url)) {
				loader = surfaceLoader;
			} else if (/.thickness/.test(file.url)) {
				loader = surfaceMapLoader;
			} else {
				loader = null;
			}
			
			loader !== null && loader(file);
		}
		
		await dispatch(toggleIsLoadingRedux(false));
	};
	
	return {
		isDragOver,
		onDragOver,
		onDragLeave,
		handleUpload,
		isLoading,
	};
};
export default useViewer;