import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import buildResponse from "../buildResponse";


const loginRequest = async (values) => {
	
	const result = await axiosService.post(buildUrl('/login'), { ...values});
	
	return buildResponse(result);
};
export default loginRequest;