import React, {useEffect} from "react";
import * as THREE from "three";
import {useThree} from "@react-three/fiber";
import {Vector3} from "three";
import {useSelector} from "react-redux";

import useSurfaceMesh from "./hooks/useSurfaceMesh";
import useSurfaceMeshMaterial from "./hooks/useSurfaceMeshMaterial";


const SurfaceMesh = ({position = new THREE.Vector3(), geometry}) => {
	
	const {gl, camera} = useThree();
	const {onPointerDown, onPointerUp} = useSurfaceMesh();
	const {meshMaterial} = useSurfaceMeshMaterial();
	
	const {clipping} = useSelector((state) => state.geometry);
	const {rayCast } = useSelector((state) => state.rayCast);
	const { camera_near} = useSelector((state) => state.geometry);
	
	useEffect(()=>{
		camera.near = camera_near;
		camera.updateProjectionMatrix();
	},[camera_near]);
	
	useEffect(() => {
		
		const { clippingPlanes, localClippingEnabled } = gl;
		
		if (clipping){
			
			const plane = (new THREE.Plane(new Vector3(0, -1*Math.abs(rayCast.y) , 0), rayCast.y > 0 ? 1 : -1));
			
			gl.clippingPlanes = [plane];
			gl.localClippingEnabled = true
			// Go back to defaults on unmount
		} else {
			gl.clippingPlanes = [];
			gl.localClippingEnabled = false
		}
		return () => Object.assign(gl, { clippingPlanes, localClippingEnabled})
		
	}, [rayCast, clipping]);

	return (
		<mesh position={position} geometry={geometry}
		      material={meshMaterial}
		      clipShadows={true} castShadow={true}
		      onPointerUp={onPointerUp}
		      onPointerDown={onPointerDown}/>
	);
};
export default SurfaceMesh;