import React from 'react';
import {useNavigate} from "react-router-dom";

import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";

import {logoutRequest} from "@api/auth";
import routes from "../../../../routes";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const HeaderLogoutButton = () => {
	
	const navigate = useNavigate();
	const {buttonTitle} = useTranslate({translates});
	
	const handleLogoutClick = async () => {
		await logoutRequest();
		navigate(routes.login)
	};
	
	return (
		<Button variant="contained" style={{backgroundColor: 'white', color: "#1976d2"}} size={'small'}
		        onClick={handleLogoutClick} startIcon={<LogoutIcon/>}>{buttonTitle}</Button>
	);
};
export default HeaderLogoutButton;