import {Vector3} from "three";

import {getClosestObject, getVectorDistance} from "./index";


const getRayCasts = (point, surface, offset={x: 0, y: 0, z: 0})=>{
	
	// find nearest objects in KD tree instances
	const nearestLeft = surface.leftKdTree.nearest([point.x, point.y, point.z], 1)[0][0].obj;
	const nearestRight = surface.rightKdTree.nearest([point.x, point.y, point.z], 1)[0][0].obj;
	
	const distanceLeft = getVectorDistance(point, [...nearestLeft]);
	const distanceRight = getVectorDistance(point, [...nearestRight]);
	
	// find closest object to point
	const nearestResult = getClosestObject(point, nearestLeft, nearestRight );
	const activeSide = (distanceLeft < distanceRight) ? 'left' : 'right';
	
	return new Vector3(...nearestResult)[activeSide === 'left' ? "sub" : "add"]({...offset})
};

export default getRayCasts;