import axiosService from "../../libs/services/axiosService";
import {buildUrl} from "../settings";
import {COMMENTS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const commentUpdateRequest = async (commentId,values) => {
	
	const result = await axiosService.put(buildUrl(`/${COMMENTS_ROOT}/${commentId}`), JSON.stringify({...values}));
	
	return buildResponse(result);
};
export default commentUpdateRequest;