import React from 'react';

import List from "@mui/material/List";
import ListEmptyStateWrap from "@ui/ListEmptyStateWrap/ListEmptyStateWrap";

import PatientListItem from "../Item/PatientListItem";
import usePatientMyList from "./usePatientMyList";


const PatientMyList = () => {
	const {patientMyList} = usePatientMyList();
	return (
		<ListEmptyStateWrap length={patientMyList.length}>
			<List sx={{width: '100%', bgcolor: 'background.paper'}}>
				{patientMyList.map((patient, i) => (
					<PatientListItem patient={patient} key={i}/>
				))}
			</List>
		</ListEmptyStateWrap>
	);
};
export default PatientMyList;