import React, {useRef} from "react";
import {Line as DreiLine} from '@react-three/drei'

import CanvasSphere from "../primitives/CanvasSphere";


const Segment = ({start, end, visibility}) => {
	const ref = useRef();
	/*useLayoutEffect(() => {
		ref.current.geometry.setFromPoints([start, end].map((point) => new THREE.Vector3(...point)))
	}, [start, end]);*/
	
	return (
		<group visible={visibility}>
			<CanvasSphere position={start} radius={1.5}/>
			{end && (
				<>
					<DreiLine ref={ref} lineWidth={2} points={[Object.values(start), Object.values(end)]} color={'purple'}/>
					<CanvasSphere position={end} radius={1.5}/>
				</>
			)}
		</group>
	)
};
export default Segment;