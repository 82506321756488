import React, {} from 'react';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UIModal from "@ui/UIModal/UIModal";

import useMedicalRecordModal from "./useMedicalRecordModal";


const styles = {
	width: 600,
	maxHeight: 500,
	overflowY: "hidden",
	display: "flex",
	flexDirection: "column"
};

const MedicalRecordModal = ({isOpen, setIsOpen, record}) => {
	
	const {recordData, loading} = useMedicalRecordModal({isOpen, record});
	
	return (
		<UIModal isOpen={isOpen} setIsOpen={setIsOpen} styles={styles}>
			<Typography variant="h6" component="h2" sx={{mb: 1}}>Просмотр {record.medrecord_name}</Typography>
			{recordData && (
				<Box sx={{overflowY: "auto", overflowX: "hidden"}}>
					{recordData.extension === 'png' && <img src={recordData.data} alt="" style={{width: "100%"}}/>}
					{(recordData.extension === 'txt' || recordData.extension === 'csv') &&
					<div style={{whiteSpace: "pre-wrap"}}>{recordData.data}</div>}
				</Box>
			)}
			{recordData === null && (<div>unsupported file type</div>)}
			{loading && <div>loading...</div>}
		</UIModal>
	);
};
export default MedicalRecordModal;