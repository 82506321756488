import React, {} from 'react';

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

import useFavoriteIconButton from "./useFavoriteIconButton";


const FavoriteIconButton = ({...props}) => {
	
	const {handleToFavoriteClick, toolTipTitle, isInFav} = useFavoriteIconButton();
	
	return (
		<Tooltip title={toolTipTitle } {...props}>
			<IconButton onClick={handleToFavoriteClick}>
				{isInFav ? <FavoriteIcon/> : <FavoriteBorderIcon/>}
			</IconButton>
		</Tooltip>
	);
};
export default FavoriteIconButton;