import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {MEDRECORD_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const medrecordDeleteRequest = async (dataId) => {
	
	const result = await axiosService.delete(buildUrl(`/${MEDRECORD_ROOT}/${dataId}`));
	
	return buildResponse(result);
};
export default medrecordDeleteRequest;