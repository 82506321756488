import React from 'react';

import Stack from "@mui/material/Stack";
import HeadRow from "@ui/HeadRow";

import MedicalRecordList from "@MedicalRecord/List/MedicalRecordList";
import useMedicalRecordCreateModal from "@MedicalRecord/CreateModal/useMedicalRecordCreateModal";
import MedicalRecordCreateModal from "@MedicalRecord/CreateModal/MedicalRecordCreateModal";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const MedRecordsBlock = () => {
	
	const {isOpen:isOpenRecord, setIsOpen:setIsOpenRecord} = useMedicalRecordCreateModal();
	const {buttonTitle, blockTitle} = useTranslate({translates});
	
	const addNewFileRecord = () => setIsOpenRecord(true);
	
	return (
		<Stack spacing={2}>
			<HeadRow title={{text: blockTitle}} btn={{text: buttonTitle, onClick: addNewFileRecord}}/>
			<MedicalRecordList/>
			<MedicalRecordCreateModal isOpen={isOpenRecord} setIsOpen={setIsOpenRecord} />
		</Stack>
	);
};
export default MedRecordsBlock;