import React, {} from 'react';

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import EditIconButton from "../EditIconButton/EditIconButton";
import FavoriteIconButton from "../FavoriteIconButton/FavoriteIconButton";

import UserInfo from "../UserInfo/UserInfo";

import SimpleKeyValueList from "@components/SimpleKeyValueList";
import RowKeyValueList from "@components/RowKeyValueList";
import ProtocolsButton from "../ProtocolsButton/ProtocolsButton";

import usePatientCard from "./usePatientCard";


const PatientCard = () => {
	
	const {simpleList, rowList} = usePatientCard();
	
	return (
		<Paper sx={{px: 2, py: 4, position: 'relative'}}>
			<EditIconButton sx={{position: 'absolute', left: 8, top: 8}}/>
			<FavoriteIconButton sx={{position: 'absolute', right: 8, top: 8}}/>
			<Stack spacing={1} alignItems={'center'} sx={{mb: 2}}>
				<UserInfo/>
				<RowKeyValueList list={rowList}/>
				<SimpleKeyValueList list={simpleList}/>
			</Stack>
			<Stack spacing={2} alignItems="flex-start">
				<ProtocolsButton/>
			</Stack>
		</Paper>
	);
};
export default PatientCard;