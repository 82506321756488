import React, {} from 'react';

import List from "@mui/material/List";

import RoiPointProtocolSingleItem from "../ProtocolSingleItem/RoiPointProtocolSingleItem";

import useRoiPointProtocolSingleList from "./useRoiPointProtocolSingleList";


const RoiPointProtocolSingleList = () => {
	const {roiPointList} = useRoiPointProtocolSingleList();
	
	return (
		<List dense={true}>
			{roiPointList.map((ROIpoint, i) => (
				<RoiPointProtocolSingleItem point={ROIpoint} key={i}/>
			))}
		</List>
	);
};
export default RoiPointProtocolSingleList;