import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DrawerLayout from "@ui/layout/DrawerLayout";

import PatientCreateModal from "@Patient/CreateModal/PatientCreateModal";
import useTranslate from "@hooks/useTranslate";
import usePatientCreateSuccessPage from "./usePatientCreateSuccessPage";

import translates from "./translates";


const PatientCreateSuccessPage = () => {
	
	const {open, setOpen, goToPatientClick, handleOpen} = usePatientCreateSuccessPage();
	
	const {oneMoreButtonTitle, goToPatientButtonTitle, successMessage} = useTranslate({translates});
	
	return (
		<DrawerLayout>
			<Stack alignItems={'center'} justifyContent={'center'} sx={{height:'calc(100vh - 68.5px)'}}>
				<Box sx={{width: "280px", textAlign: "center"}}>
					<h4>{successMessage}</h4>
					<Button sx={{}} variant="contained" onClick={goToPatientClick}>{goToPatientButtonTitle}</Button>
					<Button sx={{mt:2}} variant="contained" onClick={handleOpen}>{oneMoreButtonTitle}</Button>
					<PatientCreateModal open={open} setOpen={setOpen}/>
				</Box>
			</Stack>
		</DrawerLayout>
	);
};
export default PatientCreateSuccessPage;
