import React, {} from 'react';

import List from "@mui/material/List";
import ProtocolItem from "../Item/ProtocolItem";

import useProtocolList from "./useProtocolList";


const ProtocolList = () => {
	
	const {protocolList} = useProtocolList();

	return (
		<List sx={{width: '100%', bgcolor: 'background.paper'}}>
			{protocolList.map((protocol, i) =>
				<ProtocolItem protocol={protocol} key={i}/>
			)}
		</List>
	);
};
export default ProtocolList;