import * as THREE from "three";
import update from 'immutability-helper';
import {
	RAY_CAST_SET,
	RAY_CAST_HELPER_SET,
	RAY_CAST_INFLATED_SET,
	RAY_CAST_HELPER_TOGGLE,
} from "./constants";


const initialRayCastState = {
	rayCast: new THREE.Vector3(0, 0, 0),
	rayCastInflated: new THREE.Vector3(0, 0, 0),
	rayCastHelper: new THREE.Vector3(0, 0, 0),
	rayCastHelperShow:false,
};

export const rayCast = (state = initialRayCastState, action) => {
	switch (action.type) {
		case RAY_CAST_SET:
			return update(state,{ rayCast:{$set:action.payload} });
			
		case RAY_CAST_INFLATED_SET:
			return update(state,{ rayCastInflated:{$set:action.payload} });
			
		case RAY_CAST_HELPER_SET:
			return update(state,{ rayCastHelper:{$set:action.payload} });
			
		case RAY_CAST_HELPER_TOGGLE:
			return update(state,{rayCastHelperShow:{$set:action.payload}});
			
		default:
			return state;
	}
};
