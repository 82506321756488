import React, {} from 'react';
import { useParams} from "react-router-dom";
import { useSelector} from "react-redux";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import PageTitle from "@ui/PageTitle";
import DrawerLayout from "@ui/layout/DrawerLayout";

import RoiPointProtocolSingleList from "@ROIPoint/ProtocolSingleList/RoiPointProtocolSingleList";

import CommentsBlock from "./components/CommentsBlock/CommentsBlock";

import useSingleProtocol from "./useSingleProtocol";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const SingleProtocol = () => {
	
	const {protocolId} = useParams();
	const {pageTitle, pointsTitle, publishButtonTitle, saveButtonTitle, deleteButtonTitle} = useTranslate({translates});
	
	const {protocol , handleDelete, handlePublish, handleSaveAsFileClick} = useSingleProtocol({protocolId});
	
	const {roiPointActive} = useSelector((state) => state.ROIPoint);
	
	return (
		<DrawerLayout>
			<PageTitle text={<>{pageTitle} {protocolId} {protocol !== null && <Chip label={protocol.state} color={protocol.state === 'final' ? 'success' : undefined} size={'small'}/>}</>} sx={{mb: 2}}/>
			{protocol !== null && <>
				<Button variant={'contained'} color={'success'} onClick={handlePublish} disabled={protocol.state === 'final'}>{publishButtonTitle}</Button>
				<Button variant={'contained'} onClick={handleSaveAsFileClick}>{saveButtonTitle}</Button>
				<Button variant={'contained'} color={'error'} onClick={handleDelete}>{deleteButtonTitle}</Button>
			</>}
			
			<Grid container spacing={4}>
				<Grid item xs={7}>
					<Typography variant="h6" component="h2" sx={{mt: 2}}>{pointsTitle}</Typography>
					<RoiPointProtocolSingleList />
				</Grid>
				<Grid item xs={5}>
					{protocol !==null && roiPointActive !==null && (<CommentsBlock canWriteComments={protocol.state !== 'final'}/>)}
				</Grid>
			</Grid>
		</DrawerLayout>
	);
};
export default SingleProtocol;
