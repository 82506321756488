import {createAction} from 'redux-actions';

import {PROTOCOL_CREATE, PROTOCOL_DELETE, PROTOCOL_CHANGE_STATUS, EDITABLE_PROTOCOL_SET, EDITABLE_PROTOCOL_CLEAR, PROTOCOL_LIST_CREATE} from './constants';


const protocolCreateAction = createAction(PROTOCOL_CREATE);
const protocolDeleteAction = createAction(PROTOCOL_DELETE);
const protocolChangeStatusAction = createAction(PROTOCOL_CHANGE_STATUS);

const editableProtocolSetAction = createAction(EDITABLE_PROTOCOL_SET);
const editableProtocolClearAction = createAction(EDITABLE_PROTOCOL_CLEAR);

const protocolListCreateAction = createAction(PROTOCOL_LIST_CREATE);


export const protocolCreateRedux = (payload) => async (dispatch) => dispatch(protocolCreateAction(payload));
export const protocolDeleteRedux = (payload) => async (dispatch) => dispatch(protocolDeleteAction(payload));
export const protocolChangeStatusRedux = (payload) => async (dispatch) => dispatch(protocolChangeStatusAction(payload));
export const editableProtocolSetRedux = (payload) => async (dispatch) => dispatch(editableProtocolSetAction(payload));
export const editableProtocolClearRedux = () => async (dispatch) => dispatch(editableProtocolClearAction());

export const protocolListCreateRedux = (payload) => async (dispatch) => dispatch(protocolListCreateAction(payload));