import React from "react";
import {useParams} from "react-router-dom";

import Typography from "@mui/material/Typography";
import UIModal from "@ui/UIModal/UIModal";

import MedicalRecordCreateForm from "../CreateForm/MedicalRecordCreateForm";


const MedicalRecordCreateModal = ({isOpen, setIsOpen}) => {
	
	const {patientId} = useParams();
	
	return (
		<UIModal isOpen={isOpen} setIsOpen={setIsOpen}>
			<Typography variant="h6" component="h2" sx={{mb: 1}}>Создать MedicalRecord</Typography>
			<MedicalRecordCreateForm patientId={patientId} setIsOpen={setIsOpen}/>
		</UIModal>
	);
};
export default MedicalRecordCreateModal;