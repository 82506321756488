import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";


const logoutRequest = async () => {
	
	const result = await axiosService.post(buildUrl('/logout'));
	const content = result.data;
	
	if (result.status === 200 ){
		sessionStorage.removeItem('accessToken');
		sessionStorage.removeItem('refreshToken');
		localStorage.removeItem('login');
		return { isSuccess: true, data:content };
	}
	
	return content.errors
		? { isSuccess:false, errors:content.errors, message:`${content.status} ${content.statusText}` }
		: { isSuccess:false, message:content.message };
};
export default logoutRequest;