import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PROCESSINGS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const processingDeleteRequest = async (processingId) => {
	
	const result = await axiosService.delete(buildUrl(`/${PROCESSINGS_ROOT}/${processingId}`));
	
	return buildResponse(result);
};
export default processingDeleteRequest;