export const listMainTranslates = {
	en:{
		allPatients:'all patients'
	},
	ru:{
		allPatients:'все пациенты'
	}
};
export const listLibTranslates = {
	en:{
		myPatients:'my patients',
		favorite:'favorite',
	},
	ru:{
		myPatients:'мои пациенты',
		favorite:'избранное',
	}
};

export const libTitleTranslates = {
	en:{
		title:'Library',
	},
	ru:{
		title:'Библиотека',
	}
};