import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {editableProtocolClearRedux, editableProtocolSetRedux, protocolCreateRedux} from "@redux/protocol/actions";
import protocolCreateWithRoiRequest from "@api/protocol/createWithRoi";


const useProtocolStateRow = () => {
	
	const dispatch = useDispatch();
	const {patientId} = useParams();
	const {dataId} = useParams();
	const {roiPointList} = useSelector((state) => state.ROIPoint);
	
	const handleButtonEditableClearClick = () => dispatch(editableProtocolClearRedux());
	
	const handleButtonSaveClick = async (state = 'draft') => {
		const roiToSave = roiPointList.map((roi) => roi.id);
		const {isSuccess, data} = await protocolCreateWithRoiRequest({
			subject_id: patientId,
			state: state,
			synthetic_data_id:Number(dataId),
			rois: [...roiToSave]
		});
		
		if (isSuccess) {
			dispatch(protocolCreateRedux(data));
			dispatch(editableProtocolSetRedux({protocolId: data.id}))
		}
		
	};
	
	return {
		handleButtonEditableClearClick,
		handleButtonSaveClick,
	};
};

export default useProtocolStateRow;