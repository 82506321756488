import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import patientGetRawDataRequest from "@api/patient/getRawData";
import useInstrDataCreateModal from "@InstrData/CreateModal/useInstrDataCreateModal";

import {patientDataSetRedux} from "@redux/patient/actions";


const useDataBlock = ({patientId}) => {
	
	const dispatch = useDispatch();
	const {patientData} = useSelector((state) => state.patient);
	const {isOpen, setIsOpen} = useInstrDataCreateModal();
	
	const addNewRawFile = () => {
		setIsOpen(true);
	};
	
	const getPatientData = useCallback(async ()=>{
		const {isSuccess,data} = await patientGetRawDataRequest(patientId);
		
		isSuccess && dispatch(patientDataSetRedux(data))
	},[dispatch,patientId]);
	
	useEffect(()=>{
		getPatientData().catch(console.error)
	},[getPatientData]);
	
	return {
		patientData,
		addNewRawFile,
		isOpen,
		setIsOpen,
	};
};

export default useDataBlock;