import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useControls} from "leva";

import {activeGeometrySetRedux, wireframeSwitchRedux, opacityUpdateRedux, geometryUpdateColorRedux, clippingSwitchRedux, cameraNearSetRedux, colormapUpdateRedux} from "@redux/geometry/actions";
import {rayCastHelperToggleRedux} from "@redux/rayCast/actions";
import {activeSurfaceMapSetRedux} from "@redux/surfaceMaps/actions";

import useChangeLut from "../useChangeLut";


const Model3DFolder = () => {
	
	const dispatch = useDispatch();
	const {changeLut} = useChangeLut();
	
	const {lutCounter} = useSelector((state) => state.tomograms);
	const {activeSyntheticIndex, synthetic} = useSelector((state) => state.syntheticTomograms);
	const {colormap} = useSelector((state) => state.geometry);
	
	const {activeGeometry, wireframeShow, opacity, clipping, camera_near} = useSelector((state) => state.geometry);
	const {rayCastHelperShow} = useSelector((state) => state.rayCast);
	const {mapsList, activeSurfaceMap} = useSelector((state) => state.surfaceMaps);
	
	
	const handleLutColorSchemeChange = async (newColorMap)=>{
		await dispatch(colormapUpdateRedux(newColorMap))
	};
	
	// eslint-disable-next-line no-empty-pattern
	const [{}, set] = useControls("3D", () => ({
		_near: {
			label: 'camera near',
			value: camera_near,
			min: 10,
			max: 150,
			step: 10,
			onChange: (v) => {
				dispatch(cameraNearSetRedux(v))
			},
		},
		_wireframeShow: {
			label: 'show wireframe',
			value: wireframeShow,
			onChange: (v) => {
				dispatch(wireframeSwitchRedux(v))
			},
		},
		_clipping: {
			label: 'clipping',
			value: clipping,
			onChange: (v) => {
				dispatch(clippingSwitchRedux(v))
			},
		},
		_rayCastHelperShow: {
			label: 'show RayCast Helper',
			value: rayCastHelperShow,
			onChange: (v) => {
				dispatch(rayCastHelperToggleRedux(v))
			},
		},
		_opacity: {
			label: 'opacity',
			value: opacity,
			min: 0.1,
			max: 1,
			step: 0.1,
			onChange: (v) => {
				dispatch(opacityUpdateRedux(v))
			},
		},
		_meshSurface: {
			label: 'surface mesh',
			value: 'pial',
			options: {
				'Pial': 'pial',
				'Inflated': 'inflated',
			},
			onChange: (v) => {
				(v !== activeGeometry) && changeGeometryType(v)
			},
		},
		_surfaceMap: {
			label: 'surface map',
			value: (mapsList.length === 0) ? 'choose surface' : activeSurfaceMap,
			options: (mapsList.length === 0) ? ['choose surface'] : mapsList.reduce((acc, tm, i) => ({
				...acc,
				[tm.type]: i
			}), {}),
			onChange: (v) => {
				((v !== 'choose surface') && v !== activeSurfaceMap) && handleChangeSurfaceMap(v);
			},
			disabled: mapsList.length === 0
		},
		_lutColorScheme: {
			label: 'lut colorscheme',
			value: colormap,
			options: {
				'Cooltowarm': 'cooltowarm',
				'Rainbow': 'rainbow',
				'Grayscale':'grayscale',
				'Blackbody':'blackbody',
			},
			onChange: handleLutColorSchemeChange,
			disabled: false
		},
	}), [
		activeGeometry,
		mapsList,
		activeSurfaceMap,
		lutCounter,
		colormap,
	]);
	
	const changeGeometryType = async (newGeometry) => {
		
		await dispatch(activeGeometrySetRedux(newGeometry));
		const data = await changeLut(activeSurfaceMap, newGeometry);
		await dispatch(geometryUpdateColorRedux({data, type: newGeometry}));
	};
	
	const handleChangeSurfaceMap = async (newSurfaceMap) => {
		if (mapsList.length !== 0 && (mapsList[newSurfaceMap].left || mapsList[newSurfaceMap].right)) {
			
			await dispatch(activeSurfaceMapSetRedux(newSurfaceMap));
			const data = await changeLut(newSurfaceMap, activeGeometry);
			await dispatch(geometryUpdateColorRedux({data, type: activeGeometry}));
			
		}
	};
	
	useEffect(() => {
		set({_surfaceMap: activeSurfaceMap})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapsList]);
	
	useEffect(() => {
	}, [synthetic, activeSurfaceMap, activeSyntheticIndex, lutCounter, colormap]);
	
	return <></>
};
export default Model3DFolder;
