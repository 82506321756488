const perspectives = [
	{
		cssClass: 'sagittal',
		label: 'sagittal (x)',
		number: 'r2',
		camera: {position: [320, 0, 0], up: [0, 0, 1]}
	},
	{
		cssClass: 'axial',
		label: 'axial (z)',
		number: 'r1',
		camera: {position: [0, 0, -320], up: [0, -1, 0]}
	},
	{
		cssClass: 'coronal',
		label: 'coronal (y)',
		number: 'r3',
		camera: {position: [0, -320, 0], up: [0, 1, 0]}
	},
];
export default perspectives;