import { createAction } from 'redux-actions';

import {
	PROCESSINGS_SET,
	PROCESSINGS_ADD,
	PROCESSING_UPDATE,
	PROCESSING_DELETE,
	PROCESSINGS_BY_DATA_ID_SET,
	PROCESSINGS_BY_DATA_ID_ADD,
	PROCESSING_BY_DATA_ID_UPDATE,
	PROCESSING_BY_DATA_ID_DELETE,
} from './constants';


const processingsSetAction = createAction(PROCESSINGS_SET);
const processingsAddAction = createAction(PROCESSINGS_ADD);
const processingUpdateAction = createAction(PROCESSING_UPDATE);
const processingDeleteAction = createAction(PROCESSING_DELETE);

const processingsByDataIdSetAction = createAction(PROCESSINGS_BY_DATA_ID_SET);
const processingsByDataIdAddAction = createAction(PROCESSINGS_BY_DATA_ID_ADD);
const processingsByDataIdUpdateAction = createAction(PROCESSING_BY_DATA_ID_UPDATE);
const processingsByDataIdDeleteAction = createAction(PROCESSING_BY_DATA_ID_DELETE);


export const processingsSetRedux = (payload) => async (dispatch) => dispatch(processingsSetAction(payload));
export const processingsAddRedux = (payload) => async (dispatch) => dispatch(processingsAddAction(payload));
export const processingUpdateRedux = (payload) => async (dispatch) => dispatch(processingUpdateAction(payload));
export const processingDeleteRedux = (payload) => async (dispatch) => dispatch(processingDeleteAction(payload));

export const processingsByDataIdSetRedux = (payload) => async (dispatch) => dispatch(processingsByDataIdSetAction(payload));
export const processingsByDataIdAddRedux = (payload) => async (dispatch) => dispatch(processingsByDataIdAddAction(payload));
export const processingByDataIdUpdateRedux = (payload) => async (dispatch) => dispatch(processingsByDataIdUpdateAction(payload));
export const processingByDataIdDeleteRedux = (payload) => async (dispatch) => dispatch(processingsByDataIdDeleteAction(payload));
