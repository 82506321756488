import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PATIENTS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const patientUpdateRequest = async (patientId, values) => {
	
	const result = await axiosService.put(buildUrl(`/${PATIENTS_ROOT}/${patientId}`), JSON.stringify({...values}));
	
	return buildResponse(result);
};
export default patientUpdateRequest;