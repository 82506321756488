import { createAction } from 'redux-actions';

import {
	SCENE_CHILDREN_SET,
	SCENE_3D_CHILDREN_SET,
	SYNTH_CHILDREN_SET,
	ORIG_CHILDREN_SET,
	ACTIVE_INDEX_SET,
	PIAL_LEFT_SET,
	PIAL_RIGHT_SET,
	SYNTH_VISIBLE_SET,
	ORIG_VISIBLE_SET,
	USER_DATA_SET,
	GLTF_READY_SET,
	POINT_POS_SET,
	POINT_POS_AND_ACTIVE_INDEX_SET,
	CAMERA_NEAR_SET,
	SHOW_SLICES_ON_3D_SET,
	SHOW_SYNTH_SET,
	POINT_INFO_SET,
	ZOOM_SET,
} from './constants';


const sceneChildrenSetAction = createAction(SCENE_CHILDREN_SET);
const scene3DChildrenSetAction = createAction(SCENE_3D_CHILDREN_SET);
const synthChildrenSetAction = createAction(SYNTH_CHILDREN_SET);
const origChildrenSetAction = createAction(ORIG_CHILDREN_SET);

const activeIndexSetAction = createAction(ACTIVE_INDEX_SET);

const pialLeftSetAction = createAction(PIAL_LEFT_SET);
const pialRightSetAction = createAction(PIAL_RIGHT_SET);

const synthVisibleSetAction = createAction(SYNTH_VISIBLE_SET);
const origVisibleSetAction = createAction(ORIG_VISIBLE_SET);

const userDataSetAction = createAction(USER_DATA_SET);

const gltfReadySetAction = createAction(GLTF_READY_SET);

const pointPosSetAction = createAction(POINT_POS_SET);

const pointPosAndActiveIndexSetAction = createAction(POINT_POS_AND_ACTIVE_INDEX_SET);

const cameraNearSetAction = createAction(CAMERA_NEAR_SET);
const showSlicesOn3DSetAction = createAction(SHOW_SLICES_ON_3D_SET);
const showSynthSetAction = createAction(SHOW_SYNTH_SET);
const pointInfoSetAction = createAction(POINT_INFO_SET);
const zoomSetAction = createAction(ZOOM_SET);

export const sceneChildrenSetRedux = (payload) => async (dispatch) => dispatch(sceneChildrenSetAction(payload));
export const scene3DChildrenSetRedux = (payload) => async (dispatch) => dispatch(scene3DChildrenSetAction(payload));
export const synthChildrenSetRedux = (payload) => async (dispatch) => dispatch(synthChildrenSetAction(payload));
export const origChildrenSetRedux = (payload) => async (dispatch) => dispatch(origChildrenSetAction(payload));
export const activeIndexSetRedux = (payload) => async (dispatch) => dispatch(activeIndexSetAction(payload));

export const pialLeftSetRedux = (payload) => async (dispatch) => dispatch(pialLeftSetAction(payload));
export const pialRightSetRedux = (payload) => async (dispatch) => dispatch(pialRightSetAction(payload));

export const synthVisibleSetRedux = (payload) => async (dispatch) => dispatch(synthVisibleSetAction(payload));
export const origVisibleSetRedux = (payload) => async (dispatch) => dispatch(origVisibleSetAction(payload));

export const userDataSetRedux = (payload) => async (dispatch) => dispatch(userDataSetAction(payload));
export const gltfReadySetRedux = (payload) => async (dispatch) => dispatch(gltfReadySetAction(payload));

export const pointPosSetRedux = (payload) => async (dispatch) => dispatch(pointPosSetAction(payload));
export const pointPosAndActiveIndexSetRedux = (payload) => async (dispatch) => dispatch(pointPosAndActiveIndexSetAction(payload));
export const cameraNearSetRedux = (payload) => async (dispatch) => dispatch(cameraNearSetAction(payload));
export const showSlicesOn3DSetRedux = (payload) => async (dispatch) => dispatch(showSlicesOn3DSetAction(payload));
export const showSynthSetRedux = (payload) => async (dispatch) => dispatch(showSynthSetAction(payload));

export const pointInfoSetRedux = (payload) => async (dispatch) => dispatch(pointInfoSetAction(payload));

export const zoomSetRedux = (payload) => async (dispatch) => dispatch(zoomSetAction(payload));



