import React from "react";

const usePatientEditModal = () => {
	const [isEditPatientModalOpen, setIsEditPatientModalOpen] = React.useState(false);
	
	return {
		isEditPatientModalOpen,
		setIsEditPatientModalOpen
	}
};
export default usePatientEditModal;