const getFileType = fileName => {
	const lowercaseName = fileName.toLowerCase();
	return (
		(/.nii/.test(lowercaseName) && 'nii')
		|| (/.mgz/.test(lowercaseName) && 'mgz')
		|| (/.pial/.test(lowercaseName) && 'pial')
		|| (/.inflated/.test(lowercaseName) && 'inflated')
		|| (/.xml/.test(lowercaseName) && 'xml')
		|| (/norm/.test(lowercaseName) && 'norm')
		|| (/blurring/.test(lowercaseName) && 'blurring')
		|| 'thickness'
	)
};

export default getFileType;