import React from 'react';

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";

import usePatientEditModal from "@Patient/EditModal/usePatientEditModal";
import PatientEditModal from "@Patient/EditModal/PatientEditModal";


const EditIconButton = ({...props}) => {
	const {isEditPatientModalOpen, setIsEditPatientModalOpen} = usePatientEditModal();
	
	const onEditClick = () => setIsEditPatientModalOpen(true);
	return (
		<>
			<Tooltip title={"Редактировать"} {...props}>
				<IconButton onClick={onEditClick}>
					<EditIcon/>
				</IconButton>
			</Tooltip>
			<PatientEditModal open={isEditPatientModalOpen} setOpen={setIsEditPatientModalOpen}/>
		</>
	);
};

export default EditIconButton;