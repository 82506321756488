import {useDispatch} from "react-redux";

import {roiPointDeleteRedux, roiPointCreateRedux, roiPointEditRedux} from "@redux/ROIPoint/actions";


const useRoiPoint = () => {
	
	const dispatch = useDispatch();
	
	const deletePoint = (pointId) => dispatch(roiPointDeleteRedux({pointId}));
	const createPoint = (newPoint) => dispatch(roiPointCreateRedux(newPoint));
	const editPoint = (values, pointId) => dispatch(roiPointEditRedux({pointId, data: {...values}}));
	
	return {
		deletePoint,
		createPoint,
		editPoint,
	};
};
export default useRoiPoint;