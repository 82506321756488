// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrawLogRow {
  display: flex;
  align-items: center;
  font-size: 12px;
  background: #292d39;
  padding: 5px 10px;
}
.DrawLogRow > * {
  margin: 0;
}
.DrawLogRow__point {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background-color: red;
}
.DrawLogRow__title {
  margin-left: 5px;
}
.DrawLogRow__time {
  margin-left: 5px;
}
.DrawLogRow__link {
  display: inline-block;
  margin-left: auto;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/Viewer/components/DrawLog/components/DrawLogRow/DrawLogRow.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AACF;AAAE;EACE,SAAA;AAEJ;AAAE;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,qBAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,qBAAA;EACA,iBAAA;EACA,YAAA;AAEJ","sourcesContent":[".DrawLogRow {\n  display: flex;\n  align-items: center;\n  font-size: 12px;\n  background: #292d39;\n  padding: 5px 10px;\n  & > * {\n    margin: 0;\n  }\n  &__point {\n    display: inline-block;\n    width: 10px;\n    height: 10px;\n    border-radius: 4px;\n    background-color: red;\n  }\n  &__title {\n    margin-left: 5px;\n  }\n  &__time {\n    margin-left: 5px;\n  }\n  &__link {\n    display: inline-block;\n    margin-left: auto;\n    color: white;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
