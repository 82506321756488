import React, {} from 'react';
import {Link} from "react-router-dom";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MoreVertIcon from '@mui/icons-material/MoreVert';

import useProtocolItem from "./useProtocolItem";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const ProtocolItem = ({protocol}) => {

	const { id, state } = protocol;
	
	const {publishButtonTitle, saveButtonTitle, deleteButtonTitle} = useTranslate({translates});
	const { handleSaveAsFile, handlePublish, handleDelete, anchorEl, handleMenu, handleClose, link } = useProtocolItem({protocol});
	
	return (
		<ListItem alignItems="flex-start" divider
		          secondaryAction={
			          <IconButton edge="end" aria-label="comments" onClick={handleMenu}>
				          <MoreVertIcon />
			          </IconButton>
		          }>
			<ListItemText
				primary={<Link to={link} style={{color: "inherit"}}>id={id}</Link>}
				secondaryTypographyProps={{component: 'div'}}
				secondary={
					<Box sx={{mt: 1, mb: 1}}>
						<Chip label={state} size="small" color={(state === 'final') ? 'success' : undefined}/>
						<Typography sx={{display: 'inline', ml: 1}} component="span" variant="body2" color="text.primary">Protocol Author</Typography>
						{' -  some extra information'}
					</Box>
				}
			/>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{vertical: 'top', horizontal: 'right'}}
				keepMounted
				transformOrigin={{vertical: 'top', horizontal: 'right'}}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handlePublish} disabled={state === 'final'}>{publishButtonTitle}</MenuItem>
				<MenuItem onClick={handleSaveAsFile}>{saveButtonTitle}</MenuItem>
				<MenuItem onClick={handleDelete}>{deleteButtonTitle}</MenuItem>
			</Menu>
		</ListItem>
	);
};
export default ProtocolItem;