import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as THREE from "three";

import {INFLATED_OFFSET} from "../../../../../../constants";

import {getRayCasts, matrixTransform} from "@utils/index";
import {
	activeTmPointSetRedux,
	activeTmSliceIndexesUpdateRedux,
	realSliceUpdateAllPerspectivesIndexRedux,
} from "@redux/tomograms/actions";
import {
	rayCastSetRedux,
	rayCastHelperSetRedux,
	rayCastInflatedSetRedux,
} from "@redux/rayCast/actions";
// import getTmDataToUpdate from "../../../../TomogramCanvas/components/TomogramMesh/utils/getTmDataToUpdate";
import {labelMeshUpdateSliceIndexesRedux} from "@redux/labelTomogram/actions";
import {syntheticUpdateAllPerspectivesIndexRedux} from "@redux/syntheticTomograms/actions";


const useSurfaceMesh = () => {
	
	const [mousePos, setMousePos] = useState({x: 0, y: 0});
	const dispatch = useDispatch();
	
	const geometryState = useSelector((state) => state.geometry);
	const {pial, inflated, activeGeometry} = useSelector((state) => state.geometry);
	const {real, activeRealIndex} = useSelector((state) => state.tomograms);
	const {synthetic} = useSelector((state) => state.syntheticTomograms);
	
	const {labelMesh} = useSelector((state) => state.labelTomogram);
	
	const handleIndexCoordinate = ({intersections}) => {
		
		const {point, eventObject} = intersections[0];
		
		const activeSurface = geometryState[activeGeometry];
		
		const _activeSide = eventObject.geometry.uuid === activeSurface.right.uuid
			? "right"
			: "left";
		
		// offset for inflated surfaces (to avoid their intersection)
		const offsetPoint = activeGeometry === 'inflated'
			? new THREE.Vector3().copy(point)[_activeSide === 'right' ? "sub" : "add"]({x: INFLATED_OFFSET, y: 0, z: 0})
			: new THREE.Vector3().copy(point);
		
		const rayCasts = getRayCasts(offsetPoint, pial);
		dispatch(rayCastSetRedux(rayCasts));
		
		if (inflated.left !==null && inflated.right !==null){
			const rayCastsInflated = getRayCasts(offsetPoint, inflated, {x: INFLATED_OFFSET, y: 0, z: 0});
			dispatch(rayCastInflatedSetRedux(rayCastsInflated));
		}
		
		dispatch(rayCastHelperSetRedux(new THREE.Vector3().copy(point)));
		
		// SET TOMOGRAMS INDEX
		
		if (real.length !== 0) {
			
			const {_spacing, lps2IJK} = real[0].r1.stackHelper.stack;
			
			let _ijk = matrixTransform(_spacing, rayCasts, lps2IJK);
			const worldCenter = real[0].r1.stackHelper.stack.worldCenter();
			
			const transformedPoint = {
				x: -1*rayCasts.x + worldCenter.x,
				y: -1*rayCasts.y + worldCenter.y,
				z: rayCasts.z + worldCenter.z
			};
			
			dispatch(activeTmPointSetRedux(transformedPoint));
			
			dispatch(activeTmSliceIndexesUpdateRedux({r1: _ijk.y, r2: _ijk.x, r3: _ijk.z}));
			
			dispatch(realSliceUpdateAllPerspectivesIndexRedux({
				index: activeRealIndex,
				data: {r1: _ijk.y, r2: _ijk.x, r3: _ijk.z}
			}));
		}
		
		if (synthetic.length !== 0) {
			synthetic.forEach((tm,i) => {
				const {_spacing, lps2IJK} = tm.r1.stackHelper.stack;
				
				let _ijk = matrixTransform(_spacing, point, lps2IJK);
				dispatch(activeTmSliceIndexesUpdateRedux({r1: _ijk.y, r2: _ijk.x, r3: _ijk.z}));
				
				dispatch(syntheticUpdateAllPerspectivesIndexRedux({
							index: i,
							data: {r1: _ijk.y, r2: _ijk.x, r3: _ijk.z}
				}));
			})
		}
		
		if (labelMesh !== null) {
			const {_spacing, lps2IJK} = labelMesh.r1.stackHelper.stack;
			
			let _ijk = matrixTransform(_spacing, point, lps2IJK);
			
			dispatch(activeTmSliceIndexesUpdateRedux({r1: _ijk.y, r2: _ijk.x, r3: _ijk.z}));
			
			dispatch(labelMeshUpdateSliceIndexesRedux({
				data: {r1: _ijk.y, r2: _ijk.x, r3: _ijk.z}
			}));
		}
	};
	
	const onPointerUp = (event) => {
		
		const x = event.pageX;
		const y = event.pageY;
		
		mousePos.x === x && mousePos.y === y && handleIndexCoordinate(event);
		
	};
	
	const onPointerDown = (event) => {
		const x = event.pageX;
		const y = event.pageY;
		setMousePos({x, y})
	};
	
	return {
		onPointerDown,
		onPointerUp,
	}
};
export default useSurfaceMesh;