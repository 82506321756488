import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import profileGetPatientsRequest from "@api/profile/getPatients";
import {patientMyListCreateRedux} from "@redux/patient/actions";


const usePatientMyList = () => {
	
	const dispatch = useDispatch();
	
	const [loading, setLoading] = React.useState(false);
	const {patientMyList} = useSelector((state) => state.patient);
	
	const getProfilePatients = async ()=>{
		
		setLoading(true);
		const {isSuccess, data} = await profileGetPatientsRequest();
		
		isSuccess && dispatch(patientMyListCreateRedux(data));
		
		setLoading(false)
	};
	
	useEffect(()=>{
		getProfilePatients()
			.catch((error)=>{console.error(error)})
	},[]);
	
	return {
		loading,
		patientMyList,
	};
};
export default usePatientMyList;