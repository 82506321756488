import { createAction } from 'redux-actions';

import {
	GEOMETRY_ADD,
	ACTIVE_GEOMETRY_SET,
	GEOMETRY_UPDATE_COLOR,
	COLORMAP_UPDATE,
	WIREFRAME_SWITCH,
	OPACITY_UPDATE,
	CLIPPING_SWITCH,
	CAMERA_NEAR_SET,
} from './constants';


const geometryAddAction = createAction(GEOMETRY_ADD);
const geometryUpdateColorAction = createAction(GEOMETRY_UPDATE_COLOR);
const activeGeometrySetAction = createAction(ACTIVE_GEOMETRY_SET);

const colormapUpdateAction = createAction(COLORMAP_UPDATE);
const wireframeSwitchAction = createAction(WIREFRAME_SWITCH);
const opacityUpdateAction = createAction(OPACITY_UPDATE);
const clippingSwitchAction = createAction(CLIPPING_SWITCH);

const cameraNearSetAction = createAction(CAMERA_NEAR_SET);


export const geometryAddRedux = (payload) => async (dispatch) => dispatch(geometryAddAction(payload));
export const geometryUpdateColorRedux = (payload) => async (dispatch) => dispatch(geometryUpdateColorAction(payload));

export const activeGeometrySetRedux = (payload) => async (dispatch) => dispatch(activeGeometrySetAction(payload));

export const wireframeSwitchRedux = (payload) => async (dispatch) => dispatch(wireframeSwitchAction(payload));
export const opacityUpdateRedux = (payload) => async (dispatch) => dispatch(opacityUpdateAction(payload));
export const colormapUpdateRedux = (payload) => async (dispatch) => dispatch(colormapUpdateAction(payload));
export const clippingSwitchRedux = (payload) => async (dispatch) => dispatch(clippingSwitchAction(payload));
export const cameraNearSetRedux = (payload) => async (dispatch) => dispatch(cameraNearSetAction(payload));

