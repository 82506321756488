import JSZip from "jszip";


const unzip = async (transferFile) => {
	const zip = new JSZip();
	const archive = await zip.loadAsync(transferFile);
	const archiveFiles = Object
		.values(archive.files)
		.filter(file => !file.dir) // remove dirs
		.filter(file => !file.name.includes('/')); // remove files inside dirs

	return archiveFiles;
};

export default unzip;