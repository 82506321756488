import React, {} from 'react';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import MedicalRecordModal from "../Modal/MedicalRecordModal";

import useMedicalRecordListItem from "./useMedicalRecordListItem";


const MedicalRecordListItem = ({record}) => {
	const { isModalOpen, setIsModalOpen, onTitleClick, onDeleteClick} = useMedicalRecordListItem();
	
	return (
		<div>
			<Box sx={{textAlign: 'center', width: "100px", p: 2}}>
				<ContentPasteIcon/>
				<a href={record.path} style={{display: "block", marginTop: "8px", color: "inherit"}}
				   onClick={onTitleClick}>{record.medrecord_name}</a>
				<Button onClick={()=>onDeleteClick(record.id)}>delete</Button>
			</Box>
			<MedicalRecordModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} record={record}/>
		</div>
	);
};
export default MedicalRecordListItem;
