import React from "react";

import "./toolbarButton.scss"


const ToolbarButton = ({ extraClass, onClick=()=>{}, title, icon }) => {
	return (
		<span className={'toolbarButton' + extraClass} title={title} onClick={ onClick }>{ icon }</span>
	);
};
export default ToolbarButton;