const translates = {
	en: {
		pageTitle: 'Protocol',
		pointsTitle: 'Points',
		publishButtonTitle: 'Publish',
		saveButtonTitle: 'Save into file',
		deleteButtonTitle: 'Delete',
	},
	ru: {
		pageTitle: 'Протокол',
		pointsTitle: 'Точки',
		publishButtonTitle: 'Опубликовать',
		saveButtonTitle: 'Сохранить в файл',
		deleteButtonTitle: 'Удалить',
	}
};
export default translates;