import React from 'react';


const LightingLayer = () => {
	return (
		<>
			<ambientLight intensity={Math.PI}/>
			<pointLight position={[100, 10, 10]} intensity={Math.PI*10000}/>
			<pointLight position={[-200, 10, 10]} intensity={Math.PI*10000}/>
		</>
	);
};
export default LightingLayer;