import React from "react";
import {Sphere} from '@react-three/drei'
import {Vector3} from "three";


const CanvasSphere = ({ position, radius=2, color='purple' }) => {
	return (
		<Sphere position={(new Vector3()).copy(position)} args={[radius]}>
			<meshBasicMaterial color={color}/>
		</Sphere>
	)
};
export default CanvasSphere;