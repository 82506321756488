import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PROTOCOLS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const protocolUpdateWithRoiRequest = async (protocolId, values) => {
	
	const result = await axiosService.put(buildUrl(`/${PROTOCOLS_ROOT}/${protocolId}/rois`), JSON.stringify({...values}));
	
	return buildResponse(result);
};
export default protocolUpdateWithRoiRequest;