import React, {} from "react";
import { useSelector } from "react-redux";

import ViewerMenu from "../../components/ViewerMenu";
import TomogramsGrid from "../../components/TomogramsGrid";

import UploadBlock from "./components/UploadBlock";
import DropZone from "./components/DropZone";
import Preloader from "./components/Preloader";
import Toolbar from "./components/Toolbar";
import DrawLog from "./components/DrawLog";

import useViewer from "./hooks/useViewer";

import "./index.scss";


const Viewer = () => {
	
	const { isDragOver, onDragOver, onDragLeave, handleUpload, isLoading } = useViewer();
	
	const { real } = useSelector((state) => state.tomograms);
	const { extraPoints } = useSelector((state) => state.drawLayer);
	
	return (
		<>
			<div className="workMainDiv">
				<div className="canvasContainer" onDragOver={onDragOver}>
					{ isDragOver && <DropZone handleUpload={handleUpload} onDragLeave={onDragLeave}/> }
					{ real.length !== 0 ? (
						<>
							<ViewerMenu/>
							<TomogramsGrid/>
						</>
					) : <UploadBlock handleUpload={handleUpload}/>}
					{ isLoading && <Preloader/> }
				</div>
			</div>
			
			{ real.length !== 0 && extraPoints.length !== 0 && (<DrawLog/>)}
			{ real.length !== 0 && (<Toolbar cls={'fixed'}/>)}
			
		</>
	);
};
export default Viewer;