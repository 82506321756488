import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";

import {Store} from "@redux/store";

import "./index.css";

import 'localStore/init';
import 'idbStore/init';

const root = ReactDOM.createRoot(document.getElementById("root"));
      root.render(
          <Provider store={Store}>
            <App />
          </Provider>
      );
