import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PROTOCOLS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const protocolGetAllRequest = async () => {
	
	const result = await axiosService.get(buildUrl(`/${PROTOCOLS_ROOT}`));
	
	return buildResponse(result);
};
export default protocolGetAllRequest;