import {useDispatch, useSelector} from "react-redux";

import {editableProtocolSetRedux} from "@redux/protocol/actions";


const useInspectionProtocolItem = ({protocol}) => {
	
	const dispatch = useDispatch();
	
	const {editableProtocolId} = useSelector((state) => state.protocol);
	
	const isActive = editableProtocolId === protocol.id;
	
	const onSetEditableClick = () => dispatch(editableProtocolSetRedux({protocolId:parseInt(protocol.id)}));
	
	return {
		isActive,
		editableProtocolId,
		onSetEditableClick,
	};
};
export default useInspectionProtocolItem;