import React from 'react';

import List from "@mui/material/List";
import ListEmptyStateWrap from "@ui/ListEmptyStateWrap/ListEmptyStateWrap";

import PatientListItem from "../Item/PatientListItem";


const PatientList = ({patientList}) => {
	return (
		<ListEmptyStateWrap length={patientList?.length}>
			<List sx={{width: '100%', bgcolor: 'background.paper'}}>
				{patientList?.map((patient, i) => (
					<PatientListItem patient={patient} key={i}/>
				))}
			</List>
		</ListEmptyStateWrap>
	);
};
export default PatientList;