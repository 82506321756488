import { createAction } from 'redux-actions';

import {
	REAL_CLEAR,
	REAL_CREATE,
	REAL_UPDATE_SLICE_INDEX,
	REAL_UPDATE_ACTIVE_INDEX,
	REAL_SLICE_UPDATE_ALL_PERSPECTIVES_INDEX,
	
	LUT_COUNTER_SET,
	ZOOM_SIZE_SET,
	ACTIVE_TM_POINT_SET,
	ACTIVE_TM_SLICE_INDEXES_UPDATE,
} from './constants';


const realCreateAction = createAction(REAL_CREATE);
const realClearAction = createAction(REAL_CLEAR);
const realUpdateSliceIndexAction = createAction(REAL_UPDATE_SLICE_INDEX);
const realUpdateActiveIndexAction = createAction(REAL_UPDATE_ACTIVE_INDEX);
const realSliceUpdateAllPerspectivesIndexAction = createAction(REAL_SLICE_UPDATE_ALL_PERSPECTIVES_INDEX);

const lutCounterSetAction = createAction(LUT_COUNTER_SET);
const zoomSizeSetAction = createAction(ZOOM_SIZE_SET);
const activeTmPointSetAction = createAction(ACTIVE_TM_POINT_SET);
const activeTmSliceIndexesUpdateAction = createAction(ACTIVE_TM_SLICE_INDEXES_UPDATE);

export const realCreateRedux = (payload) => async (dispatch) => dispatch(realCreateAction(payload));
export const realClearRedux = () => async (dispatch) => dispatch(realClearAction());
export const realUpdateSliceIndexRedux = (rn, stackIndex, index) => async (dispatch) => dispatch(realUpdateSliceIndexAction({rn,stackIndex, index}));
export const realUpdateActiveIndexRedux = (index) => async (dispatch) => dispatch(realUpdateActiveIndexAction({index}));
export const realSliceUpdateAllPerspectivesIndexRedux = (payload) => async (dispatch) => dispatch(realSliceUpdateAllPerspectivesIndexAction(payload));

export const lutCounterSetRedux = (payload) => async (dispatch) => dispatch(lutCounterSetAction(payload));
export const zoomSizeSetRedux = (payload) => async (dispatch) => dispatch(zoomSizeSetAction(payload));
export const activeTmPointSetRedux = (payload) => async (dispatch) => dispatch(activeTmPointSetAction(payload));
export const activeTmSliceIndexesUpdateRedux = (payload) => async (dispatch) => dispatch(activeTmSliceIndexesUpdateAction(payload));
