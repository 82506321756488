import React from 'react';

import ListWide from "@ui/ListWide";
import FavoriteListItemHome from "../ListItemHome/FavoriteListItemHome";
import useFavoriteListHome from "./useFavoriteListHome";


const FavoriteListHome = () => {
	const {favoriteList} = useFavoriteListHome();
	return (
		<ListWide>
			{favoriteList.map(((favorite, i) => (
				<FavoriteListItemHome favorite={favorite} key={i}/>
			)))}
		</ListWide>
	);
};
export default FavoriteListHome;