import React, {} from 'react';

import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';

import useProcessingItem from "./useProcessingItem";


const ProcessingItem = ({processing}) => {
	
	const {status, modality, data_hash, synthetic_data } = processing;
	const { handleButtonDeleteClick, formattedDate, handleEyeClick } = useProcessingItem({processing});
	
	return (
		<ListItem
			secondaryAction={
				<Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
					{synthetic_data !== null && (
						<IconButton edge="end" aria-label="delete" onClick={handleEyeClick}>
							<VisibilityIcon />
						</IconButton>
					)}
					<IconButton edge="end" aria-label="delete" onClick={handleButtonDeleteClick}>
						<DeleteIcon />
					</IconButton>
				</Stack>
			}
		>
			<ListItemText primary={
					<Stack direction={'row'} justifyContent={'space-between'} >
						<Box sx={{display:'inline-block'}}>
							<Chip label={modality} size={'small'} /> - {formattedDate} <Chip label={status} color={'success'} size={'small'}/>
						</Box>
					</Stack>
				} secondary={<Typography variant="caption" display="block" gutterBottom>{`Hash: ${data_hash}`}</Typography>}
			/>
		</ListItem>
	);
};
export default ProcessingItem;