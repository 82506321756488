import React, {} from 'react';
import { useParams} from "react-router-dom";

import Stack from "@mui/material/Stack";
import HeadRow from "@ui/HeadRow";
import InstrDataCreateModal from "@InstrData/CreateModal/InstrDataCreateModal";
import DataAccordion from "./components/DataAccordion/DataAccordion";

import useTranslate from "@hooks/useTranslate";
import translates from "./translates";

import useDataBlock from "./useDataBlock";


const DataBlock = () => {
	
	const {patientId} = useParams();
	
	const {patientData, addNewRawFile, isOpen, setIsOpen} = useDataBlock({patientId});
	const {buttonTitle, blockTitle} = useTranslate({translates});
	
	return (
		<Stack spacing={2}>
			<HeadRow title={{text: blockTitle}} btn={{text: buttonTitle, onClick: addNewRawFile}}/>
			{patientData.map((dataItem,i) =>
				<DataAccordion dataItem={dataItem} key={i}/>
			)}
			<InstrDataCreateModal isOpen={isOpen} setIsOpen={setIsOpen} initialOverride={{}}/>
		</Stack>
	);
};
export default DataBlock;