import {useSelector} from "react-redux";

import useCreatePoint from "./useCreatePoint";
import useCreateLine from "./useCreateLine";
import useCreateText from "./useCreateText";


const useCreateMode = ({rN}) => {
	
	const {createPoint} = useCreatePoint({rN});
	const {createLine} = useCreateLine({rN});
	const {createText} = useCreateText({rN});
	
	const {createMode} = useSelector((state) => state.drawLayer);
	
	const createObject = (point) => {
		let createFn = null;
		switch (createMode) {
			case 'point':
				createFn = createPoint;
				break;
			case 'line':
			case 'ruler':
				createFn = createLine;
				break;
			case 'text':
				createFn = createText;
				break;
			default:
				console.error('wrong mode name!');
		}
		createFn !== null && createFn(point, createMode)
	};
	
	return {
		createObject,
	}
};
export default useCreateMode;