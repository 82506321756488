import React from "react";

import LoginLayout from "@ui/layout/LoginLayout";

import RegisterForm from "../components/Profile/RegisterForm";


const RegisterPage = () => {
	return (
		<LoginLayout>
			<RegisterForm/>
		</LoginLayout>
	);
};
export default RegisterPage;
