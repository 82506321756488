import React, {} from 'react';

import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import FavoriteListHome from "@components/Favorite/ListHome/FavoriteListHome";

import useFavoriteBlock from "./useFavoriteBlock";


const FavoriteBlock = () => {
	
	const {trans} = useFavoriteBlock();
	return (
		<Card sx={{mb:4}}>
			<CardHeader title={trans.blockTitle}/>
			<Divider/>
			<CardContent>
			  <FavoriteListHome />
			</CardContent>
		</Card>
	);
};
export default FavoriteBlock;