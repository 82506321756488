import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const useProcessingBlock = () => {
	const trans = useTranslate({translates});
	return {
		trans,
	};
};

export default useProcessingBlock;