import {useState} from "react";

import patientGetRequest from "@api/patient/get";


function usePatient({patientId}) {
	
	const [loading, setLoading] = useState(false);
	
	const getSingleSubject = async () => {
		setLoading(true);
		const response = await patientGetRequest(patientId);
		setLoading(false);
		return response;
	};
	
	return {
		getSingleSubject,
		loading,
	}
}
export default usePatient;