import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import favoriteGetAllLocal from "../../../localStore/favorite/getAll";
import {favoritesSetRedux} from "@redux/favorite/actions";


const FAVORITE_COUNT = 2;

const useFavoriteListHome = () => {
	
	const dispatch = useDispatch();
	
	const {favorite} = useSelector((state) => state.favorite);
	
	const getFavoriteList = async () => {
		const result = await favoriteGetAllLocal();
		dispatch(favoritesSetRedux(result.slice(-FAVORITE_COUNT)))
	};
	
	useEffect(()=>{
		
		getFavoriteList()
			.catch((error)=>console.error(error))
		
	},[]);
	
	return {
		favoriteList:favorite
	};
};
export default useFavoriteListHome;