import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as THREE from "three";

import {
	activeTmPointSetRedux,
	activeTmSliceIndexesUpdateRedux,
	realSliceUpdateAllPerspectivesIndexRedux,
} from "@redux/tomograms/actions";
import {
	rayCastSetRedux,
	rayCastHelperSetRedux,
	rayCastInflatedSetRedux,
} from "@redux/rayCast/actions";

import {getRayCasts, matrixTransformWithWorldCenter} from "@utils/index";

import useCreateMode from "./useCreateMode";
import {INFLATED_OFFSET} from "../../../../../../constants";
import getTmDataToUpdate from "../utils/getTmDataToUpdate";
import {labelMeshUpdateSliceIndexesRedux} from "@redux/labelTomogram/actions";
import {syntheticUpdateAllPerspectivesIndexRedux} from "@redux/syntheticTomograms/actions";


const useTomogramMesh = ({rN}) => {
	
	const dispatch = useDispatch();
	
	const {createObject} = useCreateMode({rN});
	
	const geometryState = useSelector((state) => state.geometry);
	const {pial, inflated, activeGeometry} = useSelector((state) => state.geometry);
	const {createMode} = useSelector((state) => state.drawLayer);
	const {real} = useSelector((state) => state.tomograms);
	const {synthetic} = useSelector((state) => state.syntheticTomograms);
	
	const {labelMesh} = useSelector((state) => state.labelTomogram);
	
	const [mousePos, setMousePos] = useState({x: 0, y: 0});
	
	const handleLocalize = ({intersections}) => {
		
		const {point} = intersections[0];
		
		const stack = real[0][rN].stackHelper.stack;
		const activeSurface = geometryState[activeGeometry];
		
		dispatch(activeTmPointSetRedux(point));
		
		// set rayCast
		if (activeSurface.left !== null || activeSurface.right !== null) {
			const worldCenter = stack.worldCenter();
			
			// transform point to 3D coordinate
			const transformedPoint = {
				x: -1 * point.x + worldCenter.x,
				y: -1 * point.y + worldCenter.y,
				z: point.z - worldCenter.z
			};
			
			const rayCasts = getRayCasts(transformedPoint, pial);
			dispatch(rayCastSetRedux(rayCasts));
			
			if (inflated.left !==null && inflated.right !==null){
				const rayCastsInflated = getRayCasts(transformedPoint, inflated,{x: INFLATED_OFFSET, y: 0, z: 0});
				dispatch(rayCastInflatedSetRedux(rayCastsInflated));
			}
			
			dispatch(rayCastHelperSetRedux(new THREE.Vector3().copy(transformedPoint)));
		}
		
		const {lps2IJK, _spacing, dimensionsIJK} = stack;
		
		const ijk = matrixTransformWithWorldCenter(_spacing, point, lps2IJK, stack.worldCenter());
		
		dispatch(activeTmSliceIndexesUpdateRedux({...getTmDataToUpdate(real[0][rN].sliceOrientation, ijk, dimensionsIJK)}));
		
		// SET TOMOGRAMS INDEX
		real.map((tm, i) => dispatch(realSliceUpdateAllPerspectivesIndexRedux({
			index: i,
			data: {...getTmDataToUpdate(tm[rN].sliceOrientation, ijk, dimensionsIJK)}
		})));
		
		if (synthetic.length !== 0 && intersections[1]) {
			synthetic.map((tm, i) => dispatch(syntheticUpdateAllPerspectivesIndexRedux({
				index: i,
				data: {...getTmDataToUpdate(tm[rN].sliceOrientation, ijk, dimensionsIJK)}
			})))
		}
		if (labelMesh !== null) {
			dispatch(labelMeshUpdateSliceIndexesRedux({
				data: {...getTmDataToUpdate(labelMesh[rN].sliceOrientation, ijk, dimensionsIJK)}
			}));
		}
		
	};
	
	const onPointerUp = (event) => {
		// +- 2px move range
		if (mousePos.x - event.pageX < 2 && mousePos.y - event.pageY < 2) {
			createMode
				? createObject(event.intersections[0].point)
				: handleLocalize(event)
		}
	};
	
	const onPointerDown = (event) => {
		setMousePos({x: event.pageX, y: event.pageY})
	};
	
	return {
		onPointerDown,
		onPointerUp,
	}
};
export default useTomogramMesh;