import React, {} from 'react';

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const CustomInput = ({ field, handleChange, setFieldValue, className, errors, values, ...rest}) => {
	const {emptySelect} = useTranslate({translates});
	return (
		<>
			{field.type === "select" ? (
				<FormControl className={className}>
					<InputLabel id="demo-select-small">{field.label}{field.required && " *"}</InputLabel>
					<Select labelId="demo-select-small" id="demo-select-small" value={values[field.name]} onChange={handleChange} {...field}>
						<MenuItem value=""><em>{emptySelect}</em></MenuItem>
						{field.items.map( (item,i) => <MenuItem key={i} value={item.value}>{item.key}</MenuItem>)}
					</Select>
				</FormControl>
			) : (
				<TextField
					className={className}
					error={errors[field.name] !== undefined}
					helperText={errors[field.name]}
					value={field.type === 'file' ? undefined : values[field.name]}
					onChange={field.type === 'file' ? (event) => setFieldValue(field.name, event.currentTarget.files[0]) : handleChange}
					InputLabelProps={{shrink: field.type === 'file' || undefined}}
					autoComplete={"off"}
					{...field}
					{...rest}
				/>
			)}
		</>
	);
};
export default CustomInput;
