import update from 'immutability-helper';
import {SURFACE_MAP_ADD, ACTIVE_SURFACE_MAP_SET} from "./constants";


const initialTomogramsState = {
	mapsList:[],
	activeSurfaceMap: 0,
};

export const surfaceMaps = (state = initialTomogramsState, action) => {
	switch (action.type) {
		case ACTIVE_SURFACE_MAP_SET:
			return update(state,{activeSurfaceMap:{$set:action.payload}});
		case SURFACE_MAP_ADD:
			const index = state.mapsList.findIndex(map=>map.type===action.payload.type);
			return (index === -1)
				? update(state,{ mapsList:{ $push:[{ type:action.payload.type, left:null, right:null, [action.payload.side]:action.payload.data }] } })
				: update(state,{ mapsList:{ [index]:{[action.payload.side]:{$set:action.payload.data}}}});
		default:
			return state;
	}
};
