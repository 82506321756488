import useFavorite from "@hooks/useFavorite";


const useFavoriteListItem = () => {
	const {favoriteDelete} = useFavorite();
	
	const handleDeleteClick = async (id)=>{
		await favoriteDelete(id);
	};
	return {
		handleDeleteClick
	};
};
export default useFavoriteListItem;