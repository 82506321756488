import React, {} from 'react';

import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DeleteIcon from "@mui/icons-material/Delete";

import useRoiPointProtocolSingleItem from "./useRoiPointProtocolSingleItem";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const RoiPointProtocolSingleItem = ({point}) => {
	
	const {tooltipTitle} = useTranslate({translates});
	const {handlePointIconClick, handleItemClick, handleDeleteClick, isActive} = useRoiPointProtocolSingleItem({point});
	
	return (
		<ListItemButton selected={isActive} onClick={handleItemClick} sx={{cursor: "pointer"}} divider>
			<ListItemIcon>
				<Tooltip title={tooltipTitle}>
					<IconButton size="small" onClick={handlePointIconClick}>
						<RadioButtonCheckedIcon color={isActive ? 'success' :"disabled"}/>
					</IconButton>
				</Tooltip>
			</ListItemIcon>
			<ListItemText primary={`id: ${point.id}`}/>
			<IconButton size="medium" onClick={handleDeleteClick}>
				<DeleteIcon fontSize="inherit"/>
			</IconButton>
		</ListItemButton>
	);
};
export default RoiPointProtocolSingleItem;

