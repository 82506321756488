import React from 'react';

import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
// import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import ImageIcon from '@mui/icons-material/Image';
import EditIcon from '@mui/icons-material/Edit';

import useCommentListItem from "./useCommentListItem";


// TODO: add translation
const CommentListItem = ({comment, isEditEnable=true, isDeleteEnable=true}) => {
	
	const {formattedDate, handleDeleteClick, handleEditClick} = useCommentListItem({comment});
	
	const renderEditButton = ()=>{
		return (
			<IconButton size="small" disabled={!isEditEnable} onClick={handleEditClick}>
				<EditIcon fontSize="inherit"/>
			</IconButton>
		)
	};
	const renderSecondary = ()=>{
		return (
			<>
				{/*<Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
					to Scott, Alex, Jennifer
				</Typography>*/}
				{" — " + comment.comment}
				<br/>
				{isDeleteEnable && <Link href="#" variant="body2" underline="hover" color='error' onClick={handleDeleteClick} sx={{display:"inline-block", mt:0.5}}>удалить</Link>}
			</>
		)
	};
	
	return (
		<ListItem alignItems="flex-start" divider secondaryAction={renderEditButton()}>
			<ListItemAvatar>
				<Avatar>
					<ImageIcon />
				</Avatar>
			</ListItemAvatar>
			<ListItemText primary={<><b>{comment.author.first_name} {comment.author.last_name}</b> <i>{formattedDate}</i></>}
			              secondary={renderSecondary()} />
		</ListItem>
	);
};
export default CommentListItem;