// import React from 'react';
// import {Link} from 'react-router-dom';
// import {Formik} from 'formik';

// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Stack from "@mui/material/Stack";
// import Alert from "@mui/material/Alert";
// import CustomInput from "@ui/form/CustomInput/CustomInput";

// import routes from "../../../routes";

// import useLoginForm from "./useLoginForm";
// import useTranslate from "@hooks/useTranslate";
// import translates from "./translates";


// const LoginForm = () => {
	
// 	const {onSubmit, fields, initialValues, validate} = useLoginForm();
	
// 	const {formTitle, submitButtonTitle, submitButtonProcessTitle, or, register, fields:_fields} = useTranslate({translates});
// 	return (
// 		<Formik {...{initialValues, validate, onSubmit}} validateOnChange={false} validateOnBlur={false}>
// 			{({values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, status}) => (
// 				<form onSubmit={handleSubmit} autoComplete="off" style={{width: 240}}>
// 					<Typography component="h5" variant="h5" sx={{textAlign: "center"}}>{formTitle}</Typography>
// 					<Stack spacing={1.5} sx={{mt: 2}}>
// 						{fields.map((field, i) => (
// 							<CustomInput size="small" {...{field:{...field,label:_fields[field.name]}, values, errors, handleChange, setFieldValue}} key={i}/>
// 						))}
// 					</Stack>
// 					{!!status && <Alert severity="error" sx={{mt: 2}}>{status}</Alert>}
// 					<Button variant="contained" type={'submit'} disabled={isSubmitting}
// 					        sx={{width: "100%", mt: 2}}>{isSubmitting ? `${submitButtonProcessTitle}...` : submitButtonTitle}</Button>
// 					<Stack spacing={2} alignItems="center" sx={{mt: 2}}>
// 						<div>{or}</div>
// 						<Link to={routes.register}>{register}</Link>
// 					</Stack>
// 				</form>
// 			)}
// 		</Formik>
// 	);
// };
// export default LoginForm;


import React from 'react';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import CustomInput from "@ui/form/CustomInput/CustomInput";

import routes from "../../../routes";

import useLoginForm from "./useLoginForm";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const LoginForm = () => {
	
	const {onSubmit, fields, initialValues, validate} = useLoginForm();
	
	const {formTitle, submitButtonTitle, submitButtonProcessTitle, or, register, fields:_fields} = useTranslate({translates});

	return (
		<div style={{
			height: '100vh',
			width: '100vw',
			backgroundImage: 'url("https://bogatyr.club/uploads/posts/2023-06/1687358031_bogatyr-club-p-tsvetnoi-mozg-foni-vkontakte-68.png")',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<Formik {...{initialValues, validate, onSubmit}} validateOnChange={false} validateOnBlur={false}>
				{({values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, status}) => (
					<form
					onSubmit={handleSubmit}
					autoComplete="off"
						style={{
							background: 'none', // Убираем белый фон
							padding: '20px',
							borderRadius: '8px',
							boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
							width: '100%',
							maxWidth: '400px'
							}}
							>
						<Typography component="h5" variant="h5" sx={{textAlign: "center"}}>{formTitle}</Typography>
						<Stack spacing={1.5} sx={{mt: 2}}>
							{fields.map((field, i) => (
								<CustomInput size="small" {...{field:{...field,label:_fields[field.name]}, values, errors, handleChange, setFieldValue}} key={i}/>
							))}
						</Stack>
						{!!status && <Alert severity="error" sx={{mt: 2}}>{status}</Alert>}
						<Button variant="contained" type={'submit'} disabled={isSubmitting}
								sx={{width: "100%", mt: 2}}>{isSubmitting ? `${submitButtonProcessTitle}...` : submitButtonTitle}</Button>
						<Stack spacing={2} alignItems="center" sx={{mt: 2}}>
							<div>{or}</div>
							<Link to={routes.register}>{register}</Link>
						</Stack>
					</form>
				)}
			</Formik>
		</div>
	);
};
export default LoginForm;
