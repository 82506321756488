import { createAction } from 'redux-actions';

import {
	LABEL_MESH_CREATE,
	LABEL_MESH_SHOW_TOGGLE,
	LABEL_MESH_UPDATE_SLICE_INDEXES,
	LABEL_MESH_UPDATE_INDEX,
	LABEL_MESH_CLEAR,
} from './constants';


const labelMeshCreateAction = createAction(LABEL_MESH_CREATE);
const labelMeshShowToggleAction = createAction(LABEL_MESH_SHOW_TOGGLE);
const labelMeshUpdateSliceIndexesAction = createAction(LABEL_MESH_UPDATE_SLICE_INDEXES);
const labelMeshUpdateIndexAction = createAction(LABEL_MESH_UPDATE_INDEX);
const labelMeshClearAction = createAction(LABEL_MESH_CLEAR);

export const labelMeshCreateRedux = (payload) => async (dispatch) => dispatch(labelMeshCreateAction(payload));
export const labelMeshShowToggleRedux = (payload) => async (dispatch) => dispatch(labelMeshShowToggleAction(payload));
export const labelMeshUpdateSliceIndexesRedux = (payload) => async (dispatch) => dispatch(labelMeshUpdateSliceIndexesAction(payload));
export const labelMeshUpdateIndexRedux = (rn, stackIndex, index) => async (dispatch) => dispatch(labelMeshUpdateIndexAction({rn, stackIndex, index}));
export const labelMeshClearRedux = () => async (dispatch) => dispatch(labelMeshClearAction());
