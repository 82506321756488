// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrawLog {
  position: fixed;
  width: 250px;
  max-height: 150px;
  top: 10px;
  left: 10px;
  background: #181c20;
  color: white;
  z-index: 100000;
  padding: 0 10px;
  overflow: auto;
}
.DrawLog > * {
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Viewer/components/DrawLog/DrawLog.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,iBAAA;EACA,SAAA;EACA,UAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,cAAA;AACF;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".DrawLog {\n  position: fixed;\n  width: 250px;\n  max-height: 150px;\n  top: 10px;\n  left: 10px;\n  background: #181c20;\n  color: white;\n  z-index: 100000;\n  padding: 0 10px;\n  overflow: auto;\n  & > * {\n    margin: 10px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
