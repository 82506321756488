import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {syntheticUpdateIndexRedux} from "@redux/syntheticTomograms/actions";


const useTomogramCanvas = ({rN}) => {
	const dispatch = useDispatch();
	
	const {real} = useSelector((state) => state.tomograms);
	const {synthetic, activeSyntheticIndex, showSynthetic} = useSelector((state) => state.syntheticTomograms);
	
	const {labelMesh} = useSelector((state) => state.labelTomogram);
	
	const slices = ['r1', 'r2', 'r3'];
	
	const index = slices.indexOf(rN);
	if (index !== -1) {
		slices.splice(index, 1);
	}
	
	const filterSyntheticByActiveIndex = tm => {
		/*console.log(tm)
		console.log(activeSyntheticIndex)
		console.log(activeSyntheticIndex.findIndex(item => {
			console.log(tm.alias)
			console.log(item.name)
			return tm.alias === item.name && item.value
		}) !== -1)*/
		return activeSyntheticIndex.findIndex(item => tm.alias === item.name && item.value) !== -1;
	};
	
	useEffect(() => {
		if (synthetic.length !== 0) {
			dispatch(syntheticUpdateIndexRedux('r1', real[0].r1.stackHelper._index, synthetic.length - 1));
			dispatch(syntheticUpdateIndexRedux('r2', real[0].r2.stackHelper._index, synthetic.length - 1));
			dispatch(syntheticUpdateIndexRedux('r3', real[0].r3.stackHelper._index, synthetic.length - 1));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [synthetic,labelMesh]);
	
	useEffect(() => {
		console.info('%c CanvasTomogram update ', 'background: #222; color: #bada55')
	}, [showSynthetic, activeSyntheticIndex, real, synthetic, labelMesh]);
	return {
		filterSyntheticByActiveIndex,
		slices,
	};
};
export default useTomogramCanvas;