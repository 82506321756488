import axiosService from "../../libs/services/axiosService";
import {buildUrl} from "../settings";
import {SYNTHETIC_DATA_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const syntheticDataGetRequest = async (dataId)=>{
	
	const result = await axiosService.get(buildUrl(`/${SYNTHETIC_DATA_ROOT}/${dataId}`));
	
	return buildResponse(result);
};
export default syntheticDataGetRequest;