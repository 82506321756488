import * as THREE from "three";


const getTextRotation = (rN) => {
	switch (rN) {
		case 'r1':
			return new THREE.Euler(0, Math.PI, Math.PI, 'XYZ');
		case 'r2':
			return new THREE.Euler(0, Math.PI / 2, Math.PI / 2, 'XYZ');
		case 'r3':
			return new THREE.Euler(Math.PI / 2, 0, 0, 'XYZ');
		default:
			return new THREE.Euler();
	}
};
export default getTextRotation;