// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadBlock {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #232323;
  color: white;
  text-align: center;
}
.uploadBlock__input {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  z-index: 1;
}
.uploadBlock__input:hover {
  cursor: pointer;
}
.uploadBlock__title {
  width: 150px;
  font-size: 20px;
  line-height: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/Viewer/components/UploadBlock/uploadBlock.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AACF;AAAE;EACE,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,UAAA;EACA,UAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,YAAA;EACA,eAAA;EACA,cAAA;AAEJ","sourcesContent":[".uploadBlock {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  background-color: #232323;\n  color: white;\n  text-align: center;\n  &__input {\n    display: block;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    opacity: 0;\n    z-index: 1;\n  }\n  &__input:hover {\n    cursor: pointer;\n  }\n  &__title {\n    width: 150px;\n    font-size: 20px;\n    line-height: 1;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
