import React from 'react';
import {Stats, Text} from "@react-three/drei";
// import Axes from "../../elements/Axes";
import {useSelector} from "react-redux";
import RayCast from "./RayCast";


const HelpersLayer = () => {
	const {activeGeometry} = useSelector((state) => state.geometry);
	const {rayCast, rayCastInflated, rayCastHelper } = useSelector((state) => state.rayCast);
	
	return (
		<>
			{/*<Axes/>*/}
			{process.env.NODE_ENV === 'development' && (<Stats/>)}
			
			<axesHelper args={[150]}/>
			<Text color="white" fontSize={12} lineHeight={1} rotation={[Math.PI/2,Math.PI,0]}  position={[100,0,100]}>R</Text>
			<Text color="white" fontSize={12} lineHeight={1} rotation={[Math.PI/2,Math.PI,0]} position={[-100,0,100]}>L</Text>
			
			{activeGeometry === 'inflated'
				? <RayCast position={rayCastInflated} color={"green"}/>
				: <RayCast position={rayCast}/>}
			
			<RayCast position={rayCastHelper} color={"purple"}/>
			{/*<gridHelper args={[500, 500]} rotation={[Math.PI/2,0,0]} />*/}
		</>
	);
};
export default HelpersLayer;