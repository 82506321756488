import {useCallback, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {roiPointCreateManyRedux} from "@redux/ROIPoint/actions";
import syntheticDataGetRoiRequest from "@api/synthetic-data/getRoi";


const useRoiPointList = () => {
	
	const {dataId} = useParams();
	
	const dispatch = useDispatch();
	
	const {roiPointList} = useSelector((state) => state.ROIPoint);
	const {editableProtocolId} = useSelector((state) => state.protocol);
	
	const getPoints = useCallback(async () => {
		const { isSuccess, data } = await syntheticDataGetRoiRequest(dataId,`protocol_id=${editableProtocolId}`);
		isSuccess && dispatch(roiPointCreateManyRedux(data));
	},[dispatch, editableProtocolId]);
	
	useEffect(() => {
		getPoints().catch((error)=>console.error(error))
	},[editableProtocolId,getPoints]);
	
	return {
		roiPointList,
	};
};
export default useRoiPointList;