import React, {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import protocolGetRoiRequest from "@api/protocol/getRoi";
import commentGetAllRequest from "@api/comment/getAll";
import downloadTextAsFile from "@utils/downloadTextAsFile";
import protocolUpdateRequest from "@api/protocol/update";
import {default as protocolDeleteRequest} from "@api/protocol/delete";
import protocolGetAllRequest from "@api/protocol/getAll";


const useSingleProtocol = ({protocolId})=> {
	
	const navigate = useNavigate();
	
	const [protocol, setProtocol] = React.useState(null);
	
	const handleSaveAsFileClick = async () => {
		const {isSuccess, data} = await protocolGetRoiRequest(protocol.id);
		const response = await commentGetAllRequest();
		
		const reducedRoi = data.reduce((acc, roiPoint)=>[...acc, {...roiPoint, comments:response.data.filter(comment=>comment.roi_id===roiPoint.id)}],[]);
		
		const str = reducedRoi.map(roi=>[` roi ${roi.id}: x=${roi.coord_x} y=${roi.coord_y} z=${roi.coord_z}`,` roi ${roi.id} comments:`, ...roi.comments.map(comment=>`  - ${comment.comment} (author: ${comment.author_id})`)].join('\n')).join('\n\n');
		
		if (isSuccess) {
			const fileName = `protocol-${protocol.id}(${protocol.state}).txt`;
			const fileText = [
				`protocol ${protocol.id}`,
				`status: ${protocol.state}`,
				``,
				`roi points:`,
				``,
				str,
			].join('\n');
			downloadTextAsFile(fileName, fileText)
		}
	};
	
	const handlePublish = async () => {
		const {isSuccess} = await protocolUpdateRequest(protocol.id, {state:'final'});
		isSuccess && setProtocol({...protocol, state:'final'})
	};
	
	const handleDelete = async () => {
		const {isSuccess} = await protocolDeleteRequest(protocol.id);
		isSuccess && navigate(-1)
	};
	
	const getProtocol = useCallback(async () =>{
		const {isSuccess,data} = await protocolGetAllRequest();
		if (isSuccess){
			const index = data.findIndex((el)=>el.id=== parseInt(protocolId));
			index !== -1 && setProtocol(data[index])
		}
	},[protocolId]);
	
	useEffect(()=>{
		getProtocol()
			.catch((result)=>console.error(result))
	},[protocolId,getProtocol]);
	
	return {
		protocol,
		protocolId,
		handleDelete,
		handlePublish,
		handleSaveAsFileClick
	};
};

export default useSingleProtocol;