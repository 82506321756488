const fields = [
	{name: "username", label: "Логин Пользователя", type: "text", required: true},
	{name: "first_name", label: "Имя", type: "text", required: true},
	{name: "last_name", label: "Фамилия", type: "text", required: true},
	{name: "organization", label: "Организация", type: "text", required: true},
	{name: "password", label: "Пароль", type: "password", required: true},
];

const initialValues = {username: '', first_name:'', last_name:'', organization: '', password:''};

const validate = values => { // TODO: fix validation
	const error = {};

	if (values.name === '') {
		error.name = 'required';
	}

	if (values.organization === '') {
		error.organization = 'required';
	}

	return error;
};

export {fields, initialValues, validate};