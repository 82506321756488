import React from 'react';
import {useNavigate, useParams} from "react-router-dom";

import routes from "../../routes";


const usePatientCreateSuccessPage = () => {
	
	const navigate = useNavigate();
	const {patientId} = useParams();
	
	const [open, setOpen] = React.useState(false);
	
	const goToPatientClick = () => navigate(routes.patients + "/" + patientId);
	const handleOpen = () => setOpen(true);
	
	return {
		open,
		setOpen,
		goToPatientClick,
		handleOpen,
	};
};
export default usePatientCreateSuccessPage;