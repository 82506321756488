import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {ROI_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const roiDeleteRequest = async (roiId) => {
	
	const result = await axiosService.delete(buildUrl(`/${ROI_ROOT}/${roiId}`));
	
	return buildResponse(result);
};
export default roiDeleteRequest;