import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import * as THREE from "three";
import {useFrame, useThree} from "@react-three/fiber";

import setUniform from "./utils/setUniform";

import useTomogramMesh from "./hooks/useTomogramMesh";


const TomogramMesh = ({rN, tm, isSynthetic=false}) => {
	
	const {camera} = useThree();
	const {onPointerDown, onPointerUp} = useTomogramMesh({rN});
	// const {activeSyntheticIndex} = useSelector((state) => state.syntheticTomograms);
	const rayCast = useSelector((state) => state.rayCast);
	const {zoomSize} = useSelector((state) => state.tomograms);
	const {colormap} = useSelector((state) => state.geometry);
	
	// console.log(tomograms.real)
	// useFrame(({ gl, scene, camera }) => {
	//   console.log(gl.getContext());
	//   gl.render(scene, camera);
	// }, 1);
	
	camera.updateProjectionMatrix();
	camera.zoom = zoomSize[rN];
	
	// TODO: useMemo ???
	// const geometry = useMemo(() => tm[rN].stackHelper.slice.mesh.geometry, [rayCast,activeSyntheticIndex]);
	const geometry = tm[rN].stackHelper.slice.mesh.geometry;
	console.log(geometry)
	// const material = useMemo(() => tm[rN].stackHelper.slice.mesh.material, [rayCast,activeSyntheticIndex]);
	// const material = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, color: 0xffff00 })
	const material = tm[rN].stackHelper.slice.mesh.material;
	
	const halfDimensionsIJK = tm[rN].stackHelper.stack.halfDimensionsIJK;
	
	const position = new THREE.Vector3()
		.copy(halfDimensionsIJK)
		.negate();
	
	useFrame(() => isSynthetic && setUniform(tm[rN],colormap));
	
	// DEBUG
	// real[rN].stackHelper.slice.mesh.material.uniforms.uInvert.value = 0;
	// tomograms.real[rN].stackHelper.slice.mesh.material.transparent = true;
	// tomograms.real[rN].stackHelper.slice.mesh.material.uniforms.uOpacity.value = 1;
	
	useEffect(() => {
	}, [rayCast,colormap]);
	
	useEffect(() => {
		camera.updateProjectionMatrix();
	}, [camera]);
	
	return (
		<mesh onPointerUp={onPointerUp} onPointerDown={onPointerDown} position={position} geometry={geometry}
		      material={material}/>
	);
};
export default TomogramMesh;