import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

import useRoiPoint from "@hooks/useRoiPoint";

import roiCreateRequest from "@api/roi/create";
import protocolUpdateWithRoiRequest from "@api/protocol/updateWithRoi";


const useRoiPointListHead = () => {
	
	const {dataId} = useParams();
	const {createPoint} = useRoiPoint();
	
	const {activeTmPoint, activeTmSliceIndexes} = useSelector((state) => state.tomograms);
	const {editableProtocolId} = useSelector((state) => state.protocol);
	const {rayCast} = useSelector((state) => state.rayCast);
	
	const handleButtonClick = async () => {
		const newPoint = {
			note: `${JSON.stringify({...rayCast})};${JSON.stringify({...activeTmSliceIndexes})}`,
			coord_x: activeTmPoint.x,
			coord_y: activeTmPoint.y,
			coord_z: activeTmPoint.z,
			synthetic_data_id: Number(dataId),
		};
		
		const {isSuccess, data} = await roiCreateRequest(newPoint);
		
		editableProtocolId !== null && await protocolUpdateWithRoiRequest(editableProtocolId,{"rois": [data.id]});
		
		isSuccess && createPoint(data);
	};
	
	return {
		handleButtonClick
	};
};

export default useRoiPointListHead;