import React, { useEffect, useRef } from 'react';
import {useSelector} from "react-redux";
import {useThree} from "@react-three/fiber";
import useVolume3D from "./useVolume3D";


const Volume3D = () => {
	
	const groupRef = useRef();
	const {camera} = useThree();
	const {onClick} = useVolume3D();
	
	const {pialLeft, pialRight, camera_near} = useSelector((state) => state.gltf);
	
	useEffect(()=>{
		camera.near = camera_near;
		camera.updateProjectionMatrix();
	},[camera_near]);
	
	return (
		<group ref={groupRef} dispose={<div>loading...</div>}>
			{pialLeft && <primitive object={pialLeft.scene} onClick={false ? undefined : onClick}/>}
			{pialRight && <primitive object={pialRight.scene} onClick={false ? undefined : onClick}/>}
		</group>
	);
};
export default Volume3D;