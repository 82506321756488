import axiosService from "../../libs/services/axiosService";
import {buildUrl} from "../settings";
import {RAW_DATA_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const rawDataGetProcessingRequest = async (dataId)=>{
	
	const result = await axiosService.get(buildUrl(`/${RAW_DATA_ROOT}/${dataId}/processings`));
	
	return buildResponse(result);
};
export default rawDataGetProcessingRequest;