import React, {useEffect, useState} from 'react';

import List from "@mui/material/List";
import Box from "@mui/material/Box";
import EmptyBlock from "@ui/EmptyBlock";

import ROIPointItem from "../Item/ROIPointItem";

import useRoiPointGltfList from "./useRoiPointGltfList";
import GltfROIPointItem from "../GltfItem/GltfROIPointItem";


const ROIPointGltfList = ({...rest}) => {
	
	const {roiPointList} = useRoiPointGltfList();

	return (
		<Box sx={{
			overflow: "auto",
			mt: 2,
			border: roiPointList.length > 0 ? "1px solid grey" : "none",
			flex: "0 1 100%"
		}}>
			{roiPointList.length > 0 && (
				<List dense={true} {...rest}>
					{roiPointList.map((point, i) => (
						<GltfROIPointItem point={point} key={i}/>
					))}
				</List>
			)}
			{roiPointList.length <=0 && <EmptyBlock text={'no points'} sx={{height:"100%", ">*": { height:"calc(100% - 2px)!important" }}}/>}
		</Box>
	);
};
export default ROIPointGltfList;
