const getDateString = dateObj => {

	const date = dateObj.getDate();
	const month = dateObj.getMonth();
	const fullYear = dateObj.getFullYear();
	const hours = dateObj.getHours();
	const minutes = dateObj.getMinutes();

	return `${date}.${month}.${fullYear} ${hours}:${minutes}`
};
export default getDateString;