import React from 'react';

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";


const RowKeyValueList = ({list}) => {
	return (
		<Stack spacing={1} direction={'row'}>
			{list.map((item,i)=>(
				<React.Fragment key={i}>
					{i !== 0 && <Divider orientation="vertical" flexItem />}
					<Stack spacing={0} alignItems={'center'}>
						<Typography variant="h6" component='h6'>{item.key}</Typography>
						<Typography variant="body2" component='p' sx={{textAlign:'center'}}>{item.value}</Typography>
					</Stack>
				</React.Fragment>
			))}
		</Stack>
	);
};
export default RowKeyValueList;