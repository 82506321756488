import React from 'react';

import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";


const FormGroup = ({group, children}) => {
	return (
		<>
			<FormLabel component="legend">{group.label}</FormLabel>
			<Stack sx={{mt: 1}} spacing={1.5}>
				{children}
			</Stack>
		</>
	);
};

export default FormGroup;