const translates = {
	en: {
		gender: {
			male: "male",
			female: "female",
		}
	},
	ru: {
		gender: {
			male: "муж.",
			female: "жен.",
		}
	}
};

export default translates;