import {useDispatch} from "react-redux";

import commentDeleteRequest from "@api/comment/delete";
import roiCreateCommentRequest from "@api/roi/createComment";
import commentUpdateRequest from "@api/comment/update";
import {commentDeleteRedux, commentsUpdateRedux, commentUpdateRedux} from "@redux/comment/actions";


function useComment() {
	
	const dispatch = useDispatch();
	
	const commentDelete = async (commentId)=>{
		await commentDeleteRequest(commentId);
		dispatch(commentDeleteRedux({commentId}))
	};
	const commentCreate = async (pointId, comment)=>{
		const {data} = await roiCreateCommentRequest(pointId,{...comment});
		dispatch(commentsUpdateRedux(data));
	};
	const commentUpdate = async (commentId, comment)=>{
		const {data} = await commentUpdateRequest(commentId,{...comment});
		dispatch(commentUpdateRedux({commentId,data}));
	};
	
	return {
		commentCreate,
		commentUpdate,
		commentDelete,
	}
}
export default useComment;