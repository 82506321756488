import {fields, initialValues} from "./fields";
import {useSelector} from "react-redux";

import useComment from "@hooks/useComment";


const useCommentCreateForm = () => {
	
	const {commentCreate} = useComment();
	const {roiPointActive} = useSelector((state) => state.ROIPoint);
	
	const onSubmit = async (values, {setSubmitting, resetForm}) => {
		
		await commentCreate(roiPointActive, {...values});
		
		resetForm();
		setSubmitting(false);
	};
	return {
		onSubmit,
		fields,
		initialValues,
	};
};
export default useCommentCreateForm;