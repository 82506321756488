import axiosService from "../../libs/services/axiosService";


const patientGetArchiveRequest = async (fileUrl, callback=()=>{}) => {
	
	const result = await axiosService.get(fileUrl,{
		responseType:'blob',
		onDownloadProgress: (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			callback(percentCompleted)
		}});
	return result.data;
};
export default patientGetArchiveRequest;