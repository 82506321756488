import React from "react";

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";


const ErrorLoading = ({message, action}) => {
	return (
		<Alert severity="error"
		       action={
			       <Button color="inherit" size="small" onClick={action}>попробовать еще раз</Button>
		       }>
			{message}
		</Alert>
	)
};
export default ErrorLoading;