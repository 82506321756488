import update from 'immutability-helper';
import {
	PROFILE_SET,
	PROFILE_DELETE,
} from "./constants";


const initialProfileState = {
	profile: null,
};

export const profile = (state = initialProfileState, action) => {
	switch (action.type) {
		
		case PROFILE_SET:
			return update(state, {
				profile: {$set: action.payload},
			});
			
		case PROFILE_DELETE:
			return update(state, {
				profile: {$set: null},
			});
		
		default:
			return state;
	}
};
