import React, {} from 'react';
import {Link} from "react-router-dom";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import VisibilityIcon from "@mui/icons-material/Visibility";

import useProcessingItemHome from "./useProcessingItemHome";


const ProcessingItemHome = ({processing}) => {
	
	const {dateRange, patientLink, eyeClickHandler} = useProcessingItemHome({processing});
	const { status, modality, subject, synthetic_data} = processing;
	
	return (
		<ListItem divider>
			<ListItemAvatar>
				<Avatar alt={`${subject.first_name} ${subject.last_name}`}/>
			</ListItemAvatar>
			<ListItemText primary={<Link to={patientLink}>{subject.first_name} {subject.last_name}</Link>}
			              secondary={<><Chip label={modality}/>{dateRange}</>}/>
			<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
				{synthetic_data !== null && (
					<IconButton edge="end" aria-label="delete" onClick={eyeClickHandler}>
						<VisibilityIcon />
					</IconButton>
				)}
				{status === 'progress'
					? <CircularProgress size={20}/>
					: (status === 'failed' ? <div style={{color: 'red'}}>{status}</div> :
						<div style={{color: 'green'}}>{status}</div>)}
			</Stack>
		</ListItem>
	);
};
export default ProcessingItemHome;