import {padWithZeros} from "./index";


const formatDate = (date) => {
	const dateObj = typeof date === 'string' ? new Date(date) : date;
	
	const day = padWithZeros(dateObj.getDate());
	const month = padWithZeros(dateObj.getMonth());
	const year = dateObj.getFullYear();
	
	return`${day}.${month}.${year}`
};
export default formatDate;