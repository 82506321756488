import axiosService from "../../libs/services/axiosService";
import {buildUrl} from "../settings";
import {PROCESSINGS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const processingGetClustersRequest = async (processingId)=>{
	
	const url = `/${PROCESSINGS_ROOT}/${processingId}/clusters`;
	const result = await axiosService.get(buildUrl(url));
	return buildResponse(result);
	
	/*return new Promise((resolve)=>{
		setTimeout(()=>{
			resolve([
				{
					name: "clusters_XGBoost.txt",
					points:[
						{
							slices:[116, 71, 95],
							chance: 24,
							size: 32
						},
						{
							slices:[114, 72, 85],
							chance: 20,
							size: 191,
						},
					]
				}
			])
		},100)
	})*/
};
export default processingGetClustersRequest;