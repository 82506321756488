import React, {} from 'react';
import ClusterItem from "../ClusterItem";

import useClustersList from "./useClustersList";


const ClustersList = () => {
	
	const {clustersList, onItemClick} = useClustersList();

	return (
		<div>
			{clustersList.map((cluster,i)=>(
				<div key={i}>
					<div>{cluster.name}</div>
					<div>
						{cluster.points.map((point,j)=>(
							<ClusterItem point={point} onClick={onItemClick} key={j}/>
						))}
					</div>
				</div>
			))}
		</div>
	);
};
export default ClustersList;