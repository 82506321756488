import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import HeaderLogo from "./components/HeaderLogo/HeaderLogo";
import HeaderUser from "./components/HeaderUser/HeaderUser";
import HeaderBackButton from "./components/BackButton/BackButton";
import HeaderLogoutButton from "./components/HeaderLogoutButton/HeaderLogoutButton";

import useLang from "@hooks/useLang";

import routes from "../../routes";


const Header = ({...props}) => {
	
	const {switchLang, lang} = useLang();
	
	const isMainMenuRoute = window.location.pathname !== routes.mainMenu;
	
	return (
		<AppBar position="static" {...props}>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<HeaderLogo/>
					<Stack direction={'row'}
					       justifyContent={isMainMenuRoute ? 'space-between' : 'flex-end'}
					       sx={{flexGrow: 1, mr: 4}}>
						<HeaderBackButton/>
						<HeaderLogoutButton/>
					</Stack>
					<Stack direction={'row'} alignItems={'center'} sx={{flexGrow: 0}}>
						<Button size={'small'} variant={'text'} color={'inherit'}
						        sx={{minWidth: 'auto', mr: 4}}
						        onClick={switchLang}>{lang}</Button>
						<HeaderUser/>
					</Stack>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default Header;