import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import favoriteGetAllRequest from "@api/favorite/getAll";
import {favoritesSetRedux} from "@redux/favorite/actions";


const useFavoriteList = () => {
	const dispatch = useDispatch();
	
	const {favorite} = useSelector((state) => state.favorite);
	
	const getFavoriteList = useCallback(async () => {
		const result = await favoriteGetAllRequest();
		dispatch(favoritesSetRedux(result))
	},[dispatch]);
	
	useEffect(() => {
		
		getFavoriteList().catch((error) => console.error(error))
		
	}, [getFavoriteList]);
	
	return {
		favoriteList: favorite
	};
};
export default useFavoriteList;