// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workMainDiv {
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.canvasContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/Viewer/index.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AAEF","sourcesContent":[".workMainDiv {\n  background-color: black;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n}\n.canvasContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
