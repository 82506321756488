import { set, get } from 'idb-keyval';

// import LoadersVolumes from "ami.js/src/loaders/loaders.volume"
import LoadersVolumes from "../classes/loaders.volume.arraybuffer"
import {helpersStack} from "../ami-legacy/helpers/helpers.stack";
import {stackStore} from "../../idbStore/init";
// import * as AMI from 'ami.js'
// import {StackHelper} from 'ami.js';
// import HelpersStack from "ami.js/src/helpers/helpers.stack";

const loadSynth = (bufferArrayFile, alias, cacheEnabled) => {

	return new Promise(async (resolve, reject) => {
		
		const r1 = {
			sliceOrientation: "axial",
			targetID: 1,
			stackHelper: null,
			domId: 'r1',
			axis:'z',
			domElement: null,
			renderer: null,
			color: 0x121212,
			// sliceColor: 0xFF1744,
			sliceColor: "red",
			camera: null,
			controls: null,
			scene: null,
			light: null,
			localizerHelper: null,
			localizerScene: null,
			opacity:1,
		};
		const r2 = {
			sliceOrientation: "sagittal",
			targetID: 2,
			stackHelper: null,
			domId: 'r2',
			axis:'x',
			domElement: null,
			renderer: null,
			color: 0x121212,
			// sliceColor: 0xFFEA00,
			sliceColor:"yellow",
			camera: null,
			controls: null,
			scene: null,
			light: null,
			localizerHelper: null,
			localizerScene: null,
			opacity:1,
		};
		const r3 = {
			sliceOrientation: "coronal",
			targetID: 3,
			stackHelper: null,
			domId: 'r3',
			axis:'y',
			domElement: null,
			renderer: null,
			color: 0x121212,
			// sliceColor: 0x76FF03,
			sliceColor: "green",
			camera: null,
			controls: null,
			scene: null,
			light: null,
			localizerHelper: null,
			localizerScene: null,
			opacity:1,
		};
		// let t2 = Array.from(Array(392),(_,x) => (x).toString().padStart(6, '0'));
		// let files = t2.map((v) =>'/6-3D Ax T1 MP-RAGE 0.8 mm/' + v + '.dcm');
		// let t2 = Array.from(Array(224),(_,x) => "IM"+(x).toString().padStart(6, '0'));
		// let files = t2.map((v) =>'/n32/SE000001/' + v);
		// console.log(t2);
		// console.log(files);
		
		let _loader = new LoadersVolumes();
		
		await _loader.load([bufferArrayFile]);
		
		let series = _loader.data[0].mergeSeries(_loader.data)[0];
		
		let stack = series.stack[0];
				stack.prepare();
				
		const stackCached = await get(alias, stackStore);
		if (cacheEnabled && stackCached !== undefined) {
			stack.textureType = stackCached.textureType;
			stack.rawData = stackCached.data;
			stack.packedPerPixel = stackCached.packedPerPixel;
			stack.bitsAllocated = stackCached.bitsAllocated;
			stack.packed = true;
			console.log('exist')
		} else {
			console.log('doesnt exist')
		}
		
		const Stack = new helpersStack(stack);
		const Stack1 = new helpersStack(stack);
		const Stack2 = new helpersStack(stack);
		
		await Stack._create();
		await Stack1._create();
		await Stack2._create();
		
		if (cacheEnabled && stackCached === undefined) {
			await set(alias, {
				textureType: stack.textureType,
				data: stack.rawData,
				bitsAllocated: stack.bitsAllocated,
				packedPerPixel: stack.packedPerPixel
			}, stackStore);
		}
		r1.stackHelper = Stack;
		r1.stackHelper.orientation = 0;
		
		r2.stackHelper = Stack1;
		r2.stackHelper.orientation = 1;
		
		r3.stackHelper = Stack2;
		r3.stackHelper.orientation = 2;
		
		_loader.free();
		_loader = null;
		
		setTimeout(()=>{
			resolve({ r1, r2, r3, filename:bufferArrayFile.url, alias:bufferArrayFile.url.split(".")[0] });
		},0)
		
	})
};

export default loadSynth;