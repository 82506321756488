import * as THREE from "three";


const RASToLPS = (RASToLPS, xyz) => {
	const v3 = new THREE.Vector3()
		.copy(xyz)
		.applyMatrix4(RASToLPS);
	
	return v3;
};
export default RASToLPS;