const fields = [
	{name: "first_name", label: "Имя", type: "text", required: true},
	{name: "last_name", label: "Фамилия", type: "text", required: true},
	{name: "gender", label: "Пол", type: "select", required: true, items:[{key:"м", value:"m"},{key:"ж", value:"f"}]},
	{name: "diagnosis", label: "Диагноз", type: "select", required: false,  items:[{key:"epilepsy", value:"epilepsy"},{key:"non-epilepsy", value:"non-epilepsy"}]},
	{name: "comorbidity", label: "Сопутствующий диагноз", type: "select", required: false,  items:[{key:"epilepsy", value:"epilepsy"},{key:"non-epilepsy", value:"non-epilepsy"}]},
];

const initialValues = {
	first_name: "",
	last_name: "",
	gender: "",
	diagnosis: "",
	comorbidity: '',
};

export {fields, initialValues};