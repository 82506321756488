import React, {} from 'react';

import ListWide from "@ui/ListWide";
import ProcessingItemHome from "../ItemHome/ProcessingItemHome";
import useProcessingListHome from "./useProcessingListHome";


const ProcessingListHome = () => {
	const {processings} = useProcessingListHome();
	return (
		<ListWide>
			{processings.map(((processing,i) => (
				<ProcessingItemHome processing={processing} key={i}/>
			)))}
		</ListWide>
	);
};
export default ProcessingListHome;