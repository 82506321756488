import {createAction} from 'redux-actions';

import {
	PATIENT_CREATE,
	PATIENT_UPDATE,
	PATIENT_DELETE,
	PATIENT_ARCHIVE_ADD,
	PATIENT_ARCHIVE_DELETE,
	PATIENT_LIST_CREATE,
	PATIENT_MEDICAL_RECORD_ADD,
	PATIENT_MEDICAL_RECORD_DELETE,
	PATIENT_MY_LIST_CREATE,
	PATIENT_DATA_SET,
	PATIENT_SYNTH_DATA_SET,
} from './constants';


const patientCreateAction = createAction(PATIENT_CREATE);
const patientUpdateAction = createAction(PATIENT_UPDATE);
const patientDeleteAction = createAction(PATIENT_DELETE);

const patientArchiveAddAction = createAction(PATIENT_ARCHIVE_ADD);
const patientArchiveDeleteAction = createAction(PATIENT_ARCHIVE_DELETE);

const patientMedicalRecordAddAction = createAction(PATIENT_MEDICAL_RECORD_ADD);
const patientMedicalRecordDeleteAction = createAction(PATIENT_MEDICAL_RECORD_DELETE);

const patientListCreateAction = createAction(PATIENT_LIST_CREATE);
const patientMyListCreateAction = createAction(PATIENT_MY_LIST_CREATE);

const patientDataSetAction = createAction(PATIENT_DATA_SET);
const patientSynthDataSetAction = createAction(PATIENT_SYNTH_DATA_SET);


export const patientCreateRedux = (payload) => async (dispatch) => dispatch(patientCreateAction(payload));
export const patientUpdateRedux = (payload) => async (dispatch) => dispatch(patientUpdateAction(payload));
export const patientDeleteRedux = (payload) => async (dispatch) => dispatch(patientDeleteAction(payload));

export const patientArchiveAddRedux = (payload) => async (dispatch) => dispatch(patientArchiveAddAction(payload));
export const patientArchiveDeleteRedux = (payload) => async (dispatch) => dispatch(patientArchiveDeleteAction(payload));

export const patientMedicalRecordAddRedux = (payload) => async (dispatch) => dispatch(patientMedicalRecordAddAction(payload));
export const patientMedicalRecordDeleteRedux = (payload) => async (dispatch) => dispatch(patientMedicalRecordDeleteAction(payload));

export const patientListCreateRedux = (payload) => async (dispatch) => dispatch(patientListCreateAction(payload));
export const patientMyListCreateRedux = (payload) => async (dispatch) => dispatch(patientMyListCreateAction(payload));

export const patientDataSetRedux = (payload) => async (dispatch) => dispatch(patientDataSetAction(payload));
export const patientSynthDataSetRedux = (payload) => async (dispatch) => dispatch(patientSynthDataSetAction(payload));
