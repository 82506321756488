import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {RAW_DATA_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const rawDataStartProcessRequest = async (dataId,values) => {
	
	const result = await axiosService.post(buildUrl(`/${RAW_DATA_ROOT}/${dataId}/process`), JSON.stringify({...values}));
	
	return buildResponse(result);
};
export default rawDataStartProcessRequest;