import React from 'react';
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";


const SplitButtonPopper = ({anchorRef, handleClose, selectedIndex, handleMenuItemClick, open, options}) => {
	return (
		<Popper sx={{zIndex: 1}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
			{({ TransitionProps, placement }) => (
				<Grow {...TransitionProps} style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
					<Paper>
						<ClickAwayListener onClickAway={handleClose}>
							<MenuList id="split-button-menu" autoFocusItem>
								{options.map((option, index) => (
									<MenuItem key={option} disabled={index === 2} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
										{option}
									</MenuItem>
								))}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
};
export default SplitButtonPopper;