import React, {useEffect, useState} from "react";

// import {buildUrl} from "@api/settings";

import {Leva, useControls} from "leva";
import {useDispatch, useSelector} from "react-redux";
import {
	pialLeftSetRedux,
	pialRightSetRedux,
	synthVisibleSetRedux,
	cameraNearSetRedux,
	showSlicesOn3DSetRedux,
	showSynthSetRedux, zoomSetRedux, origVisibleSetRedux,
} from "@redux/gltf/actions";


const predictionNames = [
	'norm-curv',
	'norm-sulc',
	'norm-thickness',
	'post-Blurring_Flair',
	'post-Blurring_T1',
	'post-Blurring_T2',
	'post-CR_Flair',
	'post-CR_T2',
	'post-Entropy',
	'post-MLP_prediction',
	'post-Variance',
	'post-XGBoost_prediction'
];

const mapNames = [
	'norm.curv',
	'norm.sulc',
	'norm.thickness',
	'post-Blurring_Flair.thickness',
	'post-Blurring_T1.thickness',
	'post-Blurring_T2.thickness',
	'post-CR_Flair.thickness',
	'post-CR_T2.thickness',
	'post-Entropy.thickness',
	'post-MLP_prediction.thickness',
	'post-Variance.thickness',
	'post-XGBoost_prediction.thickness'
];


const formatSynthMapName = (name)=>{
	return name.split('.')[0].replace('post-','').replace('norm-','').replace('_native','');
};

const LevaControls = () => {
	
	const dispatch = useDispatch();
	
	const {
		pialLeft, pialRight
	} = useSelector((state) => state.gltf);
	
	const {
		origChildren,
		synthChildren,
		synthVisible,
		origVisible,
		gltfReady,
		camera_near,
		showSlicesOn3D,
		showSynth,
	} = useSelector((state) => state.gltf);
	
	const [{}, set] = useControls("3D", () => ({
		_showSlices: {
			label: 'show slices',
			value: showSlicesOn3D,
			onChange: (v) => {
				dispatch(showSlicesOn3DSetRedux(v));
			},
		},
		_wireframeShow: {
			label: 'show wireframe',
			value: false,
			onChange: (v) => {
				if (pialLeft === null || pialRight === null){
					return
				}
				const _gLeft = pialLeft.scene.children[0].material;
				const _gRight = pialRight.scene.children[0].material;
				
				_gLeft.wireframe = v;
				_gRight.wireframe = v;
				
				dispatch(pialLeftSetRedux(pialLeft));
				dispatch(pialRightSetRedux(pialRight));
			},
		},
		_near: {
			label: 'camera near',
			value: camera_near,
			min: 10,
			max: 150,
			step: 10,
			onChange: (v) => {
				dispatch(cameraNearSetRedux(v))
			},
		},
		_opacity: {
			label: 'opacity',
			value: 1,
			min: 0.1,
			max: 1,
			step: 0.1,
			onChange: (v) => {
				if (pialLeft === null || pialRight === null){
					return
				}
				console.log(v)
				
				const _gLeft = pialLeft.scene.children[0].material;
				const _gRight = pialRight.scene.children[0].material;
				
				// _gLeft.transparent = true;
				// _gRight.transparent = true;
				
				_gLeft.opacity = v;
				_gRight.opacity = v;
				
				dispatch(pialLeftSetRedux(pialLeft));
				dispatch(pialRightSetRedux(pialRight));
			},
		},
	}), []);
	
	const [{}] = useControls("tomograms", () => ({
		
		_showSynth: {
			label: 'show synth',
			value: showSynth,
			onChange: (v) => {
				dispatch(showSynthSetRedux(v));
			},
		},
		_common: {
			label: 'maps',
			value: (synthChildren.length === 0) ? 0 : synthVisible,
			options: synthChildren[2].length === 0
				? {'choose prediction':0}
				: synthChildren[2].reduce((acc, name, i) => ({
					...acc,
					[formatSynthMapName(name)]: i
				}), {}),
			onChange: (v) => {
				
				if(synthChildren.length === 0){
					return;
				}
				if (v === synthVisible ){
					return;
				}
				
				synthChildren[1].forEach((group)=>{
					group.forEach((mesh, i)=>{
						mesh.visible = i === v;
					})
				});
				dispatch(synthVisibleSetRedux(v));
				if(pialLeft === null ){
					return
				}
				const _gLeft = pialLeft.scene.children[0].geometry;
				const _gRight = pialRight.scene.children[0].geometry;
				
				const _colorAttrL = _gLeft.getAttribute(`color_${v+1}`);
				const _colorAttrR = _gRight.getAttribute(`color_${v+1}`);
				
				_gLeft.setAttribute("color", _colorAttrL);
				_gRight.setAttribute("color", _colorAttrR);
				
				dispatch(pialLeftSetRedux(pialLeft));
				dispatch(pialRightSetRedux(pialRight));
				
			},
			disabled: synthChildren.length === 0
		},
		_orig: {
			label: 'origs',
			value: (origChildren[2].length === 0) ? 0 : origVisible,
			options: origChildren[2].length === 0
				? {'choose prediction':0}
				: origChildren[2].reduce((acc, name, i) => ({
					...acc,
					[name.split('.')[0]]: i
				}), {}),
			onChange: (v) => {
				dispatch(origVisibleSetRedux(v));
			},
			disabled: origChildren[2].length === 0
		},
	}), [pialLeft, synthVisible,origVisible, synthChildren, origChildren, gltfReady, showSynth]);
	
	return (
		<Leva flat /*collapsed*/ titleBar={{ title:'Controls', drag:true, filter:false }} hideCopyButton />
	);
};
export default LevaControls;
