import * as THREE from "three";
import makeIdentityLutTexture from "./makeIdentityLutTexture";


const setUniform = (tomogram,colormap) => {

    const {stackHelper:{slice:{mesh:{material}}}} = tomogram;

    material.vertexColors = true;
    material.side = THREE.DoubleSide;
    material.uniforms.uBorderWidth.value = 0;
    material.uniforms.uBorderDashLength.value = 0;

    material.uniforms.uLut.value = 1;
    material.uniforms.uTextureLUT.value = makeIdentityLutTexture(THREE.LinearFilter,colormap);
    
    tomogram.stackHelper.slice.mesh.material.transparent = true;
    tomogram.stackHelper.slice.mesh.material.uniforms.uOpacity.value = tomogram.opacity;
};
export default setUniform;