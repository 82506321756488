import {useSelector} from "react-redux";


const useTranslate = ({translates}) => {
	
	const {lang} = useSelector((state) => state.lang);
	
	const message = translates[lang];
	
	return {
		...message
	};
};

export default useTranslate;