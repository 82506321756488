import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import dateFormat from "dateformat";

import {processingsSetRedux} from "@redux/processing/actions";

import doctorGetProcessingsRequest from "@api/doctor/getProcessings";
import {buildUrl} from "@api/settings";
import {PROCESSING_STATES, PROCESSINGS_ROOT} from "@api/constants";

import routes from "../../../routes";
import {useNavigate} from "react-router-dom";


const dateMask = "mmmm dS, yyyy, h:MM";

const useProcessingItemHome = ({processing}) => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {profile} = useSelector((state) => state.profile);
    
    const { id, update_date, create_date, status, subject, synthetic_data} = processing;

    const dateRange = `запущен ${dateFormat(create_date, dateMask)} ${status !== 'progress' ? ` - завершен ${dateFormat(update_date, dateMask)}` :''}`;
    
    const patientLink = `${routes.patients}/${subject.id}`;
    
    const eyeClickHandler = () => navigate(`/patients/${subject.id}/inspection/${synthetic_data.id}`);
    
    const getProcessings = async () => {
        const result = await doctorGetProcessingsRequest(profile.id);

        dispatch(processingsSetRedux(result.data))
    };

    useEffect(()=>{
        
        if (status === PROCESSING_STATES.untouched || status === PROCESSING_STATES.progress){
            
            let eventSource = new EventSource(buildUrl(`/${PROCESSINGS_ROOT}/${id}/sse`));

            eventSource.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                await getProcessings();
                parsedData.status === PROCESSING_STATES.finished && eventSource.close();
            };
            return (()=>{
                eventSource.close();
            })
        }
    },[status]);

    return {
        dateRange,
        patientLink,
        eyeClickHandler,
    };
};

export default useProcessingItemHome;