import {useDispatch} from "react-redux";

import {fields, initialValues} from "./fields";
import {patientMedicalRecordAddRedux} from "@redux/patient/actions";
import medrecordCreateRequest from "@api/medrecord/create";


const useMedicalRecordCreateForm = ({patientId, setIsOpen}) => {
	
	const dispatch = useDispatch();
	const onSubmit = async (values, {setSubmitting, setStatus, setErrors}) => {
		
		const _values = { ...values, subject_id:patientId};
		
		const {isSuccess, data, message, errors} = await medrecordCreateRequest( _values);
		
		if (isSuccess) {
			dispatch(patientMedicalRecordAddRedux(data));
			setIsOpen(false);
		} else {
			setStatus(message);
			setTimeout(() => setStatus(""), 3000);
			errors && setErrors(errors.json);
		}
		
		setSubmitting(false);
	};
	return {
		onSubmit,
		fields,
		initialValues,
	};
};
export default useMedicalRecordCreateForm;