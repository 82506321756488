import {useSelector} from "react-redux";
import dateFormat from "dateformat";

import useTranslate from "@hooks/useTranslate";
import {patientDataTranslates, patientInfoTranslates} from "./translates";


const dateMask = "mmmm dS, yyyy";

const usePatientCard = ()=> {
	
	const {patient} = useSelector((state) => state.patient);
	
	const {createDate, diagnosis, comorbidity} = useTranslate({translates: patientInfoTranslates});
	const {medicalRecords} = useTranslate({translates: patientDataTranslates});
	
	const simpleList = [
		{key: createDate, value: dateFormat(patient.create_date, dateMask)},
		{key: diagnosis, value: patient.diagnosis},
		{key: comorbidity, value: patient.comorbidity},
	];
	
	const rowList = [
		/*{key: patient.data.filter((item) => item.origin === 'raw').length, value: rawData},
		{key: patient.data.filter((item) => item.origin === 'synth').length, value: synthData},*/
		{key: patient.medical_records.length, value: medicalRecords},
	];
	
	return {
		simpleList,
		rowList,
	};
};

export default usePatientCard;