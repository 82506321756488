import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PROFILE_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const profileGetRequest = async () => {
	
	const result = await axiosService.get(buildUrl(`/${PROFILE_ROOT}`));
	
	return buildResponse(result);
};
export default profileGetRequest;