import React from 'react';

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";


const EmptyBlock = ({text, ...rest}) => {
	return (
		<Box {...rest}>
			<Stack alignItems="center" justifyContent="center" sx={{height:80, border:"1px dashed grey"}}>{text}</Stack>
		</Box>
	);
};
export default EmptyBlock;