import React from "react";

import CanvasText from "../primitives/CanvasText";


const Caption = ({ point, rN, visibility}) => {
	return (
		<group visible={visibility}>
			<CanvasText point={point.xyz} rN={rN} text={point.text} fontSize={10}/>
		</group>
	)
};
export default Caption;