import update from 'immutability-helper';
import {
	UPDATE_LOADING_MESSAGE,
	TOGGLE_IS_LOADING,
} from "./constants";


const initialTomogramsState = {
	isLoading: false,
	loadingMessage:'...',
};

export const loader = (state = initialTomogramsState, action) => {
	switch (action.type) {
		case TOGGLE_IS_LOADING:
			return update(state,{isLoading:{$set:action.payload}});
		case UPDATE_LOADING_MESSAGE:
			return update(state,{loadingMessage:{$set:action.payload}});
		default:
			return state;
	}
};
