import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PROTOCOLS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const protocolCreateWithRoiRequest = async (values) => {
	
	const result = await axiosService.post(buildUrl(`/${PROTOCOLS_ROOT}/rois`), JSON.stringify({...values}));
	
	return buildResponse(result);
};
export default protocolCreateWithRoiRequest;