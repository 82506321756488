import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {extraObjectCreateRedux, firstLinePointModeToggleRedux} from "@redux/drawLayer/actions";
import {matrixTransformWithWorldCenter} from "@utils/index";


const useCreateLine = ({rN}) => {
	
	const dispatch = useDispatch();
	
	const {isFirstLinePoint} = useSelector((state) => state.drawLayer);
	const {real} = useSelector((state) => state.tomograms);
	
	const [startPoint, setStartPoint] = useState(null);
	
	const createLine = (point, createMode) => {
		
		const time = (new Date()).getTime();
		const stack = real[0][rN].stackHelper.stack;
		
		const {lps2IJK, _spacing, dimensionsIJK} = stack;
		
		if (!isFirstLinePoint) {
			dispatch(firstLinePointModeToggleRedux(true));
			setStartPoint(point)
			
		} else {
			dispatch(firstLinePointModeToggleRedux(false));
			
			const ijk = matrixTransformWithWorldCenter(_spacing, point, lps2IJK, stack.worldCenter());
			
			const tmData = {r1: dimensionsIJK.y - ijk.y, r2: dimensionsIJK.x - ijk.x, r3: dimensionsIJK.z - ijk.z};
			tmData[rN] = real[0][rN].stackHelper.index;
			
			dispatch(extraObjectCreateRedux({
				start: startPoint,
				end: point,
				id: time,
				time: new Date(time),
				tmData: tmData,
				type: createMode
			}));
		}
	};
	
	return {
		createLine,
	}
};
export default useCreateLine;