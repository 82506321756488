import React, {} from "react";
import { useSelector } from "react-redux";

import CircularProgress from '@mui/material/CircularProgress';

import "./Preloader.scss";


const Preloader = () => {
	
	const {loadingMessage} = useSelector((state) => state.loader);
	
	return (
		<div className={'preloaderOverlay'}>
			<div className={'preloader'}>
				<CircularProgress  color="inherit" />
				<div className={'preloader__title'}>Loading...</div>
				<div className={'preloader__text'}>{loadingMessage}</div>
			</div>
		</div>
	);
};
export default Preloader;