import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as THREE from "three";

import {INFLATED_OFFSET} from "../../../../constants";
import getColoredGeometry from "../../../../libs/secondaryFunctions/getColoredGeometry";
import {geometryAddRedux} from "@redux/geometry/actions";


const useSurfaceCanvas = () => {
	
	const dispatch = useDispatch();
	
	const geometryState = useSelector((state) => state.geometry);
	const {pial, activeGeometry,colormap} = useSelector((state) => state.geometry);
	const {lutCounter} = useSelector((state) => state.tomograms);
	const {rayCast} = useSelector((state) => state.rayCast);
	const {mapsList} = useSelector((state) => state.surfaceMaps);
	
	const activeSurface = geometryState[activeGeometry];
	const activeSurfacePos = activeGeometry === 'inflated'
		? {
			left: new THREE.Vector3(-INFLATED_OFFSET, 0, 0),
			right: new THREE.Vector3(INFLATED_OFFSET, 0, 0)
		}
		: new THREE.Vector3();
	
	useEffect(() => {
		
		if (mapsList.length > 0) {
			
			if (pial.left !== null && mapsList[0].left !== null) {
				let modGeometry = getColoredGeometry(pial.left, mapsList[0].left, colormap);
				dispatch(geometryAddRedux({data: modGeometry, type: 'pial', side: 'left'}))
			}
			if (pial.right !== null && mapsList[0].right !== null) {
				let modGeometry = getColoredGeometry(pial.right, mapsList[0].right,colormap);
				dispatch(geometryAddRedux({data: modGeometry, type: 'pial', side: 'right'}))
			}
			
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	useEffect(() => {
	}, [rayCast, lutCounter]);
	
	return {
		activeSurface,
		activeSurfacePos,
	}
};
export default useSurfaceCanvas;