import axiosService from "../../libs/services/axiosService";
import {buildUrl} from "../settings";
import {COMMENTS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const commentDeleteRequest = async (commentId) => {
	const result = await axiosService.delete(buildUrl(`/${COMMENTS_ROOT}/${commentId}`));
	
	return buildResponse(result);
};
export default commentDeleteRequest;