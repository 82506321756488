const translates = {
	en: {
		editTitle: 'Editing draft',
		cancelButtonTitle: 'Cancel',
		publishButtonTitle: 'Publish',
		saveButtonTitle: 'Save as draft',
	},
	ru: {
		editTitle: 'Редактируется черновик',
		cancelButtonTitle: 'Отменить',
		publishButtonTitle: 'Опубликовать',
		saveButtonTitle: 'Сохранить как черновик',
	}
};
export default translates;