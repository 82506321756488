// import * as THREE from "three";
import update from 'immutability-helper';
import {
	SYNTHETIC_CREATE,
	SYNTHETIC_UPDATE_INDEX,
	SYNTHETIC_TOGGLE,
	SYNTHETIC_UPDATE_ACTIVE_INDEX,
	SYNTHETIC_UPDATE_ALL_PERSPECTIVES_INDEX,
	SYNTHETIC_UPDATE_ALL_PERSPECTIVES_OPACITY,
	SYNTHETIC_CLEAR,
} from "./constants";


const initialSyntheticTomogramsState = {
	synthetic: [],
	activeSyntheticIndex:[],
	showSynthetic: true,
};

export const syntheticTomograms = (state = initialSyntheticTomogramsState, action) => {
	switch (action.type) {

		case SYNTHETIC_TOGGLE:
			return update(state,{ showSynthetic:{$set:action.payload} });
			
		case SYNTHETIC_UPDATE_ACTIVE_INDEX:
			const index = state.activeSyntheticIndex.findIndex((item)=>action.payload.name === item.name);

			return index !== -1
				? update(state,{ activeSyntheticIndex:{ [index]:{ value:{ $set:action.payload.value }} }})
				: update(state,{ activeSyntheticIndex:{ $push:[{name:action.payload.name, value:action.payload.value}] } });
			
		case SYNTHETIC_CLEAR:
			return update(state,{ synthetic:{$set: []} });
			
			
		case SYNTHETIC_CREATE:
			return update(state,{ synthetic:{$push: [action.payload]} });
			
		case SYNTHETIC_UPDATE_ALL_PERSPECTIVES_INDEX:
			return update(state,{
				synthetic:{
					[action.payload.index]: {
						...(action.payload.data.r1 && {
							r1: {
								stackHelper: { index:{ $set:action.payload.data.r1 } }
							}
						}),
						...(action.payload.data.r2 && {
							r2: {
								stackHelper: { index:{ $set:action.payload.data.r2 } }
							}
						}),
						...(action.payload.data.r3 && {
							r3: {
								stackHelper: { index:{ $set:action.payload.data.r3 } }
							}
						}),
					},
				}
			});
		case SYNTHETIC_UPDATE_ALL_PERSPECTIVES_OPACITY:
			return update(state,{
				synthetic:{
					[action.payload.index]: {
						r1: {
							opacity: {  $set:action.payload.opacity }
						},
						r2: {
							opacity: { $set:action.payload.opacity }
						},
						r3: {
							opacity: {  $set:action.payload.opacity }
						}
					},
				}
			});
		case SYNTHETIC_UPDATE_INDEX:
			return update(state,{
				synthetic:{
					[action.payload.index]: {
						[action.payload.rn]: {
							stackHelper: {
								index: {$set: action.payload.stackIndex}
							}
						}
					}
				}
			});

		default:
			return state;
	}
};
