import {parseFloat32EndianSwapped} from "./index";


const parseFloat32EndianSwappedArray = (data, offset, elements) => {
	const arr = [];

	let max = 0;
	let min = Infinity;

	for (let i = 0; i < elements; i++) {
		const val = parseFloat32EndianSwapped(data, offset + i * 4);
		arr[i] = val;
		max = Math.max(max, val);
		min = Math.min(min, val);
	}

	return [arr, max, min];
};
export default parseFloat32EndianSwappedArray;