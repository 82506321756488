import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PATIENTS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const patientGetAllRequest = async () => {

	const result = await axiosService.get(buildUrl(`/${PATIENTS_ROOT}`));
	
	return buildResponse(result);
};
export default patientGetAllRequest;