import React from 'react';

import Stack from "@mui/material/Stack";
import ListEmptyStateWrap from "@ui/ListEmptyStateWrap/ListEmptyStateWrap";

import MedicalRecordListItem from "../Item/MedicalRecordListItem";

import useMedicalRecordList from "./useMedicalRecordList";


const MedicalRecordList = ({...rest}) => {
	
	const {medicalRecords} = useMedicalRecordList();
	
	return (
		<ListEmptyStateWrap length={medicalRecords.length} {...rest}>
			<Stack direction="row" sx={{flexWrap: "wrap"}}>
				{medicalRecords.map((record, i) => (
					<MedicalRecordListItem record={record} key={i}/>
				))}
			</Stack>
		</ListEmptyStateWrap>
	);
};
export default MedicalRecordList;