import React, { useRef} from "react";
import {Line} from '@react-three/drei'

import CanvasText from "../primitives/CanvasText";
import CanvasSphere from "../primitives/CanvasSphere";

import {getVectorDistance} from "@utils/index";


const Ruler = ({ start, end, rN, visibility }) => {
	
	const ref = useRef();
	/*useLayoutEffect(() => {
		ref.current.geometry.setFromPoints([start, end].map((point) => new THREE.Vector3(...point)))
	}, [start, end]);*/
	
	const distance = getVectorDistance(start, end).toFixed(2);
	
	return (
		<group visible={visibility}>
			<CanvasSphere position={start} radius={1.5}/>
			{end && (
				<>
					<CanvasText point={start} rN={rN} fontSize={8} text={`${distance} units`}/>
					<Line ref={ref} lineWidth={2} points={[Object.values(start), Object.values(end)]} color={'purple'}/>
					<CanvasSphere position={end} radius={1.5}/>
				</>
			)}
		</group>
	)
};
export default Ruler;