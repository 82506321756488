import React from 'react';
import {Formik} from 'formik';

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import CustomInput from "@ui/form/CustomInput/CustomInput";

import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const CommentForm = ({handleSubmit, fields, initialValues, messages, ...rest}) => {
	
	const {submitButtonTitle, submitButtonProcessTitle} = useTranslate({translates});
	
	return (
		<Formik initialValues={initialValues} validate={undefined} onSubmit={handleSubmit}>
			{({values, errors, handleChange, handleSubmit, isSubmitting, status, setFieldValue}) => (
				<form onSubmit={handleSubmit} {...rest}>
					{!!status && <Alert severity="error">{status}</Alert>}
					<Stack direction={'row'} alignItems={'stretch'} justifyContent={'space-between'} spacing={2}>
						<CustomInput {...{field: {...fields[0], label: messages[fields[0].name]}, values, errors, handleChange, setFieldValue}} size={'small'} sx={{flex: "1 1 auto"}}/>
						<Button variant="contained" type={'submit'} size={'small'} disabled={isSubmitting}
						        sx={{flex: "0 0 auto"}}>{isSubmitting ? `${submitButtonProcessTitle}...` : submitButtonTitle}</Button>
					</Stack>
				</form>
			)}
		</Formik>
	);
};
export default CommentForm;
