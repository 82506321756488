import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {roiPointActiveSetRedux} from "@redux/ROIPoint/actions";
import {commentsSetRedux} from "@redux/comment/actions";

import roiGetCommentsRequest from "@api/roi/getComments";
import roiDeleteRequest from "@api/roi/delete";

import useRoiPoint from "@hooks/useRoiPoint";


const useRoiPointProtocolSingleItem = ({point}) => {
	
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {deletePoint} = useRoiPoint();
	
	const {roiPointActive} = useSelector((state) => state.ROIPoint);
	const {editableProtocolId} = useSelector((state) => state.protocol);
	
	const handlePointIconClick = async () => {
		navigate(`./../../inspection/${point.synthetic_data_id}?protocol=${editableProtocolId}&point=${point.id}`)
	};
	
	const handleItemClick = async () => {
		dispatch(roiPointActiveSetRedux(point.id));
		const {data} = await roiGetCommentsRequest(point.id);
		dispatch(commentsSetRedux(data))
	};
	
	const handleDeleteClick = async (e) => {
		e.stopPropagation();
		if (window.confirm('are you sure?')){
			const {isSuccess} = await roiDeleteRequest(point.id);
			isSuccess && deletePoint(point.id);
		}
	};
	
	const isActive = roiPointActive === point.id;
	
	return {
		handlePointIconClick,
		handleItemClick,
		handleDeleteClick,
		isActive,
	};
};
export default useRoiPointProtocolSingleItem;