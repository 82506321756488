import update from 'immutability-helper';
import {
	PATIENT_CREATE,
	PATIENT_UPDATE,
	PATIENT_DELETE,
	PATIENT_ARCHIVE_ADD,
	PATIENT_ARCHIVE_DELETE,
	PATIENT_LIST_CREATE,
	PATIENT_MEDICAL_RECORD_ADD,
	PATIENT_MEDICAL_RECORD_DELETE, PATIENT_MY_LIST_CREATE,
	PATIENT_DATA_SET,
	PATIENT_SYNTH_DATA_SET,
} from "./constants";


const initialPatientState = {
	patientList: null,
	patientMyList: [],
	patient: null,
	patientData: [],
	patientSynthData: [],
	medicalRecords: null,
};

export const patient = (state = initialPatientState, action) => {
	switch (action.type) {
		
		case PATIENT_DELETE:
			return update(state, { patient: {$set: null} });
			
		case PATIENT_CREATE:
			return update(state, { patient: {$set: action.payload}, medicalRecords: {$set: action.payload.medical_records} });
		
		case PATIENT_DATA_SET:
			return update(state, { patientData: {$set: action.payload} });
			
		case PATIENT_SYNTH_DATA_SET:
			return update(state, { patientSynthData: {$set: action.payload} });
			
		case PATIENT_UPDATE:
			return update(state, {patient: {$merge: action.payload}});
		
		case PATIENT_ARCHIVE_ADD:
			return update(state, {patientData: {$push: [action.payload]}});
		
		case PATIENT_ARCHIVE_DELETE:
			const indexToDelete = state.patientData.findIndex(data => data.id === action.payload.dataId);
			return update(state, {patientData: {$splice: [[indexToDelete, 1]]}});
		
		case PATIENT_MEDICAL_RECORD_ADD:
			return update(state, {medicalRecords: {$push: [action.payload]}});
		
		case PATIENT_MEDICAL_RECORD_DELETE:
			const index = state.medicalRecords.findIndex(data => data.id === action.payload.medicalRecordId);
			return update(state, {medicalRecords: {$splice: [[index, 1]]}});
		
		case PATIENT_LIST_CREATE:
			return update(state, {patientList: {$set: action.payload}});
			
		case PATIENT_MY_LIST_CREATE:
			return update(state, {patientMyList: {$set: action.payload}});
		
		default:
			return state;
	}
};
