import update from 'immutability-helper';
import {
	GEOMETRY_ADD,
	GEOMETRY_UPDATE_COLOR,
	ACTIVE_GEOMETRY_SET,
	COLORMAP_UPDATE,
	WIREFRAME_SWITCH,
	OPACITY_UPDATE,
	CLIPPING_SWITCH,
	CAMERA_NEAR_SET,
} from "./constants";


const initialGeometryState = {
	pial: { left: null, right: null, leftKdTree:null, rightKdTree:null },
	inflated: { left: null, right: null, leftKdTree:null, rightKdTree:null },
	activeGeometry: 'pial', // 'pial' || 'inflated'
	colormap:'cooltowarm',
	wireframeShow:false,
	clipping:false,
	opacity:1,
	cameraClipping:false,
	camera_near: 10
};

export const geometry = (state = initialGeometryState, action) => {
	switch (action.type) {
		
		case CAMERA_NEAR_SET:
			return update(state,{camera_near:{$set:action.payload}});
			
		case OPACITY_UPDATE:
			return update(state,{opacity:{$set:action.payload}});
			
		case CLIPPING_SWITCH:
			return update(state,{clipping:{$set:action.payload}});
			
		case ACTIVE_GEOMETRY_SET:
			return update(state,{activeGeometry:{$set:action.payload}});
			
		case GEOMETRY_ADD:
			if (action.payload.kdTree){
				return update(state,{[action.payload.type]:{[action.payload.side]:{$set:action.payload.data}, [`${action.payload.side}KdTree`]:{$set:action.payload.kdTree}}});
			} else {
				return update(state,{[action.payload.type]:{[action.payload.side]:{$set:action.payload.data}}});
			}

		case GEOMETRY_UPDATE_COLOR:
			return update(state,{[action.payload.type]:{left:{attributes:{color:{$set:action.payload.data.left}}},right:{attributes:{color:{$set:action.payload.data.right}}}}});
			
		case COLORMAP_UPDATE:
			return update(state,{colormap:{$set:action.payload}});
			
		case WIREFRAME_SWITCH:
			return update(state,{wireframeShow:{$set:action.payload}});
			
		default:
			return state;
	}
};
