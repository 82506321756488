import JSZip from "jszip";


const unzipToArrayBuffer = async (archiveBlob) => {
	const zip = new JSZip();
	const archive = await zip.loadAsync(archiveBlob);
	const archiveFiles = Object
		.values(archive.files)
		.filter(file=>!file.dir) // remove dirs
		// .filter(file=>!file.name.includes('/')); // remove files inside dirs
	
	const bufferArrayFiles = await Promise.all(archiveFiles.map(async (file,i)=> {
		const buffer = await archive.file(file.name).async("arraybuffer");
		return {
			buffer: buffer,
			url: file.name
		};
	}));
	return bufferArrayFiles;
};
export default unzipToArrayBuffer;