import React from "react";
import {Link} from "react-router-dom";

import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import routes from "../../../routes";


const PatientListItem = ({patient}) => {
	
	const {id, first_name, last_name, medical_records, raw_data, diagnosis} = patient;
	const fullname = `${first_name} ${last_name}`;
	
	return (
		<ListItem alignItems="flex-start" divider>
			<ListItemAvatar>
				<Avatar alt={fullname} src="/static/images/avatar/1.jpg"/>
			</ListItemAvatar>
			<ListItemText
				primary={
					<Typography color="text.primary">
						<Link to={`${routes.patients}/${id}/`} style={{color: "inherit"}}>{id} - {fullname}</Link>
					</Typography>
				}
				secondary={
					<>
						<Typography variant="body2" color="text.primary" component={'span'} sx={{display:"block"}}>diagnosis: <b>{diagnosis}</b></Typography>
						<Typography variant="body2" color="text.primary" component={'span'} sx={{display:"block"}}>medical_records: <b>{medical_records.length}</b></Typography>
						<Typography variant="body2" color="text.primary" component={'span'} sx={{display:"block"}}>data: <b>{raw_data.length}</b></Typography>
					</>
				}
			/>
		</ListItem>
	);
};
export default PatientListItem;