import React from 'react';

import ListEmptyStateWrap from "@ui/ListEmptyStateWrap/ListEmptyStateWrap";
import FavoriteListItem from "../ListItem/FavoriteListItem";


const FavoriteList = ({favoriteList}) => {
	return (
		<ListEmptyStateWrap length={favoriteList.length}>
			{favoriteList.map((favorite, i) => (
				<FavoriteListItem favorite={favorite} key={i}/>
			))}
		</ListEmptyStateWrap>
	);
};
export default FavoriteList;