import React, {} from 'react';

import DrawerLayout from "@ui/layout/DrawerLayout";
import PageTitle from "@ui/PageTitle";

import FavoriteList from "@components/Favorite/List/FavoriteList";
import useFavoriteList from "@hooks/useFavoriteList";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const FavoritePage = () => {
	const {pageTitle} = useTranslate({translates});
	return (
		<DrawerLayout>
			<PageTitle text={pageTitle} sx={{mb: 2}}/>
			<FavoriteList {...useFavoriteList()}/>
		</DrawerLayout>
	);
};
export default FavoritePage;