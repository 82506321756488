import React, {} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useControls, folder} from "leva";
import {
	syntheticUpdateActiveIndexRedux,
	syntheticUpdateAllPerspectivesOpacityRedux
} from "@redux/syntheticTomograms/actions";


function SyntheticTomogramsFolder() {

	const dispatch = useDispatch();

	const {synthetic} = useSelector((state) => state.syntheticTomograms);

	useControls('Synthetic tomograms', {
		...synthetic.reduce((acc, item, i) => {
			return {
				...acc, [item.alias]: folder({
					['_T2Visibility-' + i]: {
						label: 'visibility',
						value: false,
						onChange: (value) => {
							dispatch(syntheticUpdateActiveIndexRedux({name: item.alias, value}))
						},
					},
					['_T2Opacity-' + i]: {
						label: 'opacity',
						value: 1,
						min: 0.1,
						max: 1,
						step: 0.1,
						onChange: (v) => {
							dispatch(syntheticUpdateAllPerspectivesOpacityRedux({index: i, opacity: v}));
						},
					},
				})
			}
		}, {}),
	}, [
		synthetic
	]);

	return <></>
}

export default SyntheticTomogramsFolder;

