import React, {Suspense, useEffect} from 'react';
import {useSelector} from "react-redux";


const Slices3D = () => {
	
	const {scene3DChildren, activeIndex} = useSelector((state) => state.gltf);
	
	const updateDrawRange = (nodeDim)=>{
		const count = Math.round( 6 );
		const dim = nodeDim.userData.dim;
		nodeDim.geometry.setDrawRange(Math.round( activeIndex[dim]*6 ), count );
	};
	
	useEffect(() => {
		updateDrawRange(scene3DChildren.find((item)=>item.name==="MyNode_orig_x"));
		updateDrawRange(scene3DChildren.find((item)=>item.name==="MyNode_orig_y"));
		updateDrawRange(scene3DChildren.find((item)=>item.name==="MyNode_orig_z"));
	}, []);
	
	return (
		<>
			<primitive object={scene3DChildren.find((item)=>item.name==="MyNode_orig_x")} />
			<primitive object={scene3DChildren.find((item)=>item.name==="MyNode_orig_y")} />
			<primitive object={scene3DChildren.find((item)=>item.name==="MyNode_orig_z")} />
		</>
	);
};

export default Slices3D;