import {useDispatch, useSelector} from "react-redux";

import {langSetRedux} from "@redux/lang/actions";


function useLang() {
	
	const dispatch = useDispatch();
	
	const {lang} = useSelector((state) => state.lang);
	
	const switchLang = ()=>{
		const newLang = lang === 'ru' ? 'en' : 'ru';
		localStorage.setItem('lang', newLang);
		dispatch(langSetRedux(newLang))
	};
	
	return {
		switchLang,
		lang,
	}
}
export default useLang;