const fields = [
	{name: "username", label: "Имя пользователя", type: "text", required: true, autoComplete: "off"},
	{name: "password", label: "Пароль", type: "password", required: true, autoComplete: "off"},
];

const initialValues = {username: '', password:''};

const validate = values => {
	const error = {};

	// const users = UsersStore.getList();
	//
	// const filteredUsersList = users.filter(({name})=>name===values.name);
	//
	// if (values.name === '') {
	// 	error.name = 'required';
	// } else {
	// 	filteredUsersList.length === 0 && (error.name = 'doesnt exist');
	// }
	// if (values.password === '') {
	// 	error.password = 'required';
	// } else {
	// 	filteredUsersList.length > 0 && filteredUsersList[0].password !== values.password && (error.password = 'wrong password');
	// }

	return error;
};

export {fields, initialValues, validate};