import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {patientListCreateRedux} from "@redux/patient/actions";
import patientGetAllRequest from "@api/patient/getAll";


function usePatients() {
	
	const dispatch = useDispatch();
	
	const [inProgress, setInProgress] = useState(false);
	const {patientList} = useSelector((state) => state.patient);
	
	const getPatients = async () => {
		setInProgress(true);
		const {isSuccess, data} = await patientGetAllRequest();
		
		if (isSuccess){
			dispatch(patientListCreateRedux(data))
		}
		
		setInProgress(false);
	};
	useEffect(() => {
	
		getPatients()
			.catch(e => {
				console.error(e);
				e.status === 401 && getPatients();
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return {
		inProgress,
		patientList,
	}
}
export default usePatients;