import React from "react";
import {useSelector} from "react-redux";
import * as THREE from "three";


const Localizer = ({rN}) => {
	
	const {real} = useSelector((state) => state.tomograms);
	
	const halfDimensionsIJK = (new THREE.Vector3()).copy({...real[0][rN].stackHelper.stack.halfDimensionsIJK});
	const position = halfDimensionsIJK.negate();
	const geometry = real[0][rN].stackHelper.slice.mesh.geometry;
	const material = new THREE.MeshBasicMaterial({wireframe: true, color: real[0][rN].sliceColor});
	
	return (
		<mesh position={position} geometry={geometry} material={material}/>
	);
};
export default Localizer;