import React, { useEffect } from "react";
import { useSelector} from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import GLTFCanvas from "../GLTFCanvas/GLTFCanvas";
import useGLTFCanvasGrid from "./useGLTFCanvasGrid";
import CanvasVolume from "../CanvasVolume/CanvasVolume";

import dims from "./dims";
import Ruler from "./components/Ruler";
import VRuler from "./components/VRuler";


const GLTFCanvasGrid = ({_archive}) => {
	
	const {loadGltf} = useGLTFCanvasGrid();
	
	const { sceneChildren, scene3DChildren, origVisible, synthChildren,origChildren, synthVisible, gltfReady, zoom } = useSelector((state) => state.gltf);
	
	useEffect(() => {
		loadGltf(_archive)
			.catch(error=>console.log(error))
	}, []);
	
	return (
		<>
			{!gltfReady
				? <Stack alignItems={'center'} justifyContent={'center'} sx={{height: "100vh", width:'100%'}}>gltf preparing...</Stack>
				: (
					<Box sx={{
						height:"100vh",
						display:'grid',
						gridTemplate:"repeat(2, 1fr) / repeat(2, 1fr)",
						gridGap: "1px",
						backgroundColor: "white",
					}}>
						
						{ scene3DChildren.length !==0
							? <CanvasVolume archive={_archive}/>
							: <Box/>
						}
						
						{dims.map((dim, i)=>
							<Box sx={{position:"relative"}} key={i}>
								<GLTFCanvas
									node={origChildren[1][origChildren[0].indexOf("MyNode_orig_"+dim.name)][origVisible]}
			            synth={synthChildren[1][synthChildren[0].indexOf("MyNode_synth_"+dim.name)][synthVisible]}
			            dim={dim}
			            key={i}/>
		            
		            <Ruler zoom={zoom[dim.name]}/>
								<VRuler  zoom={zoom[dim.name]}/>
								<div style={{position:"absolute", bottom:10, right:10}}>
									{dim.name} ({dim.label})
								</div>
								
							</Box>
						)}
					
					</Box>
				)
			}
			
		</>
	);
};
export default GLTFCanvasGrid;
