const translates = {
	en: {
		favoriteAdd:'Add to favorite',
		favoriteRemove:'Remove from favorite',
	},
	ru: {
		favoriteAdd:'Добавить в избранное',
		favoriteRemove:'Убрать из избранного',
	}
};

export default translates;