import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {COMMENTS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const commentGetAllRequest = async () => {
	
	const result = await axiosService.get(buildUrl(`/${COMMENTS_ROOT}`));
	
	return buildResponse(result);
};
export default commentGetAllRequest;