import React, {} from "react";
import {Provider, useSelector} from "react-redux";
import {Canvas} from "@react-three/fiber";
import {OrbitControls} from "@react-three/drei";

import {Store} from "@redux/store";

import MeshLayer from "./components/MeshLayer";
import LightingLayer from "./components/LightingLayer";
import HelpersLayer from "./components/HelpersLayer";


const SurfaceCanvas = ({...props}) => {
	
	const { camera_near} = useSelector((state) => state.geometry);
	
	return (
		<Canvas
			flat
			// gl={{ useLegacyLights:true }}
			frameloop="demand" camera={{position: [0, 240, 0], up: [0, 0, 1], near:camera_near}} {...props}>
			<Provider store={Store}>
				
				<MeshLayer/>
				
				<HelpersLayer/>
				
				<LightingLayer/>
				<OrbitControls/>
			
			</Provider>
		</Canvas>
	);
};
export default SurfaceCanvas;