import React from 'react';
import SurfaceMesh from "./SurfaceMesh";
import useSurfaceCanvas from "../hooks/useSurfaceCanvas";

const MeshLayer = () => {
	const {activeSurface, activeSurfacePos} = useSurfaceCanvas();

	return (
		<>
			{/* LEFT */}
			{activeSurface.left && <SurfaceMesh position={activeSurfacePos.left} geometry={activeSurface.left}/>}
			
			{/* RIGHT */}
			{activeSurface.right && <SurfaceMesh position={activeSurfacePos.right} geometry={activeSurface.right}/>}
			</>
	);
};

export default MeshLayer;