import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

// import {buildUrl} from "@api/settings";

import GLTFCanvasGrid from "./components/GLTFCanvasGrid/GLTFCanvasGrid";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import CircularProgressWithLabel from "@ui/CircularProgressWithLabel";
import InspectionLayout from "@ui/layout/InspectionLayout";

import useGLTFViewerPage from "./useGLTFViewerPage";
import useTranslate from "@hooks/useTranslate";

import LevaControls from "./components/LevaControls/LevaControls";

import InspectionProtocolStateRow from "../PatientSingle/Inspection/components/ProtocolStateRow/ProtocolStateRow";
import InspectionProtocolList from "../PatientSingle/Inspection/components/ProtocolList/ProtocolList";
import CommentsBlock from "../PatientSingle/Protocols/SingleProtocol/components/CommentsBlock/CommentsBlock";
import translates from "../PatientSingle/Inspection/translates";
import ROIPointGltfList from "@ROIPoint/GltfList/ROIPointGltfList";
import GltfRoiPointListHead from "./components/CanvasVolume/components/ROIPointListHead/ROIPointListHead";
import {buildUrl} from "@api/settings";
import Button from "@mui/material/Button";
import ClustersList from "./components/ClustersList/ClustersList";


const GLTFViewerPage = () => {
	const navigate = useNavigate();
	
	const {protocolsTitle, backButtonTitle} = useTranslate({translates});
	
	const {roiPointActive} = useSelector((state) => state.ROIPoint);
	const {getArchive,archive, percent, message, isLoading} = useGLTFViewerPage();
	
	const { gltfReady, pointInfo } = useSelector((state) => state.gltf);
	
	const handleBackButtonClick = () => navigate(-1);
	const handleBackButton2Click = () => navigate("./../");
	
	useEffect(() => {
		
		// const zipUrl = buildUrl('/uploads/raw_data/stack-single-full.zip');
		// const zipUrl = '/__data/stack-single-full.zip';
		// const zipUrl = '/__data/stack-single-half.zip';
		// const zipUrl = '/__data/30-single-full.zip';
		const zipUrl = buildUrl("/synthetic_data/a46e37632fa6ca51a13fe39a567b3c23b28c2f47d8af6be9bd63e030e214ba38/30_all_gltf.zip");
		// const zipUrl = '/__data/30_all_gltf.zip';
		
		getArchive(zipUrl)
			.catch(error=>console.log(error))
		
	}, []);

	return (
		<>
			{isLoading || archive===null ? (
				<Stack alignItems={'center'} justifyContent={'center'} sx={{position: "relative", height: '100vh'}}>
					<Stack alignItems={'center'}>
						<CircularProgressWithLabel value={percent*100} />
						{isLoading && <div style={{marginTop:"10px"}}>{message}</div>}
					</Stack>
				</Stack>
			):(
				<InspectionLayout>{{
					main: (
						<>
							<Box sx={{mt: 2, ml:2, position:'absolute', zIndex:10}}>
								<Button variant='contained' size='small' onClick={handleBackButtonClick} sx={{}}>{backButtonTitle}</Button>
								<Button variant='contained' size='small' onClick={handleBackButton2Click} sx={{ml:2}}>old visualizer</Button>
							</Box>
							<GLTFCanvasGrid _archive={archive}/>
							{gltfReady && <LevaControls/>}
						</>
					),
					aside: (
						<Box sx={{p: 2, boxSizing: "border-box"}}>
							{gltfReady && (
								<>
									{pointInfo !== null && (
										<>
											<Typography component='h3' variant='h6'>Информация о точке</Typography>
											{ Object.entries(pointInfo).map( ([key,value])=>
												<div key={key}>{key} : {value}</div>
											)}
											<Divider sx={{my: 2}}/>
										</>
									)}
									<ClustersList/>
									<Stack sx={{height: 300, width:'100%'}}>
										{/*<InspectionRoiPointListHead isArchiveLoading={false}/>*/}
										<GltfRoiPointListHead isArchiveLoading={false}/>
										<ROIPointGltfList sx={{width: '100%', bgcolor: 'background.paper', mb: 1}}/>
									</Stack>
									<InspectionProtocolStateRow/>
									<Divider sx={{my: 2}}/>
									<Typography component='h3' variant='h6'>{protocolsTitle}</Typography>
									
									<InspectionProtocolList/>
									<Divider sx={{my: 2}}/>
									{roiPointActive !==null && (<CommentsBlock/>)}
								</>
							)}
						</Box>
					)
				}}
				</InspectionLayout>
			)}
		</>
	);
};
export default GLTFViewerPage;
