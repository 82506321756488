export default function parseAsDataURL(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = (e) => {
			resolve(e.target.result);
		};
		reader.onerror = (e) => {
			reject(e);
		};
		reader.readAsDataURL(file);
	});
};
