import React, {useEffect} from "react";

import axiosService from "../../libs/services/axiosService";
import JSZip from "jszip";


const useGLTFViewerPage = () => {
	
	const [archive, setArchive] = React.useState(null);
	const [percent, setPercent] = React.useState(0);
	const [message, setMessage] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	
	const getArchive = async (url)=>{
		
		setIsLoading(true);
		setMessage("archive loading");
		
		const response = await axiosService.get(url, {
			responseType:'arraybuffer',
			onDownloadProgress: (progressEvent) => {
				// console.log(progressEvent.progress);
				setPercent(progressEvent.progress);
			}
		});
		
		setMessage("archive unpacking");
		
		const zip = new JSZip();
		const archive = await zip.loadAsync(response.data);
		
		setMessage("");
		setArchive(archive);
		setIsLoading(false);
		
		return archive;
	};
	
	return {
		getArchive,
		archive,
		percent,
		message,
		isLoading,
	};
};
export default useGLTFViewerPage;