import React from 'react';
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const useMyPatientListPage = () => {
	
	const trans = useTranslate({translates});
	
	const [modalIsOpen, setModalIsOpen] = React.useState(false);
	
	const handleSpeedDialClick = () => setModalIsOpen(true);
	
	return {
		modalIsOpen,
		setModalIsOpen,
		handleSpeedDialClick,
		trans,
	};
};

export default useMyPatientListPage;