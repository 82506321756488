const fields = [
	{name: "first_name", label: "Имя", type: "text", required: true},
	{name: "last_name", label: "Фамилия", type: "text", required: true},
	{name: "gender", label: "Пол", type: "select", required: true, items:[{key:"м", value:"m"},{key:"ж", value:"f"}]},
	{name: "diagnosis", label: "Диагноз", type: "select", required: false,  items:[{key:"epilepsy", value:"epilepsy"},{key:"non-epilepsy", value:"non-epilepsy"}]},
	{name: "comorbidity", label: "Сопутствующий диагноз", type: "select", required: false,  items:[{key:"epilepsy", value:"epilepsy"},{key:"non-epilepsy", value:"non-epilepsy"}]},
	{name: "medrecord_data", label: "Медицинские документы", type: "file", required: false},
	{name: "data", label: "Инструментальные исследования", type: "file", required: false},
	{name: "modality", label: "Модальность", type: "select", required: true,  items:[{key:"T1", value:"T1"}]},
];
const groups = [
	{name: "personal_data", label: "Персональные данные", fields: ["first_name", "last_name", "gender"]},
	{
		name: "medical_data",
		label: "Медицинские данные",
		fields: ["diagnosis", "comorbidity"]
	},
];

const initialValues = {
	first_name: "",
	last_name: "",
	gender: "",
	diagnosis: "",
	comorbidity: '',
};

export {fields, initialValues, groups};