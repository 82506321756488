import React, {useEffect} from "react";
import * as THREE from "three";
import {useThree} from "@react-three/fiber";
import {useSelector} from "react-redux";


const Pointer = ({pointPos, dim}) => {
	
	const three = useThree();
	const geometry = new THREE.SphereGeometry( 1, 8, 8 );
	const material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
	const { zoom } = useSelector((state) => state.gltf);
	
	useEffect(() => {
		if (dim){
			three.camera.zoom = zoom[dim.name];
			three.camera.updateProjectionMatrix();
		}
		
	}, [zoom]);
	
	return (
		<mesh geometry={geometry} material={material} position={pointPos}/>
	);
};
export default Pointer;
