import update from 'immutability-helper';
import {
	ROI_POINT_ACTIVE_SET,
	ROI_POINT_CLEAR_ALL,
	ROI_POINT_CREATE, ROI_POINT_CREATE_MANY,
	ROI_POINT_DELETE,
	ROI_POINT_EDIT, ROI_POINT_EDIT_MANY,
} from "./constants";

const initialState = {
	roiPointList: [],
	roiPointActive:null,
};

export const ROIPoint = (state = initialState, action) => {
	switch (action.type) {
		
		case ROI_POINT_CREATE_MANY:
			return update(state, {roiPointList: {$set: action.payload}});
		
		case ROI_POINT_CREATE:
			return update(state, {roiPointList: {$push: [action.payload]}});
		
		case ROI_POINT_DELETE:
			const indexToDelete = state.roiPointList.findIndex(roiPoint => roiPoint.id === action.payload.pointId);
			return update(state, {roiPointList: {$splice: [[indexToDelete, 1]]}});
		
		case ROI_POINT_EDIT:
			const indexToChange = state.roiPointList.findIndex(point => point.id === action.payload.pointId);
			return update(state, {roiPointList: {[indexToChange]: {$merge: action.payload.data}}});
		
		case ROI_POINT_EDIT_MANY:
			const updated = state.roiPointList.map((roiPoint, i) => (
				action.payload.points.includes(roiPoint.id) ? {
					...roiPoint,
					[action.payload.field]: action.payload.value
				} : {...roiPoint})
			);
			return update(state, {roiPointList: {$set: updated}});
		
		case ROI_POINT_CLEAR_ALL:
			return update(state, {roiPointList: {$set: []}});
			
		case ROI_POINT_ACTIVE_SET:
			return update(state, {roiPointActive: {$set: action.payload}});
		default:
			return state;
	}
};
