import React, {} from 'react';

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	boxShadow: 24,
	p: 4,
	bgcolor: 'background.default',
	color: 'text.primary',
};

const UIModal = ({isOpen, setIsOpen, styles={}, children}) => {
	
	const handleClose = () => setIsOpen(false);
	
	return (
		<Modal open={isOpen} onClose={handleClose}>
			<Box sx={{...style, ...styles}}>
				{children}
			</Box>
		</Modal>
	);
};
export default UIModal;