import {useSelector} from "react-redux";
import {Float32BufferAttribute} from "three";
import {Lut} from "three/examples/jsm/math/Lut";


const useChangeLut = () => {
	
	const {lutCounter} = useSelector((state) => state.tomograms);
	
	const geometryState = useSelector((state) => state.geometry);
	const {colormap} = useSelector((state) => state.geometry);
	const {mapsList} = useSelector((state) => state.surfaceMaps);
	
	
	const changeLut = async (activeSurfaceMap, activeGeometry)=>{
		console.log('-----changeLut-----');
		
		let _colorsParsLeft = [];
		let _colorsParsRight = [];
		
		if (geometryState[activeGeometry].left && mapsList[activeSurfaceMap].left) {
			
			let colors = [];
			let lut = (new Lut(colormap, mapsList[activeSurfaceMap]["left"].length)).setMax(lutCounter);
			
			mapsList[activeSurfaceMap]["left"].forEach((color) => {
				colors.push(lut.getColor(color));
			});
			
			colors.forEach((color) => {
				_colorsParsLeft.push(color.r, color.g, color.b);
			});
		}
		
		if (geometryState[activeGeometry].right && mapsList[activeSurfaceMap].right) {
			let colors = [];
			
			let lut = (new Lut(colormap, mapsList[activeSurfaceMap]["right"].length)).setMax(lutCounter);
			
			mapsList[activeSurfaceMap]["right"].forEach((color) => {
				colors.push(lut.getColor(color));
			});
			
			colors.forEach((color) => {
				_colorsParsRight.push(color.r, color.g, color.b);
			});
		}
		
		return  {
			left: new Float32BufferAttribute(_colorsParsLeft, 3),
			right: new Float32BufferAttribute(_colorsParsRight, 3)
		}
	};
	
	return {
		changeLut,
	};
};
export default useChangeLut;