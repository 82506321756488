import {useDispatch, useSelector} from "react-redux";

import {geometryAddRedux} from "@redux/geometry/actions";
import {surfaceMapAddRedux} from "@redux/surfaceMaps/actions";
import {realCreateRedux} from "@redux/tomograms/actions";
import {stackCacheAddRedux} from "@redux/stackCache/actions";
import {labelMeshCreateRedux} from "@redux/labelTomogram/actions";
import {syntheticCreateRedux} from "@redux/syntheticTomograms/actions";

import loadSynth from "../libs/secondaryFunctions/loadSynth";
import Kdtree from "../libs/classes/Kdtree";
import {FreeSurferLoader} from "../libs/secondaryFunctions/free.surfer";

import {getDistance,parseUInt32EndianSwapped, parseFloat32EndianSwappedArray} from "@utils/index";


const useReduxLoaders = () => {
	
	const dispatch = useDispatch();
	const {stackCache} = useSelector((state) => state.stackCache);
	const {cacheEnabled} = useSelector((state) => state.cache);
	
	const tomogramLoader = async (file, dataID) => {
		
		const alias = file.url.split(".")[0];
		const cacheId = alias+'.'+ dataID;
		
		let __obj;
		if (cacheEnabled && stackCache[cacheId] !== undefined ){
			__obj = stackCache[cacheId];
		} else {
			__obj = await loadSynth(file, cacheId, cacheEnabled);
			await dispatch(stackCacheAddRedux({data:__obj, cacheId:cacheId}))
		}
		
		if (/label/.test(__obj.filename)) {
			await dispatch(labelMeshCreateRedux(__obj))
		} else if (/brain/.test(__obj.filename)) {
			await dispatch(realCreateRedux(__obj))
		} else {
			await dispatch(syntheticCreateRedux(__obj))
		}
	};
	
	const surfaceLoader = async (file) => {
		
		const lowercaseFileUrl = file.url.toLowerCase();
		
		const side = (/lh/.test(lowercaseFileUrl) && "left") || (/rh/.test(lowercaseFileUrl) && "right") || "";
		const type = (/.pial/.test(lowercaseFileUrl) && "pial") || (/.inflated/.test(lowercaseFileUrl) && "inflated") || "";
		
		const geom = await (new FreeSurferLoader()).parse(file.buffer);
		const tree = new Kdtree(geom.attributes.position.array.slice(0), getDistance, 3);
		
		await dispatch(geometryAddRedux({data: geom, type: type, side: side, kdTree: tree}));
	};
	
	const surfaceMapLoader = async (file) => {
		
		const lowercaseFileUrl = file.url.toLowerCase();
		
		const side = (/lh/.test(lowercaseFileUrl) && "left") || (/rh/.test(lowercaseFileUrl) && "right") || "";
		const type = (/.sulc/.test(lowercaseFileUrl) && "sulc") || (/.curv/.test(lowercaseFileUrl) && "curv") || (/xgboost/.test(lowercaseFileUrl) && 'xgboost') || (/mlp/.test(lowercaseFileUrl) && 'mlp') || (/variance/.test(lowercaseFileUrl) && 'variance') || (/cr/.test(lowercaseFileUrl) && 'cr') || (/entropy/.test(lowercaseFileUrl) && 'entropy') || (/norm/.test(lowercaseFileUrl) && 'norm') || (/blurring/.test(lowercaseFileUrl) && 'blurring') || 'thickness';
		
		const decoder = new TextDecoder("x-user-defined");
		const data = decoder.decode(new Uint8Array(file.buffer));
		
		const nvertices = parseUInt32EndianSwapped(data, 3); // 134121
		const al_ret = parseFloat32EndianSwappedArray(data, 15, nvertices); // Array(134121)
		
		await dispatch(surfaceMapAddRedux({type: type, side: side, data: al_ret[0]}));
	};
	
	return {
		tomogramLoader,
		surfaceLoader,
		surfaceMapLoader
	};
};
export default useReduxLoaders;