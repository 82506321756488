import React, {} from "react";
import {useDispatch, useSelector} from "react-redux";

import {extraObjectDeleteRedux} from "@redux/drawLayer/actions";

import DrawLogRow from "./components/DrawLogRow";

import "./DrawLog.scss";


const DrawLog = () => {
	
	const dispatch = useDispatch();
	
	const { extraPoints } = useSelector((state) => state.drawLayer);
	
	const onRemoveClick = (pointId) => {
		
		const index = extraPoints.findIndex(point => point.id === pointId);
		if (index !== -1) {
			dispatch(extraObjectDeleteRedux(index))
		}
	};
	
	return (
		<div className={'DrawLog'}>
			{ extraPoints
				.sort( (a, b) => (a.id > b.id ? 1 : -1) )
				.reverse()
				.map((object, i) =>
					<DrawLogRow key={i} object={object} onRemoveClick={onRemoveClick} />
				)
			}
		</div>
	);
};
export default DrawLog;