import rawDataGetProcessingRequest from "@api/raw-data/getProcessings";


function useRawData() {
	
	const getRawDataProcessings = async dataId => await rawDataGetProcessingRequest(dataId);
	
	return {
		getRawDataProcessings
	}
}
export default useRawData;