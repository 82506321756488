import React from 'react';
import {Link} from "react-router-dom";
import {useParams} from "react-router";

import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import useInspectionProtocolItem from "./useInspectionProtocolItem";

import routes from "../../../../../routes";


const InspectionProtocolItem = ({protocol}) => {
	
	const {patientId} = useParams();
	
	const {id, state} = protocol;
	const { onSetEditableClick, isActive } = useInspectionProtocolItem({protocol});
	
	return (
		<ListItem divider selected={isActive}
				secondaryAction={
					<IconButton edge="end" aria-label="edit" disabled={isActive} onClick={onSetEditableClick}>
						<EditIcon />
					</IconButton>
				}
			>
			<ListItemText primary={<Link to={`/patients/${patientId}${routes.drafts}/${id}`}>id={id} ({state})</Link>}/>
		</ListItem>
	);
};
export default InspectionProtocolItem;