import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import Button from "@mui/material/Button";

import patientGetProtocolsRequest from "@api/patient/getProtocols";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";
import routes from "../../../../routes";


const ProtocolsButton = () => {
	
	const {patientId} = useParams();
	const navigate = useNavigate();
	
	const {buttonTitle} = useTranslate({translates});
	
	const [protocolsCount,setProtocolsCount] = useState(null);
	
	const draftsClick = () => navigate(`${routes.patients}/${patientId}/drafts`);
	
	const getPatientProtocols = useCallback(async () => {
		const {isSuccess,data} = await patientGetProtocolsRequest(patientId);
		isSuccess && setProtocolsCount(data.length);
	},[patientId]);
	
	useEffect(() => {
		getPatientProtocols()
			.catch((error) => console.log(error))
	}, [getPatientProtocols]);
	
	return (
		<Button variant="contained" onClick={draftsClick} disabled={protocolsCount<= 0}>{buttonTitle}</Button>
	);
};
export default ProtocolsButton;