const translates = {
	en: {
		buttonTitle: 'load one more archive',
		blockTitle: 'Raw data',
	},
	ru: {
		buttonTitle: 'загрузить еще архив',
		blockTitle: 'Сырые данные',
	}
};
export default translates;