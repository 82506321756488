import React from "react";
import {useSelector} from "react-redux";

import CanvasSphere from "../../canvas/primitives/CanvasSphere";


const ROIPointCanvasList = ({rN}) => {
	
	const {real, activeRealIndex} = useSelector((state) => state.tomograms);
	const {roiPointList} = useSelector((state) => state.ROIPoint);
	// const {editableProtocolId} = useSelector((state) => state.protocol);
	
	const _getTmData = (point)=>JSON.parse(point.note.split(';').at(-1));
	
	return (
		<>
			{roiPointList.map((point, i) =>
				<group visible={real[activeRealIndex][rN].stackHelper.index === _getTmData(point)[rN]} key={i}>
				{/*<group visible={real[activeRealIndex][rN].stackHelper.index === point.tmData[rN]} key={i}>*/}
				{/*<group visible={true} key={i}>*/}
					<CanvasSphere position={{x:point.coord_x,y:point.coord_y,z:point.coord_z}} radius={4}/>
				</group>
			)}
		</>
	);
};
export default ROIPointCanvasList;