const translates = {
	en:{
		formTitle:'Please login',
		submitButtonTitle:'Enter',
		submitButtonProcessTitle:'loading',
		// or:'or',
		register:'Registration',
		fields:{
			username:'Name',
			password:'Password',
		}
	},
	ru:{
		formTitle:'Авторизация',
		submitButtonTitle:'Войти',
		submitButtonProcessTitle:'загрузка',
		// or:'или',
		register:'Регистрация',
		fields:{
			username:'Имя',
			password:'Пароль',
		}
	}
};
export default translates;