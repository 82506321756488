import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PROFILE_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const profileGetPatientsRequest = async () => {

	const result = await axiosService.get(buildUrl(`/${PROFILE_ROOT}/subjects`));
	
	return buildResponse(result);
};
export default profileGetPatientsRequest;