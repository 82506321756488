import React from 'react';
import {Formik} from 'formik';

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomInput from "@ui/form/CustomInput/CustomInput";

import usePatientEditForm from "./usePatientEditForm";


const PatientEditForm = ({initial, patientId, setOpen}) => {
	
	const {onSubmit, fields, initialValues} = usePatientEditForm({patientId,setOpen});
	
	return (
		<Formik initialValues={initial || initialValues} validate={undefined} onSubmit={onSubmit}>
			{({values, errors, handleChange, handleSubmit, isSubmitting, status = null, setFieldValue}) => (
				<form onSubmit={handleSubmit} style={{maxWidth: "800px"}}>
					<Stack spacing={1.5}>
						{fields.map((field, i) => (
							<CustomInput {...{field, values, errors, handleChange, setFieldValue}} key={i}/>
						))}
					</Stack>
					<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{mt: 2}}>
						<Button variant="contained" type={'submit'}
						        disabled={isSubmitting}>{isSubmitting ? "Сохранение..." : "Сохранить"}</Button>
						{status !== null && <Typography color={status.success ? "green" : "red"}>{status.text}</Typography>}
					</Stack>
				</form>
			)}
		</Formik>
	);
};
export default PatientEditForm;
