import React from 'react';

import Stack from "@mui/material/Stack";

import useInspectionProtocolList from "./useInspectionProtocolList";
import InspectionProtocolItem from "../InspectionProtocolItem/InspectionProtocolItem";


const InspectionProtocolList = () => {
	
	const {protocolList} = useInspectionProtocolList();
	
	return (
		<Stack spacing={1} sx={{mt: 1}}>
			{protocolList.map((protocol, i) => (
				<InspectionProtocolItem protocol={protocol} key={i}/>
			))}
		</Stack>
	);
};
export default InspectionProtocolList;