import {useDispatch, useSelector} from "react-redux";

import {extraObjectCreateRedux} from "@redux/drawLayer/actions";
import {matrixTransformWithWorldCenter} from "@utils/index";


const useCreatePoint = ({rN}) => {
	
	const dispatch = useDispatch();
	
	const {real} = useSelector((state) => state.tomograms);
	
	const createPoint = (point, createMode) => {
		
		const time = (new Date()).getTime();
		
		const stack = real[0].r1.stackHelper.stack;
		const {lps2IJK, _spacing, dimensionsIJK} = stack;
		
		const ijk = matrixTransformWithWorldCenter(_spacing, point, lps2IJK, stack.worldCenter());
		
		const tmData = {r1: dimensionsIJK.y - ijk.y, r2: dimensionsIJK.x - ijk.x, r3: ijk.z};
		tmData[rN] = real[0][rN].stackHelper.index;
		
		dispatch(extraObjectCreateRedux({
			xyz: point,
			id: time,
			time: new Date(time),
			tmData: tmData,
			type: createMode
		}));
		
	};
	
	return {
		createPoint,
	}
};
export default useCreatePoint;