import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useLang from "@hooks/useLang";


const styles = {
	bgcolor:'background.default',
	color: 'text.primary',
	height:'100vh',
	display:"flex",
	alignItems: "center",
	justifyContent: "center",
};

function LoginLayout({children}) {
	
	const {switchLang, lang} = useLang();
	
	return (
		<Box sx={styles}>
			<Button size={'small'} variant={'text'} color={'inherit'}
			        sx={{minWidth: 'auto',  position:'absolute', top:20, right:20}}
			        onClick={switchLang}>{lang}</Button>
			{children}
		</Box>
	)
}
export default LoginLayout;