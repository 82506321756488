import React from 'react';
import {Formik} from 'formik';

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CustomInput from "@ui/form/CustomInput/CustomInput";
import FormGroup from "@ui/form/FormGroup";
import CircularProgressWithLabel from "@ui/CircularProgressWithLabel";

import usePatientCreateForm from "./usePatientCreateForm";
import useTranslate from "@hooks/useTranslate";

import {translates, fieldTranslates, groupTranslates} from './translates';


const PatientCreateForm = () => {
	
	const {onSubmit, fields, initialValues, groups, progress} = usePatientCreateForm();
	const _groupTranslates = useTranslate({translates:groupTranslates});
	const _fieldTranslates = useTranslate({translates:fieldTranslates});
	const {submitTitle, submitProcessTitle} = useTranslate({translates});
	
	return (
		<Formik initialValues={initialValues} validate={undefined} onSubmit={onSubmit}>
			{({values, errors, handleChange, handleSubmit, isSubmitting, status, setFieldValue}) => (
				<form onSubmit={handleSubmit}>
					<Stack sx={{mt: 1}} spacing={2}>
						{groups.map((group, i) => (
							<FormGroup key={i} group={{...group,label:_groupTranslates[group.name]}}>
								{fields.filter(({name}) => group.fields.includes(name)).map((field, j) => (
									<CustomInput {...{field:{ ...field, label:_fieldTranslates[field.name]}, values, errors, handleChange, setFieldValue}} key={j}/>
								))}
							</FormGroup>
						))}
					</Stack>
					{!!status && <Alert severity="error">{status}</Alert>}
					<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{mt: 2}}>
						<Button variant="contained" type={'submit'} disabled={isSubmitting}>{isSubmitting ? submitProcessTitle+" ..." : submitTitle}</Button>
						{progress && <CircularProgressWithLabel value={progress} />}
					</Stack>
				</form>
			)}
		</Formik>
	);
};
export default PatientCreateForm;
