import React, {Suspense, useState} from "react";
import {useSelector} from "react-redux";

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import TomogramCanvas from "../canvas/TomogramCanvas";

import useTomogramContainer from "./hooks/useTomogramContainer";


const TomogramContainer = ({perspective, className}) => {
	
	const {cssClass, number, label, camera} = perspective;
	
	const {changeZoomMove, changeDown, changeUp, onWheel, eventMouseMove} = useTomogramContainer();
	
	const {real, zoomSize} = useSelector((state) => state.tomograms);
	
	const [isFullScreen, setIsFullScreen] = useState(false);
	
	const handleDoubleClick = (e)=>{
		setIsFullScreen(!isFullScreen);
		e.stopPropagation();
	};
	
	return (
		<div className={`canvasDiv ${className} ${!isFullScreen ? "_inactive" : "_active"} ${cssClass}`}
		     onWheel={e => onWheel(e, number)}
		     onDoubleClick={handleDoubleClick}
		     onMouseDown={changeDown}
		     onMouseUp={changeUp}
		     onContextMenu={e => e.preventDefault()}
		     onMouseMove={e => eventMouseMove ? changeZoomMove(e, number) : null}
		>
			<Suspense fallback={null}>
				<TomogramCanvas className={'canvasDiv__tomogram'} camera={{...camera, zoom: zoomSize[number]}} rN={number}/>
			</Suspense>
			<span className={'canvasDiv__caption'}>{label} {number} {real[0][number].stackHelper.index}</span>
			<span className={'canvasDiv__fullscreen-icon'} onClick={() => setIsFullScreen(!isFullScreen)}>
				{isFullScreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
			</span>
		</div>
	);
};
export default TomogramContainer;