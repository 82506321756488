import {useDispatch} from "react-redux";

import favoriteCreateLocal from "../localStore/favorite/create";
import {favoriteAddRedux, favoriteDeleteRedux} from "@redux/favorite/actions";
import favoriteDeleteLocal from "../localStore/favorite/delete";


function useFavorite() {
	
	const dispatch = useDispatch();
	
	const favoriteDelete = async (favoriteItemId)=>{
		await favoriteDeleteLocal(favoriteItemId);
		dispatch(favoriteDeleteRedux(favoriteItemId));
	};
	const favoriteCreate = async (patient)=>{
		const newFav = await favoriteCreateLocal({data_id:patient.id, title:`${patient.first_name} ${patient.last_name}`});
		dispatch(favoriteAddRedux(newFav));
		return newFav;
	};
	
	return {
		favoriteCreate,
		favoriteDelete,
	}
}
export default useFavorite;