import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {protocolListCreateRedux} from "@redux/protocol/actions";
import syntheticDataGetProtocolsRequest from "@api/synthetic-data/getProtocols";


const useInspectionProtocolList = () => {
	
	const dispatch = useDispatch();
	const {dataId} = useParams();
	
	const {protocolList} = useSelector((state) => state.protocol);
	
	const getProtocols = useCallback(async()=>{
		const {isSuccess, data} = await syntheticDataGetProtocolsRequest(dataId);
		isSuccess && dispatch(protocolListCreateRedux(data))
	},[dispatch,dataId]);
	
	useEffect(()=>{
		getProtocols().catch((error)=>console.error(error))
	},[getProtocols]);
	
	return {
		protocolList,
	};
};
export default useInspectionProtocolList;