import {createAction} from 'redux-actions';

import {
	CACHE_ENABLED
} from './constants';


const cacheEnabledAction = createAction(CACHE_ENABLED);

export const cacheEnabledRedux = (payload) => async (dispatch) => dispatch(cacheEnabledAction(payload));
