import {useNavigate} from "react-router-dom";

import registerRequest from "@api/auth/register";

import {fields, initialValues, validate} from './fields';
import routes from "../../../routes";
import {toast} from "react-toastify";


const useRegisterForm = () => {
	const navigate = useNavigate();
	
	const onSubmit = async (values, {setSubmitting, setErrors, setStatus}) => {
		
		const { isSuccess, errors, message } = await registerRequest(values);

		if (isSuccess) {
			// await authorizeRequest(data);
			await navigate(routes.login);
			toast("Register success!", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});
		} else {
			setStatus(message);
			setTimeout(()=>setStatus(""),3000);
			errors && setErrors(errors.json);
		}
		
		setSubmitting(false);
		
	};
	return {
		onSubmit,
		fields,
		initialValues,
		validate
	};
};
export default useRegisterForm;