import {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {roiPointActiveSetRedux, roiPointCreateManyRedux} from "@redux/ROIPoint/actions";
import {editableProtocolSetRedux} from "@redux/protocol/actions";

import protocolGetRoiRequest from "@api/protocol/getRoi";


const useRoiPointProtocolSingleList = () => {
	
	const {protocolId} = useParams();
	const dispatch = useDispatch();
	
	const {roiPointList} = useSelector((state) => state.ROIPoint);
	
	const getRoiPoints = async()=>{
		const {isSuccess, data} = await protocolGetRoiRequest(protocolId);
		if (isSuccess){
			dispatch(roiPointCreateManyRedux(data))
		}
	};
	
	useEffect(() => {
		dispatch(editableProtocolSetRedux({protocolId:parseInt(protocolId)}));
		getRoiPoints()
			.catch((error) => console.error(error));
		return (()=> {
			dispatch(editableProtocolSetRedux({protocolId:null}));
			dispatch(roiPointActiveSetRedux(null));
		});
	}, []);
	
	return {
		roiPointList
	};
};
export default useRoiPointProtocolSingleList;