import update from 'immutability-helper';
import {
	CACHE_ENABLED
} from "./constants";


const initialCacheState = {
	cacheEnabled: false,
};

export const cache = (state = initialCacheState, action) => {
	switch (action.type) {
		
		case CACHE_ENABLED:
			return update(state, { cacheEnabled: {$set: action.payload} });
		
		default:
			return state;
	}
};
