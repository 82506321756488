import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import {blue} from "@mui/material/colors";

import FaceIcon from "@mui/icons-material/Face";

import routes from "../../../../routes";
import {useSelector} from "react-redux";


const HeaderUser = () => {
	
	const navigate = useNavigate();
	
	const [doctorInfo,setDoctorInfo] = useState(null);
	const {profile} = useSelector((state) => state.profile);
	
	const handleAvatarClick = () => navigate(routes.profile);
	
	useEffect(()=>{
		setDoctorInfo(profile);
	},[profile]);
	
	return (
		<Stack direction={'row'}>
			{doctorInfo !== null && <Stack sx={{mr: 2}}>
				<Typography variant="subtitle1" noWrap>{doctorInfo.first_name} {doctorInfo.last_name}</Typography>
				<Typography variant="caption" noWrap>{doctorInfo.organization}</Typography>
			</Stack>}
			<Tooltip title="that's you">
				<IconButton onClick={handleAvatarClick} sx={{p: 0}}>
					<Avatar alt="Remy Sharp" sx={{bgcolor: "white"}}>
						<FaceIcon sx={{color: blue[500]}}/>
					</Avatar>
				</IconButton>
			</Tooltip>
		</Stack>
	);
};
export default HeaderUser;