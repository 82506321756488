import React from 'react';

import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";


const drawerWidth = 320;

const InspectionLayout = ({children, ...rest}) => {
	return (
		<Box sx={{ display: 'flex' }} {...rest}>
			<CssBaseline />
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="permanent"
				anchor="left"
			>
				{children.aside}
			</Drawer>
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: 'background.default', position: "relative" }}
			>
				{children.main}
			</Box>
		</Box>
	);
};
export default InspectionLayout;
