const routes = {
	login: "/login",
	profile: "/profile",
	mainMenu: "/",
	patientChoose: "/patient-choose",
	createPatientSuccess:"/create-patient-success",
	patients:"/patients",
	drafts: "/drafts",
	favorite: "/favorite",
	myPatients:"/my-patients",
	library:"/library",
	register: "/register",
	registerSuccess: "/register-success",
};
export default routes;