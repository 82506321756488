import React from 'react';

import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";


const SimpleKeyValueList = ({list}) => {
	return (
		<List dense>
			<Divider/>
			{list.map((item,i)=>(
				<ListItem divider key={i}>
					<ListItemText primary={item.key}/>
					<ListItemText primary={item.value} sx={{textAlign:'right', ml:1}}/>
				</ListItem>
			))}
		</List>
	);
};
export default SimpleKeyValueList;