import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import buildResponse from "../buildResponse";


const registerRequest = async (values) => {
	
	const result = await axiosService.post(buildUrl('/register'), {...values}, {headers: {'Content-Type': 'application/json'}});
	
	return buildResponse(result);
};
export default registerRequest;