import {createAction} from 'redux-actions';

import {
	PROFILE_SET,
	PROFILE_DELETE,
} from './constants';


const profileSetAction = createAction(PROFILE_SET);
const profileDeleteAction = createAction(PROFILE_DELETE);

export const profileSetRedux = (payload) => async (dispatch) => dispatch(profileSetAction(payload));
export const profileDeleteRedux = () => async (dispatch) => dispatch(profileDeleteAction());
