import React from 'react';

const wrpStyle = {
	position:"absolute",
	left:10,
	top:"50%",
};
const outerStyle = {
	position:"relative",
	width:"10px",
	marginTop:"-50%",
	borderBottom:"1px solid red",
	borderLeft:"1px solid red",
	borderTop:"1px solid red",
};

const itemStyle = {
	height: "1px",
	position:"absolute",
	backgroundColor:"red",
};

const cm2px = (cm) => {
	const n = 0;
	const cpi = 2.54; // centimeters per inch
	const dpi = 96; // dots per inch
	const ppd = window.devicePixelRatio; // pixels per dot
	return (cm / (cpi / (dpi * ppd))).toFixed(n);
};

const VRuler = ({ zoom=1 }) => {
	const pixels = (cm2px(1)*zoom).toFixed(0);
	return (
		<div style={{ ...wrpStyle }}>
			<div style={{ ...outerStyle, height: `${pixels}px` }}>
				<span style={{ ...itemStyle, width:"1px", top:"12.5%" }}/>
				<span style={{ ...itemStyle, width:"3px", top:"25%" }}/>
				<span style={{ ...itemStyle, width:"1px", top:"37.5%" }}/>
				<span style={{ ...itemStyle, width:"7px", top:"50%" }}/>
				<span style={{ ...itemStyle, width:"1px", top:"62.5%" }}/>
				<span style={{ ...itemStyle, width:"3px", top:"75%" }}/>
				<span style={{ ...itemStyle, width:"1px", top:"87.5%" }}/>
			</div>
		</div>
	);
};
export default VRuler;