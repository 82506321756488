// CommentsStore
import LocalStorage from "../libs/classes/LocalStorage";

localStorage.setItem('calculations',[]);
const CalculationsStore = new LocalStorage('calculations', [
	{id:0, data_id:1, inProcess:false, type: "all", error:null, source:'n32_fullsurf_synth.zip', start_date:(new Date()), end_date:(new Date())},
	{id:1, data_id:1, inProcess:false, type: "blurring", error:null, source:'n32_fullsurf_synth.zip', start_date:(new Date()), end_date:(new Date())},
	{id:2, data_id:1, inProcess:false, type: "all", error:'something wrong happened', source:null, start_date:(new Date()), end_date:(new Date())},
	{id:3, data_id:3, inProcess:false, type: "all", error:null, source:'n32_fullsurf_synth.zip', start_date:(new Date()), end_date:(new Date())},
]);

const FavoriteStore = new LocalStorage('favorite', []);

export {CalculationsStore, FavoriteStore};