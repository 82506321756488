import React from 'react';
import List from "@mui/material/List";


const ListWide = ({children}) => {
	return (
		<List>
			{children}
		</List>
	);
};
export default ListWide;