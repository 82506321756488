import React, {} from 'react';

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import MedRecordsBlock from "./MedRecordsBlock/MedRecordsBlock";
import PatientCard from "./PatientCard/PatientCard";
import DataBlock from "./DataBlock/DataBlock";


const PatientSingleMain = () => {
	return (
		<Grid container spacing={4}>
			<Grid item xs={3.5}>
				
				<PatientCard/>
				
			</Grid>
			<Grid item xs={8.5}>
				
				<Paper sx={{p:2}}>
					<Stack spacing={4}>
						<DataBlock/>
						<MedRecordsBlock/>
					</Stack>
				</Paper>
				
			</Grid>
		</Grid>
	);
};
export default PatientSingleMain;