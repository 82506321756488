import update from 'immutability-helper';
import {
	COMMENTS_SET,
	COMMENTS_UPDATE,
	COMMENT_DELETE,
	COMMENT_UPDATE,
	COMMENT_EDITABLE_SET
} from "./constants";

const initialCommentState = {
	commentsActive:null,
	commentEditable:null,
};

export const comment = (state = initialCommentState, action) => {
	switch (action.type) {
		
		case COMMENTS_SET:
			return update(state, {commentsActive: {$set: action.payload}});
		
		case COMMENTS_UPDATE:
			return update(state, {commentsActive: {$push: [action.payload]}});
			
		case COMMENT_DELETE:
			const commentIndexToDelete = state.commentsActive.findIndex(comment => comment.id === action.payload.commentId);
			return update(state, {commentsActive: {$splice: [[commentIndexToDelete, 1]]}});
			
		case COMMENT_UPDATE:
			const commentIndexToUpdate = state.commentsActive.findIndex(comment => comment.id === action.payload.commentId);
			return update(state, {commentsActive: {[commentIndexToUpdate]: { comment:{$set: action.payload.data.comment}}}});
			
		case COMMENT_EDITABLE_SET:
			return update(state, {commentEditable: {$set: action.payload}});
			
		default:
			return state;
	}
};
