const translates = {
	en:{
		submitButtonTitle:'send',
		submitButtonProcessTitle:'loading',
	},
	ru:{
		submitButtonTitle:'отправить',
		submitButtonProcessTitle:'загрузка',
	},
};
export default translates;