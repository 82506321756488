import * as THREE from "three";
import update from 'immutability-helper';
import {
	REAL_CLEAR,
	REAL_CREATE,
	REAL_UPDATE_SLICE_INDEX,
	REAL_UPDATE_ACTIVE_INDEX,
	REAL_SLICE_UPDATE_ALL_PERSPECTIVES_INDEX,
	LUT_COUNTER_SET,
	ZOOM_SIZE_SET,
	ACTIVE_TM_POINT_SET,
	ACTIVE_TM_SLICE_INDEXES_UPDATE,
} from "./constants";


const initialTomogramsState = {
	real: [],
	activeRealIndex:0,
	lutCounter: 1,
	activeTmPoint: new THREE.Vector3(0, 0, 0),
	activeTmSliceIndexes: { r1:128, r2:128, r3:128 },
	zoomSize:{
		r1:1.6,
		r2:1.6,
		r3:1.6,
	}
};

export const tomograms = (state = initialTomogramsState, action) => {
	switch (action.type) {
		
		case REAL_UPDATE_ACTIVE_INDEX:
			return update(state,{ activeRealIndex:{$set:action.payload.index} });
		case REAL_CREATE:
			return update(state,{ real:{$push: [action.payload]} });
		case REAL_CLEAR:
			return update(state,{ real:{$set: []} });
		case REAL_UPDATE_SLICE_INDEX:
			return update(state,{
				real:{
					[action.payload.index]: {
						[action.payload.rn]: {
							stackHelper: {
								index: {$set: action.payload.stackIndex}
							}
						}
					}
				}
			});
		case REAL_SLICE_UPDATE_ALL_PERSPECTIVES_INDEX:
			return update(state,{
				real:{
					[action.payload.index]: {
						...(action.payload.data.r1 && {
							r1: {
								stackHelper: { index:{ $set:action.payload.data.r1 } }
							}
						}),
						...(action.payload.data.r2 && {
							r2: {
								stackHelper: { index:{ $set:action.payload.data.r2 } }
							}
						}),
						...(action.payload.data.r3 && {
							r3: {
								stackHelper: { index:{ $set:action.payload.data.r3 } }
							}
						}),
					},
				}
			});

		case ACTIVE_TM_POINT_SET:
			return update(state,{
				activeTmPoint:{$set:action.payload}
			});
		case LUT_COUNTER_SET:
			return update(state,{ lutCounter:{$set:action.payload} });
		case ZOOM_SIZE_SET:
			return update(state,{ zoomSize:{[action.payload.r]:{$set:action.payload.size}} });
		case ACTIVE_TM_SLICE_INDEXES_UPDATE:
			return update(state,{
				activeTmSliceIndexes:{
						...(action.payload.r1 && { r1: { $set:action.payload.r1 } }),
						...(action.payload.r2 && { r2: { $set:action.payload.r2 } }),
						...(action.payload.r3 && { r3: { $set:action.payload.r3 } }),
				}
			});
		default:
			return state;
	}
};
