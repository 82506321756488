const translates = {
	en: {
		publishButtonTitle: 'Publish',
		saveButtonTitle: 'Save into file',
		deleteButtonTitle: 'Delete',
	},
	ru: {
		publishButtonTitle: 'Опубликовать',
		saveButtonTitle: 'Сохранить в файл',
		deleteButtonTitle: 'Удалить',
	}
};
export default translates;