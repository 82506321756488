import {Float32BufferAttribute} from "three";
import {Lut} from "three/examples/jsm/math/Lut";


export default function getColoredGeometry(geometry, map) {
	
	const colorsPars = [];
	let counter = 1;

	const lut = (new Lut('cooltowarm', map.length)).setMax(counter);
	
	map.forEach((color) => {
		const {r,g,b} = lut.getColor(color);
		colorsPars.push(r, g, b);
	});
	
	geometry.setAttribute("color", new Float32BufferAttribute(colorsPars, 3));

	return geometry;
}