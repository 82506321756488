import { createAction } from 'redux-actions';

import {
	SYNTHETIC_CREATE,
	SYNTHETIC_UPDATE_INDEX,
	SYNTHETIC_TOGGLE,
	SYNTHETIC_UPDATE_ACTIVE_INDEX,
	SYNTHETIC_UPDATE_ALL_PERSPECTIVES_INDEX,
	SYNTHETIC_UPDATE_ALL_PERSPECTIVES_OPACITY,
	SYNTHETIC_CLEAR
} from './constants';


const syntheticCreateAction = createAction(SYNTHETIC_CREATE);
const syntheticClearAction = createAction(SYNTHETIC_CLEAR);
const syntheticToggleAction = createAction(SYNTHETIC_TOGGLE);
const syntheticUpdateActiveIndexAction = createAction(SYNTHETIC_UPDATE_ACTIVE_INDEX);
const syntheticUpdateAllPerspectivesOpacityAction = createAction(SYNTHETIC_UPDATE_ALL_PERSPECTIVES_OPACITY);
const syntheticUpdateIndexAction = createAction(SYNTHETIC_UPDATE_INDEX);
const syntheticUpdateAllPerspectivesIndexAction = createAction(SYNTHETIC_UPDATE_ALL_PERSPECTIVES_INDEX);

export const syntheticCreateRedux = (payload) => async (dispatch) => dispatch(syntheticCreateAction(payload));
export const syntheticClearRedux = () => async (dispatch) => dispatch(syntheticClearAction());
export const syntheticUpdateActiveIndexRedux = (payload) => async (dispatch) => dispatch(syntheticUpdateActiveIndexAction(payload));
export const syntheticUpdateIndexRedux = (rn, stackIndex, index) => async (dispatch) => dispatch(syntheticUpdateIndexAction({rn,stackIndex, index}));
export const syntheticUpdateAllPerspectivesOpacityRedux = (payload) => async (dispatch) => dispatch(syntheticUpdateAllPerspectivesOpacityAction(payload));
export const syntheticUpdateAllPerspectivesIndexRedux = (payload) => async (dispatch) => dispatch(syntheticUpdateAllPerspectivesIndexAction(payload));
export const syntheticToggleRedux = (value) => async (dispatch) => dispatch(syntheticToggleAction(value));

