import React, {} from "react";

import Button from "@mui/material/Button";
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from "@mui/material/Stack";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SplitButtonPopper from "./SplitButtonPopper";


export default function SplitButton({onClick, options}) {
	
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	
	const handleClick = (event, index)=>{
		onClick(event, index)
	};
	
	const handleMenuItemClick = (event, index) => {
		event.stopPropagation();
		setSelectedIndex(index);
		setOpen(false);
	};
	
	const handleToggle = (event) => {
		event.stopPropagation(event);
		setOpen((prevOpen) => !prevOpen);
	};
	
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) return;
		
		setOpen(false);
	};

	return (
		<>
			<Stack direction={'row'} alignItems={'center'}>
				<ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
					<Button onClick={handleClick.bind(this, selectedIndex)} size={'small'}>{options[selectedIndex]}</Button>
					<Button
						size="small"
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-label="select merge strategy"
						aria-haspopup="menu"
						onClick={handleToggle}
					>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>
			</Stack>
			<SplitButtonPopper handleClose={handleClose} anchorRef={anchorRef} handleMenuItemClick={handleMenuItemClick} selectedIndex={selectedIndex} open={open} options={options}/>
		</>
	);
}