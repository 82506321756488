import update from 'immutability-helper';
import {
	LABEL_MESH_CREATE,
	LABEL_MESH_SHOW_TOGGLE,
	LABEL_MESH_UPDATE_SLICE_INDEXES,
	LABEL_MESH_UPDATE_INDEX,
	LABEL_MESH_CLEAR,
} from "./constants";


const initialLabelTomogramState = {
	labelMesh: null,
	labelMeshShow: false,
};

export const labelTomogram = (state = initialLabelTomogramState, action) => {
	switch (action.type) {
		
		case LABEL_MESH_CLEAR:
			return update(state,{ labelMesh:{$set: null} });
			
		case LABEL_MESH_CREATE:
			return update(state,{ labelMesh:{$set: action.payload} });
		
		case LABEL_MESH_SHOW_TOGGLE:
			return update(state,{ labelMeshShow:{$set:action.payload} });
		
		case LABEL_MESH_UPDATE_SLICE_INDEXES:
			return update(state,{
				labelMesh:{
					...(action.payload.data.r1 && {
						r1: { stackHelper: { index:{ $set:action.payload.data.r1 } }}
					}),
					...(action.payload.data.r2 && {
						r2: { stackHelper: { index:{ $set:action.payload.data.r2 } } }
					}),
					...(action.payload.data.r3 && {
						r3: { stackHelper: { index:{ $set:action.payload.data.r3 } } }
					}),
				}
			});
		case LABEL_MESH_UPDATE_INDEX:
			return update(state,{
				labelMesh:{
					[action.payload.rn]: {
						stackHelper: {
							index: {$set: action.payload.stackIndex}
						}
					}
				}
			});
		default:
			return state;
	}
};
