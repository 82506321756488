import React from 'react';

import Typography from "@mui/material/Typography";


const PageTitle = ({text, ...rest}) => {
	return (
		<Typography component='h1' variant='h5' {...rest}>{text}</Typography>
	);
};
export default PageTitle;