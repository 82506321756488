import { createAction } from 'redux-actions';

import {
	COMMENTS_SET, COMMENTS_UPDATE, COMMENT_DELETE, COMMENT_UPDATE, COMMENT_EDITABLE_SET
} from './constants';


const commentsSetAction = createAction(COMMENTS_SET);
const commentsUpdateAction = createAction(COMMENTS_UPDATE);
const commentDeleteAction = createAction(COMMENT_DELETE);
const commentUpdateAction = createAction(COMMENT_UPDATE);
const commentEditableSetAction = createAction(COMMENT_EDITABLE_SET);


export const commentsSetRedux = (payload) => async (dispatch) => dispatch(commentsSetAction(payload));
export const commentsUpdateRedux = (payload) => async (dispatch) => dispatch(commentsUpdateAction(payload));
export const commentDeleteRedux = (payload) => async (dispatch) => dispatch(commentDeleteAction(payload));
export const commentUpdateRedux = (payload) => async (dispatch) => dispatch(commentUpdateAction(payload));
export const commentEditableSetRedux = (payload) => async (dispatch) => dispatch(commentEditableSetAction(payload));
