import {useState} from 'react';
import {useDispatch} from "react-redux";

import medrecordDeleteRequest from "@api/medrecord/delete";
import {patientMedicalRecordDeleteRedux} from "@redux/patient/actions";


const useMedicalRecordListItem = () => {
	
	const dispatch = useDispatch();
	const [isModalOpen, setIsModalOpen] = useState(false);
	
	const onTitleClick = (e) => {
		e.preventDefault();
		setIsModalOpen(true)
	};
	
	const onDeleteClick = async (medrecordId)=>{
		const {isSuccess} = await medrecordDeleteRequest(medrecordId);
		isSuccess && dispatch(patientMedicalRecordDeleteRedux({medicalRecordId:medrecordId}));
	};
	
	return {
		isModalOpen,
		setIsModalOpen,
		onTitleClick,
		onDeleteClick,
	};
};
export default useMedicalRecordListItem;