import React from 'react';

import CommentForm from "../Form/CommentForm";
import useCommentCreateForm from "./useCommentCreateForm";

import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const CommentCreateForm = () => {
	const {onSubmit, fields, initialValues} = useCommentCreateForm();
	const _translates = useTranslate({translates});
	return (
		<CommentForm initialValues={initialValues} fields={fields} handleSubmit={onSubmit} messages={_translates}/>
	);
};
export default CommentCreateForm;