import React, {Suspense, useEffect, useRef, useState} from 'react';
import {Canvas, useThree} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {OrbitControls, Stats, Text} from "@react-three/drei";

import Pointer from "../Pointer";

import {useDispatch, useSelector} from "react-redux";
import {pialLeftSetRedux, pialRightSetRedux} from "@redux/gltf/actions";
import Lights from "./components/Lights";
import Slices3D from "./components/Slices3D";
import Volume3D from "./components/Volume3D/Volume3D";
import { CameraControls } from '@react-three/drei';


const CanvasVolume = ({archive}) => {
	
	const dispatch = useDispatch();
	
	const cameraControlsRef = useRef()
	
	const { pointPos, camera_near, showSlicesOn3D } = useSelector((state) => state.gltf);
	
	const loadGltf = async (archive) => {
		
		const filesKeys = Object.keys(archive.files);
		
		const pialKeys = filesKeys.filter(item=>item.includes("pial"));
		
		const pialLeftName = pialKeys.find(item=>item.includes("lh"));
		const pialRightName = pialKeys.find(item=>item.includes("rh"));
		
		const pialLeftObj = await archive.file(pialLeftName).async("ArrayBuffer");
		const pialRightObj = await archive.file(pialRightName).async("ArrayBuffer");
		
		const gltfLoader = new GLTFLoader();
		
		const gLeft = await gltfLoader.parseAsync(pialLeftObj, "");
		const gRight = await gltfLoader.parseAsync(pialRightObj, "");
		
		
		const _gLeftM = gLeft.scene.children[0].material;
		const _gRightM = gRight.scene.children[0].material;
		
		_gLeftM.transparent = true;
		_gRightM.transparent = true;
		
		dispatch(pialLeftSetRedux(gLeft));
		dispatch(pialRightSetRedux(gRight));
	};
	
	useEffect(()=>{
		loadGltf(archive)
			.catch((e)=>console.error(e))
		
	},[]);
	
	return (
		<Canvas camera={{position: [100, 100, 100], near:camera_near}} style={{backgroundColor: "#d9d9d9"}}>
			<Suspense fallback={null}>
				
				<Volume3D/>
				{showSlicesOn3D && <Slices3D/>}
				
				<Pointer pointPos={pointPos}/>
				<axesHelper args={[256/2]} />
				<axesHelper args={[256/2]} position={Object.values(pointPos)} />
				
				{/*<OrbitControls dampingFactor={0.05}/>*/}
				<CameraControls
					ref={cameraControlsRef}
				/>
				<Stats />
				
				<Lights/>
			</Suspense>
		</Canvas>
	);
};

export default CanvasVolume;