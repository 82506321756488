import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import List from "@mui/material/List";
import Box from "@mui/material/Box";
import EmptyBlock from "@ui/EmptyBlock";

import ROIPointItem from "../Item/ROIPointItem";

import useRoiPointList from "./useRoiPointList";

import {editableProtocolSetRedux} from "@redux/protocol/actions";
import {activeTmSliceIndexesUpdateRedux, realSliceUpdateAllPerspectivesIndexRedux} from "@redux/tomograms/actions";
import {rayCastSetRedux} from "@redux/rayCast/actions";

import roiGetRequest from "@api/roi/get";

import parseQuery from "./parseQuery";


const ROIPointList = ({...rest}) => {

	const dispatch = useDispatch();
	
	const {roiPointList} = useRoiPointList();
	
	const {real} = useSelector((state) => state.tomograms);
	const [activePointWasSet,setActivePointWasSet] = useState(false);
	
	const goToPoint = (coords) => {
		real.forEach((tm, i) => {
			dispatch(activeTmSliceIndexesUpdateRedux({...coords}));
			dispatch(realSliceUpdateAllPerspectivesIndexRedux({index: i, data: {...coords}}));
		});
	};
	const _goToPoint = async (roiId)=>{
		const {data} = await roiGetRequest(roiId);
		
		dispatch(rayCastSetRedux({...JSON.parse(data.note.split(';').at(0))}));
		goToPoint(JSON.parse(data.note.split(';').at(-1)))
		
	};
	
	useEffect(()=>{
		
		if(real.length > 0 && !activePointWasSet){
			
			const parsedQuery = parseQuery(window.location.search);
			const activePoint = parsedQuery.point;
			const activeProtocol = parsedQuery.protocol;
			
			if (activePoint && activeProtocol){
				dispatch(editableProtocolSetRedux({protocolId:parseInt(activeProtocol)}));
				_goToPoint(activePoint);
				setActivePointWasSet(true)
			}
		}
		
	},[real]);
	
	return (
		<Box sx={{
			overflow: "auto",
			mt: 2,
			border: roiPointList.length > 0 ? "1px solid grey" : "none",
			flex: "0 1 100%"
		}}>
			{roiPointList.length > 0 && (
				<List dense={true} {...rest}>
					{roiPointList.map((point, i) => (
						<ROIPointItem point={point} key={i}/>
					))}
				</List>
			)}
			{roiPointList.length <=0 && <EmptyBlock text={'no points'} sx={{height:"100%", ">*": { height:"calc(100% - 2px)!important" }}}/>}
		</Box>
	);
};
export default ROIPointList;
