import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clear} from "idb-keyval";

import {cacheEnabledRedux} from "@redux/cache/actions";
import {langSetRedux} from "@redux/lang/actions";

import {rawStore, stackStore, syntheticStore} from "./idbStore/init";
import {logoutRequest} from "@api/auth";

import {profileDeleteRedux, profileSetRedux} from "@redux/profile/actions";


const useApp = () => {
	
	const dispatch = useDispatch();
	const {profile} = useSelector((state) => state.profile);
	const idbStoresToClear = [syntheticStore, rawStore, stackStore];
	
	const checkCacheEnabled = () => {
		const isCacheEnabled = localStorage.getItem('cacheEnabled');
		
		if (isCacheEnabled !==null && (isCacheEnabled === '1' || isCacheEnabled === 'true')){
			console.info('idb cache enabled');
			dispatch(cacheEnabledRedux(true));
		} else {
			console.info('idb cache disabled');
			dispatch(cacheEnabledRedux(false));
			
			idbStoresToClear.forEach((idbStore, index)=>{
				clear(idbStore)
					.then(()=>{
						console.info(`idb store [${index}] cleared`);
					})
					.catch((error)=>console.error(error));
			})
		}
	};
	
	const setAppLang = () => {
		const navigatorLanguage = window.navigator.language.split('-')[0];
		const langLocal = localStorage.getItem('lang');
		
		if (!langLocal){
			localStorage.setItem('lang', navigatorLanguage);
			dispatch(langSetRedux(navigatorLanguage))
		} else {
			dispatch(langSetRedux(langLocal))
		}
	};
	
	const logout = async () => {
		await logoutRequest();
	};
	
	useEffect(()=>{},[profile]);
	
	useEffect(()=>{
		
		const login = JSON.parse(localStorage.getItem('login'));
		
		if(login !== null) {
			
			dispatch(profileSetRedux(login));
			
		} else {
			
			if (profile !== null){
				logout().catch(error=>console.error(error));
				dispatch(profileDeleteRedux());
			}
		}
	},[dispatch]);
	
	return {
		checkCacheEnabled,
		setAppLang,
	}
};
export default useApp;