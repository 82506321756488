import React, {} from 'react';

const Lights = () => {
	return (
		<>
			<ambientLight intensity={1}/>
			{/*<pointLight position={[70, 10, 10]} intensity={1000}/>*/}
			{/*<pointLight position={[-200, 10, 10]} intensity={10000}/>*/}
			{/*<pointLight position={[100, 100, 100]} intensity={10000}/>*/}
			<pointLight position={[0, 150, 0]} intensity={10000}/>
			<pointLight position={[0, -150, 0]} intensity={10000}/>
			
			<pointLight position={[150, 0, 0]} intensity={10000}/>
			<pointLight position={[-150, 0, 0]} intensity={10000}/>
			
			<pointLight position={[0, 0, 150]} intensity={10000}/>
			<pointLight position={[0, 0, -150]} intensity={10000}/>
		</>
	);
};

export default Lights;