import React from "react";
import {useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoginLayout from "@ui/layout/LoginLayout";

import routes from "../../routes";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const RegisterSuccessPage = () => {
	
	const navigate = useNavigate();
	
	const {continueButtonTitle, endButtonTitle, successMessage} = useTranslate({translates});
	
	const continueClick = () => navigate(routes.mainMenu);
	const exitClick = () => navigate(routes.register);
	
	return (
		<LoginLayout>
			<Box sx={{width: "280px", textAlign: "center"}}>
				<h4>{successMessage}</h4>
				<Button sx={{}} variant="contained" onClick={continueClick}>{continueButtonTitle}</Button>
				<Button sx={{mt: 2}} variant="contained" onClick={exitClick}>{endButtonTitle}</Button>
			</Box>
		</LoginLayout>
	);
};
export default RegisterSuccessPage;
