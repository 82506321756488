const translates = {
	en: {
		title: 'Point comments',
		caption: 'Comments adding/changing are not available',
	},
	ru: {
		title: 'Комментарии к точке',
		caption: 'Добавление/изменение комментариев недоступно',
	}
};
export default translates;