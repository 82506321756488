import React from "react";

import ShowChartIcon from '@mui/icons-material/ShowChart';
import StraightenIcon from '@mui/icons-material/Straighten';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


const modesSvg = {
	point: <RadioButtonCheckedIcon/>,
	line: <ShowChartIcon/>,
	text: <TextFieldsIcon/>,
	ruler: <StraightenIcon/>,
};
export default modesSvg;