import React, {} from 'react';
import {useSelector} from "react-redux";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import useTranslate from "@hooks/useTranslate";

import useProtocolStateRow from "./useProtocolStateRow";

import translates from "./translates";


const InspectionProtocolStateRow = () => {
	
	const {editTitle,cancelButtonTitle,publishButtonTitle,saveButtonTitle} = useTranslate({translates});
	
	const { handleButtonEditableClearClick, handleButtonSaveClick } = useProtocolStateRow();

	const {editableProtocolId} = useSelector((state) => state.protocol);
	const {roiPointList} = useSelector((state) => state.ROIPoint);

	return (
		<>
			{editableProtocolId == null
				? (
					<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{mt: 1}}>
						<Button variant={'contained'} size={'small'} onClick={()=>handleButtonSaveClick()} disabled={!roiPointList.length} sx={{mt: 1}}>{saveButtonTitle}</Button>
						<Button variant={'contained'} size={'small'} onClick={()=>handleButtonSaveClick("final")} disabled={!roiPointList.length} sx={{mt: 1}}>{publishButtonTitle}</Button>
					</Stack>
				) : (
					<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{mt: 1}}>
						<div>{editTitle} {editableProtocolId}</div>
						<Button variant={'contained'} size={'small'} onClick={handleButtonEditableClearClick}>{cancelButtonTitle}</Button>
					</Stack>
				)
			}
		</>
	);
};
export default InspectionProtocolStateRow;