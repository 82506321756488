import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import collectFormData from "../collectFormData";
import {PROFILE_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const profileCreatePatientRequest = async (values, setProgress = () => {}) => {
	
	const formData = collectFormData({...values});
	
	const config = {
		headers: {'Content-Type': 'multipart/form-data'},
		onUploadProgress: function (progressEvent) {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			setProgress(percentCompleted)
		}
	};
	
	const result = await axiosService.post(buildUrl(`/${PROFILE_ROOT}/subjects`), formData, config);
	
	return buildResponse(result);
};
export default profileCreatePatientRequest;