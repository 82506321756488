import axiosService from "../../libs/services/axiosService";
import {buildUrl} from "../settings";
import {DOCTORS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const doctorGetProcessingsRequest = async (doctorId) => {
    
    const result = await axiosService.get(buildUrl(`/${DOCTORS_ROOT}/${doctorId}/processings`));
    
    return buildResponse(result);
};
export default doctorGetProcessingsRequest;