import { createAction } from 'redux-actions';

import {
	ROI_POINT_CREATE,
	ROI_POINT_DELETE,
	ROI_POINT_EDIT,
	ROI_POINT_CLEAR_ALL,
	ROI_POINT_EDIT_MANY,
	ROI_POINT_CREATE_MANY,
	ROI_POINT_ACTIVE_SET,
} from './constants';


const roiPointCreateAction = createAction(ROI_POINT_CREATE);
const roiPointDeleteAction = createAction(ROI_POINT_DELETE);
const roiPointEditAction = createAction(ROI_POINT_EDIT);
const roiPointEditManyAction = createAction(ROI_POINT_EDIT_MANY);
const roiPointClearAllAction = createAction(ROI_POINT_CLEAR_ALL);
const roiPointCreateManyAction = createAction(ROI_POINT_CREATE_MANY);
const roiPointActiveSetAction = createAction(ROI_POINT_ACTIVE_SET);


export const roiPointCreateRedux = (payload) => async (dispatch) => dispatch(roiPointCreateAction(payload));
export const roiPointDeleteRedux = (payload) => async (dispatch) => dispatch(roiPointDeleteAction(payload));
export const roiPointEditRedux = (payload) => async (dispatch) => dispatch(roiPointEditAction(payload));
export const roiPointEditManyRedux = (payload) => async (dispatch) => dispatch(roiPointEditManyAction(payload));
export const roiPointClearAllRedux = () => async (dispatch) => dispatch(roiPointClearAllAction());
export const roiPointCreateManyRedux = (payload) => async (dispatch) => dispatch(roiPointCreateManyAction(payload));
export const roiPointActiveSetRedux = (payload) => async (dispatch) => dispatch(roiPointActiveSetAction(payload));