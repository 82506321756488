import React from "react";

import "./DropZone.scss";


const DropZone = ({handleUpload, onDragLeave}) => {
	return (
		<div className={'dropZone'} onDrop={handleUpload} onDragLeave={onDragLeave} onMouseUp={onDragLeave}>drug here</div>
	);
};
export default DropZone;