import update from 'immutability-helper';
import {
	PROTOCOL_CREATE,
	PROTOCOL_DELETE,
	PROTOCOL_CHANGE_STATUS,
	EDITABLE_PROTOCOL_SET,
	EDITABLE_PROTOCOL_CLEAR,
	PROTOCOL_LIST_CREATE,
} from "./constants";


const initialProtocolState = {
	editableProtocolId: null,
	protocolList: [],
};

export const protocol = (state = initialProtocolState, action) => {
	switch (action.type) {
		
		case PROTOCOL_LIST_CREATE:
			return update(state, {protocolList: {$set: action.payload}});
		
		case PROTOCOL_CREATE:
			return update(state, {protocolList: {$push: [action.payload]}});
		
		case PROTOCOL_DELETE:
			const indexToDelete = state.protocolList.findIndex(protocol => protocol.id === action.payload.protocolId);
			return update(state, {protocolList: {$splice: [[indexToDelete, 1]]}});
		
		case PROTOCOL_CHANGE_STATUS:
			const indexToChange = state.protocolList.findIndex(protocol => protocol.id === action.payload.protocolId);
			return update(state, {protocolList: {[indexToChange]: {state: {$set: action.payload.state}}}});
			
		case EDITABLE_PROTOCOL_SET:
			return update(state, {editableProtocolId: {$set: action.payload.protocolId}});
			
		case EDITABLE_PROTOCOL_CLEAR:
			return update(state, {editableProtocolId: {$set: null}});
			
		default:
			return state;
	}
};
