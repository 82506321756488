import React from "react";

import LoginLayout from "@ui/layout/LoginLayout";

import LoginForm from "../components/Profile/LoginForm";


function LoginPage() {
	return (
		<LoginLayout>
			<LoginForm/>
		</LoginLayout>
	);
}
export default  LoginPage;
