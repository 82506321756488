const getSurfacePoint = (point, origPositionArray, findPositionArray) => {

	const indexX = origPositionArray.indexOf(point[0]);
	const indexY = origPositionArray.indexOf(point[1]);
	const indexZ = origPositionArray.indexOf(point[2]);

	const valX = findPositionArray[indexX];
	const valY = findPositionArray[indexY];
	const valZ = findPositionArray[indexZ];

	return [valX, valY, valZ];

};
export default getSurfacePoint;