import * as THREE from "three";

import CoreUtils from "../libs/ami-legacy/core/core.utils";
import {RASToLPS} from "./index";


const matrixTransform = (spacing, point, lps2IJK) => {
	let RASToLPSMatrix = new THREE.Matrix4()
		.set(
			-spacing.x, 0, 0, 0,
			0, spacing.y, 0, 0,
			0, 0, -spacing.z, 0,
			0, 0, 0, 1
		);
	
	const v3 = RASToLPS(RASToLPSMatrix, point);
	return CoreUtils.worldToData(lps2IJK, v3);
};
export default matrixTransform;