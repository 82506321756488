import React, {} from "react";

import PageTitle from "@ui/PageTitle";
import DrawerLayout from "@ui/layout/DrawerLayout";

import PatientList from "@Patient/List/PatientList";

import usePatients from "@hooks/usePatients";

import usePatientListPage from "./usePatientListPage";


const PatientListPage = () => {
	
	const {pageTitle} = usePatientListPage();
	return (
		<DrawerLayout>
			<PageTitle text={pageTitle}/>
			<PatientList {...usePatients()}/>
		</DrawerLayout>
	);
};
export default PatientListPage;