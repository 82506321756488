import useTranslate from "@hooks/useTranslate";
import translates from "./translates";

const useFavoriteBlock = () => {
	const trans = useTranslate({translates});
	return {
		trans,
	};
};

export default useFavoriteBlock;