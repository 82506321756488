const isAllFilesBelongToOnePatient = files => {
	let checkNamePatient = true;
	if (files[0]?.name[2] === "_") {
		const dot = files[0].name.indexOf(".");
		const word = files[0].name.slice(3, dot);
		files.forEach((file) => {
			word !== file.name.slice(3, file.name.indexOf(".")) && (checkNamePatient = false);
		});
	}
	return checkNamePatient;
};
export default isAllFilesBelongToOnePatient;