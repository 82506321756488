import axiosService from "../../libs/services/axiosService";
import {buildUrl} from "../settings";
import {ROI_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const roiCreateCommentRequest = async (roiId, data) => {
	
	const result = await axiosService.post(buildUrl(`/${ROI_ROOT}/${roiId}/roi_comments`), JSON.stringify({...data}));
	
	return buildResponse(result);
};
export default roiCreateCommentRequest;
