import React from 'react';
import {Formik} from 'formik';

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CustomInput from "@ui/form/CustomInput/CustomInput";

import useMedicalRecordCreateForm from "./useMedicalRecordCreateForm";


const MedicalRecordCreateForm = ({patientId, setIsOpen}) => {
	
	const {onSubmit, fields, initialValues} = useMedicalRecordCreateForm({patientId, setIsOpen});
	
	return (
		<Formik initialValues={initialValues} validate={undefined} onSubmit={onSubmit}>
			{({values, errors, handleChange, handleSubmit, isSubmitting, status, setFieldValue}) => (
				<form onSubmit={handleSubmit}>
					<Stack sx={{mt: 1}} spacing={2}>
						{fields.map((field, j) => (
							<CustomInput {...{field, values, errors, handleChange, setFieldValue}} key={j}/>
						))}
					</Stack>
					{!!status && <Alert severity="error">{status}</Alert>}
					<Button variant="contained" type={'submit'} disabled={isSubmitting}
					        sx={{mt: 2}}>{isSubmitting ? "loading..." : "создать"}</Button>
				</form>
			)}
		</Formik>
	);
};
export default MedicalRecordCreateForm;
