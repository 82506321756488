// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbarButton {
  display: block;
  padding: 3px;
  cursor: pointer;
}
.toolbarButton > svg {
  display: block;
  height: 1em;
  width: 1em;
  font-size: 18px;
}
.toolbarButton._active {
  background: white;
  color: black;
}
.toolbarButton._active > svg {
  color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/pages/Viewer/components/Toolbar/components/ToolbarButton/toolbarButton.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,eAAA;AACF;AAAE;EACE,cAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;AAEJ;AAAE;EACE,iBAAA;EACA,YAAA;AAEJ;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".toolbarButton {\n  display: block;\n  padding: 3px;\n  cursor: pointer;\n  & > svg {\n    display: block;\n    height: 1em;\n    width: 1em;\n    font-size: 18px;\n  }\n  &._active {\n    background: white;\n    color: black;\n  }\n  &._active > svg {\n    color: inherit;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
