import React from 'react';

import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";


const FavoriteListItemHome = ({favorite}) => {
	return (
		<ListItem divider>
			<ListItemText primary={favorite.title}/>
		</ListItem>
	);
};
export default FavoriteListItemHome;