import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PROTOCOLS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const protocolDeleteRequest = async (protocolId) => {
	
	const result = await axiosService.delete(buildUrl(`/${PROTOCOLS_ROOT}/${protocolId}`));
	
	return buildResponse(result);
};
export default protocolDeleteRequest;