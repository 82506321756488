import {useEffect, useState} from "react";
import {API_HOST, API_PORT} from "@api/constants";


const supportedFormats = ['txt', 'png', 'csv'];


const useMedicalRecordModal = ({isOpen, record}) => {
	
	const [recordData, setRecordData] = useState(undefined);
	const [loading, setLoading] = useState(false);
	
	const getRecordData = async (path, extension) => {
		
		const fixedPath = `https://${API_HOST}:${API_PORT}${path}`;
		
		setLoading(true);
		
		const response = await fetch(fixedPath);
		
		if (!response.ok) {
			const d = await response.json();
			
			setRecordData({extension: extension, data: d.status});
			setLoading(false);
			return;
		}
		
		let data = null;
		if (extension === 'txt' || extension === 'csv') {
			data = await response.text();
			
		} else if (extension === 'png') {
			const imageBlob = await response.blob();
			data = await new Promise((resolve) => {
				let fileReader = new FileReader();
				fileReader.onload = (e) => resolve(fileReader.result);
				fileReader.readAsDataURL(imageBlob);
			});
		}
		
		setRecordData({extension: extension, data: data});
		setLoading(false)
	};
	
	useEffect(() => {
		
		if (isOpen) {
			const extension = record.medrecord_name.split(".").at(-1);
			
			if (supportedFormats.includes(extension)) {
				getRecordData(record.path, extension)
					.catch(e => console.log(e))
			} else {
				setRecordData(null);
			}
			
		} else {
			setRecordData(undefined);
		}
	}, [isOpen, record]);
	
	return {
		recordData,
		loading,
	};
};
export default useMedicalRecordModal;