const translates = {
	en: {
		successMessage: 'Register successful!',
		continueButtonTitle: 'Continue working',
		endButtonTitle: 'End working',
	},
	ru: {
		successMessage: 'Регистрация прошла успешно!',
		continueButtonTitle: 'Продолжить работу',
		endButtonTitle: 'Завершить работу',
	}
};
export default translates;