import {createAction} from 'redux-actions';

import {
	LANG_SET
} from './constants';


const langSetAction = createAction(LANG_SET);

export const langSetRedux = (payload) => async (dispatch) => dispatch(langSetAction(payload));
