import {useDispatch} from "react-redux";
import dateFormat from "dateformat";

import useComment from "@hooks/useComment";
import {commentEditableSetRedux} from "@redux/comment/actions";


const dateMask = "mmmm dS, yyyy, h:MM";

const useCommentListItem = ({comment}) => {
	
	const dispatch = useDispatch();
	const { commentDelete } = useComment();
	
	const formattedDate = dateFormat(comment.create_date, dateMask);
	
	const handleDeleteClick = async (e) => {
		e.preventDefault();
		window.confirm('вы уверены что хотите удалить этот комментарий?') && await commentDelete(comment.id)
	};
	
	const handleEditClick = async () => {
		dispatch(commentEditableSetRedux(comment));
	};
	
	return {
		handleDeleteClick,
		handleEditClick,
		formattedDate,
	};
};
export default useCommentListItem;