import React from "react";

import CanvasSphere from "../primitives/CanvasSphere";


const Orb = ({ point, visibility }) => {
	return (
		<group visible={visibility}>
			<CanvasSphere position={point.xyz} radius={2}/>
		</group>
	)
};
export default Orb;