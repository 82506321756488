import React, {} from "react";

import getDateString from "./getDateString";

import "./DrawLogRow.scss";


const DrawLogRow = ({ object, onRemoveClick=()=>{} }) => {
	
	const onClick = (e) => {
		e.preventDefault();
		onRemoveClick(object.id);
	};
	
	return (
		<div className={'DrawLogRow'}>
			<span className={'DrawLogRow__point'}/>
			<span className={'DrawLogRow__title'}>{ `Set ${object.type}` }</span>
			<span className={'DrawLogRow__time'}>{ getDateString(object.time) }</span>
			<a className={'DrawLogRow__link'} onClick={ onClick } href={"#remove"}>remove</a>
		</div>
	);
};
export default DrawLogRow;