import React from "react";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";


const ErrorStatus = ({status, code}) => {
	return (
		<Stack direction={'column'} alignItems={'center'} sx={{py: 5}}>
			<Typography variant="h2" component="h2" sx={{mb: 1}}>{code}</Typography>
			<Typography variant="body1" component="body1">{status}</Typography>
		</Stack>
	)
};
export default ErrorStatus;