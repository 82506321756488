const dims = [
	{
		name: "z",
		label:"coronal",
		camera:{
			position: [0, 0, 300],
		},
	},
	{
		name: "x",
		label:"saggital",
		camera:{
			position: [300, 0, 0], up: [0, 1, 0],
		}
	},
	{
		name: "y",
		label:"axial",
		camera:{
			position: [0, 300, 0], up: [0, 0, 1],
		}
	}
];
export default dims;