import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import loginRequest from "@api/auth/login";
import {profileSetRedux} from "@redux/profile/actions";

import routes from "../../../routes";
import {fields, initialValues, validate} from './fields';


const useLoginForm = () => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const onSubmit = async (values, {setSubmitting, setErrors, setStatus}) => {
		
		const {isSuccess, errors, data, message} = await loginRequest(values);
		
		if (!isSuccess) {
			setStatus(message);
			setTimeout(() => setStatus(""), 3000);
			errors && setErrors(errors.json);
		} else {
			sessionStorage.setItem('accessToken', data.access_token);
			sessionStorage.setItem('refreshToken', data.refresh_token);
			localStorage.setItem('login', JSON.stringify({...data.user}));
			dispatch(profileSetRedux({...data.user}));
			navigate(routes.mainMenu);
		}
		
		setSubmitting(false);
		
	};
	return {
		onSubmit,
		fields,
		initialValues,
		validate
	};
};
export default useLoginForm;