import React from 'react';
import dateFormat from "dateformat";

import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import {blue} from "@mui/material/colors";
import FaceIcon from "@mui/icons-material/Face";

import SimpleKeyValueList from "@components/SimpleKeyValueList";


const dateMask = "mmmm dS, yyyy";

const ProfileCard = ({profile}) => {
	
	const list = [
		{key:"Никнейм",value:profile.username},
		{key:"Дата регистрации",value:dateFormat(profile.create_date, dateMask)},
	];
	
	return (
		<Paper sx={{px:2,py:2, position:'relative'}}>
			<Stack spacing={1} alignItems={'center'} sx={{mb:2}}>
				<Stack spacing={0} alignItems={'center'} sx={{mb:2}}>
					<Avatar alt="Remy Sharp" sx={{bgcolor: "#f9f9f9", width: 112, height: 112, mb:2}}>
						<FaceIcon sx={{color: blue[500], fontSize:72}} />
					</Avatar>
					<Typography variant="h5" component='h5'>{profile.first_name}</Typography>
					<Typography variant="h5" component='h5'>{profile.last_name}</Typography>
					<Typography variant="body2" component='p'><i>{profile.organization}</i></Typography>
				</Stack>
				
				<SimpleKeyValueList list={list}/>
			</Stack>
		</Paper>
	);
};
export default ProfileCard;