import React from 'react';
import {Formik} from 'formik';

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CustomInput from "@ui/form/CustomInput/CustomInput";
import CircularProgressWithLabel from "@ui/CircularProgressWithLabel";

import useInstrDataCreateForm from "./useInstrDataCreateForm";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const InstrDataCreateForm = ({patientId, initialOverride, setIsOpen}) => {
	
	const {onSubmit, fields, initialValues, progress} = useInstrDataCreateForm({patientId, setIsOpen});
	const {submitButtonTitle, submitButtonProcessTitle} = useTranslate({translates});
	const _translates = useTranslate({translates});
	
	return (
		<Formik initialValues={{...initialValues,...initialOverride}} validate={undefined} onSubmit={onSubmit}>
			{({values, errors, handleChange, handleSubmit, isSubmitting, status, setFieldValue}) => (
				<form onSubmit={handleSubmit}>
					<Stack sx={{mt: 1}} spacing={2}>
						{fields.map((field, j) => (
							<CustomInput {...{field:{...field, label:_translates[field.name]}, values, errors, handleChange, setFieldValue}} key={j}/>
						))}
					</Stack>
					{!!status && <Alert severity="error">{status}</Alert>}
					<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{mt: 2}}>
						<Button variant="contained" type={'submit'} disabled={isSubmitting}>{isSubmitting ? submitButtonProcessTitle+"..." : submitButtonTitle}</Button>
						{progress && <CircularProgressWithLabel value={progress} />}
					</Stack>
				</form>
			)}
		</Formik>
	);
};
export default InstrDataCreateForm;
