import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

import {activeIndexSetRedux} from "@redux/gltf/actions";
import processingGetClustersRequest from "@api/processing/getClusters";


const useClustersList = () => {
	
	const dispatch = useDispatch();
	const {processingId} = useParams();
	
	const [clusters, setClusters] = useState([]);
	
	const onItemClick = async (slices) => {
		dispatch(activeIndexSetRedux({x:slices[0],y:slices[1],z:slices[2]}))
	};
	
	const getClusters = async ()=>{
		try {
			const {data} = await processingGetClustersRequest(processingId);
			setClusters(data);
		} catch (e) {
			console.error(e)
		}
	};
	
	useEffect(()=>{
		getClusters()
	},[]);
	
	return {
		clustersList: clusters,
		onItemClick:onItemClick,
	};
};

export default useClustersList;