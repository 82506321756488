import {parseUChar8} from "./index";


const parseFloat32EndianSwapped = (data, offset) => {
	const b0 = parseUChar8(data, offset);
	const b1 = parseUChar8(data, offset + 1);
	const b2 = parseUChar8(data, offset + 2);
	const b3 = parseUChar8(data, offset + 3);
	const sign = 1 - 2 * (b0 >> 7);
	const exponent = (((b0 << 1) & 0xff) | (b1 >> 7)) - 127;
	const mantissa = ((b1 & 0x7f) << 16) | (b2 << 8) | b3;

	if (mantissa === 0 && exponent === -127) {
		return 0.0;
	}

	return sign * (1 + mantissa * Math.pow(2, -23)) * Math.pow(2, exponent);
};
export default parseFloat32EndianSwapped;