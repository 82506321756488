import React from 'react';

const px2cm = (px) => {
	const n = 0;
	const cpi = 2.54; // centimeters per inch
	const dpi = 96; // dots per inch
	const ppd = window.devicePixelRatio; // pixels per dot
	return (px * cpi / (dpi * ppd)).toFixed(n);
};

const wrpStyle = {
	position:"absolute",
	bottom:10,
	left:"50%",
};
const outerStyle = {
	position:"relative",
	height:"10px",
	marginLeft:"-50%",
	borderBottom:"1px solid red",
	borderLeft:"1px solid red",
	borderRight:"1px solid red",
};
const innerStyle = {
	position:"absolute",
	left:"calc(100% + 10px)",
	whiteSpace: "nowrap",
	color:"red",
	fontSize: "10px",
	lineHeight: "8px"
};

const itemStyle = {
	width: "1px",
	position:"absolute",
	backgroundColor:"red",
};

const cm2px = (cm) => {
	const n = 0;
	const cpi = 2.54; // centimeters per inch
	const dpi = 96; // dots per inch
	const ppd = window.devicePixelRatio; // pixels per dot
	return (cm / (cpi / (dpi * ppd))).toFixed(n);
};

const Ruler = ({ zoom=1 }) => {
	const pixels = (cm2px(1)*zoom).toFixed(0);
	return (
		<div style={{ ...wrpStyle }}>
			<div style={{ ...outerStyle, width: `${pixels}px` }}>
				<span style={{ ...itemStyle, height:"1px", left:"12.5%" }}/>
				<span style={{ ...itemStyle, height:"3px", left:"25%" }}/>
				<span style={{ ...itemStyle, height:"1px", left:"37.5%" }}/>
				<span style={{ ...itemStyle, height:"7px", left:"50%" }}/>
				<span style={{ ...itemStyle, height:"1px", left:"62.5%" }}/>
				<span style={{ ...itemStyle, height:"3px", left:"75%" }}/>
				<span style={{ ...itemStyle, height:"1px", left:"87.5%" }}/>
				<span style={{ ...innerStyle }}>1cm ({pixels}px)</span>
			</div>
		</div>
	);
};
export default Ruler;