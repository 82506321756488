import React from 'react';

import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDial from "@mui/material/SpeedDial";


const MyPatientListPageSpeedDial = ({onClick}) => {
	return (
		<SpeedDial
			ariaLabel="create patient SpeedDial"
			sx={{ position: 'absolute',  bottom: 16, right: 16 }}
			icon={<SpeedDialIcon />}
			onClick={onClick}
		/>
	);
};
export default MyPatientListPageSpeedDial;