import React from 'react';

import SplitButton from "../SplitButton/SplitButton";

import useTranslate from "@hooks/useTranslate";
import useRunProcessButton from "./useRunProcessButton";

import translates from "./translates";


const RunProcessButton = ({dataId}) => {
	
	const {options} = useTranslate({translates});
	
	const {handleCountButtonClick} = useRunProcessButton({dataId});
	
	return (
		<SplitButton onClick={handleCountButtonClick} options={options}/>
	);
};

export default RunProcessButton;