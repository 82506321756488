// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  display: inline-flex;
  max-height: 150px;
  background: #181c20;
  color: white;
  padding: 8px 4px;
}
.toolbar > * {
  margin: 0 4px;
}

.toolbar.fixed {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100000;
}`, "",{"version":3,"sources":["webpack://./src/pages/Viewer/components/Toolbar/toolbar.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;AACF;AAAE;EACE,aAAA;AAEJ;;AACA;EACE,eAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;AAEF","sourcesContent":[".toolbar {\n  display: inline-flex;\n  max-height: 150px;\n  background: #181c20;\n  color: white;\n  padding: 8px 4px;\n  & > * {\n    margin: 0 4px;\n  }\n}\n.toolbar.fixed {\n  position: fixed;\n  top: 10px;\n  left: 50%;\n  transform: translateX(-50%);\n  z-index: 100000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
