import update from 'immutability-helper';

import { FMRT_MAP_ADD} from "./constants";


const initialTomogramsState = {
	map: [],
};

export const fmrtMaps = (state = initialTomogramsState, action) => {
	switch (action.type) {
		case FMRT_MAP_ADD:
			return update(state,{map:{$set:action.payload}});
		default:
			return state;
	}
};
