import { createAction } from 'redux-actions';

import {
	CREATE_MODE_TOGGLE,
	FIRST_LINE_POINT_MODE_TOGGLE,
	EXTRA_OBJECT_CREATE,
	EXTRA_OBJECT_DELETE,
} from './constants';


const createModeToggleAction = createAction(CREATE_MODE_TOGGLE);
const firstLinePointModeToggleAction = createAction(FIRST_LINE_POINT_MODE_TOGGLE);
const extraObjectCreateAction = createAction(EXTRA_OBJECT_CREATE);
const extraObjectDeleteAction = createAction(EXTRA_OBJECT_DELETE);

export const createModeToggleRedux = (payload) => async (dispatch) => dispatch(createModeToggleAction(payload));
export const firstLinePointModeToggleRedux = (payload) => async (dispatch) => dispatch(firstLinePointModeToggleAction(payload));
export const extraObjectCreateRedux = (payload) => async (dispatch) => dispatch(extraObjectCreateAction(payload));
export const extraObjectDeleteRedux = (payload) => async (dispatch) => dispatch(extraObjectDeleteAction(payload));

