import React, {} from "react";

import Stack from "@mui/material/Stack";
import PageTitle from "@ui/PageTitle";
import DrawerLayout from "@ui/layout/DrawerLayout";

import useProfilePage from "./useProfilePage";
import ProfileCard from "./components/ProfileCard";
import Grid from "@mui/material/Grid";


const ProfilePage = () => {
	
	const { profile, isLoading } = useProfilePage();
	
	return (
		<DrawerLayout>
			<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{mb: 4}}>
				<PageTitle text='Profile'/>
				{isLoading && 'loading...'}
			</Stack>
			{!isLoading && profile !==null && (
				<Grid container spacing={4}>
					<Grid item xs={3.5}>
						<ProfileCard profile={profile}/>
					</Grid>
					<Grid item xs={8.5}>
					</Grid>
				</Grid>
			)}
		</DrawerLayout>
	);
};
export default ProfilePage;