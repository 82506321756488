import React, {} from 'react';

import Box from "@mui/material/Box";

import EmptyBlock from "../EmptyBlock";
import useTranslate from "@hooks/useTranslate";

import translates from './translates'


const ListEmptyStateWrap = ({length, children, emptyText, ...rest}) => {
	
	const {empty} = useTranslate({translates});
	
	return (
		<Box {...rest}>
			{!length && length <= 0
				? <EmptyBlock text={emptyText || empty}/>
				: children
			}
		</Box>
	);
};
export default ListEmptyStateWrap;