const translates = {
	en: {
		title: 'ROI points',
		buttonTitle: 'Fix ROIPoint',
	},
	ru: {
		title: 'Точки интереса',
		buttonTitle: 'Зафиксировать точку',
	}
};
export default translates;