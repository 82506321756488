import axiosService from "../../libs/services/axiosService";
import {buildUrl} from "../settings";
import {PROCESSINGS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const processingGetPointInfoRequest = async (processingId, pointIndex)=>{
	
	const url = `/${PROCESSINGS_ROOT}/${processingId}/pointinfo/${pointIndex}`;
	const result = await axiosService.get(buildUrl(url));
	
	return buildResponse(result);
	
	/*return new Promise((resolve)=>{
		setTimeout(()=>{
			resolve({
				index:pointIndex,
				orig: origValArr[pointIndex],
				thickness: thicknessValArr[pointIndex],
			})
		},100)
	})*/
};
export default processingGetPointInfoRequest;