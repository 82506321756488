import React from 'react';
import {Link} from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import routes from "../../../routes";
import useFavoriteListItem from "./useFavoriteListItem";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const FavoriteListItem = ({favorite}) => {
	
	const {handleDeleteClick} = useFavoriteListItem();
	const {deleteButtonTitle} = useTranslate({translates});
	
	return (
		<Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
			<Link to={`${routes.patients}/${favorite.data_id}`} style={{color: "inherit"}}>{favorite.title}</Link>
			<Button onClick={()=>handleDeleteClick(favorite.id)}>{deleteButtonTitle}</Button>
		</Stack>
	);
};
export default FavoriteListItem;