const makeAsyncRequest = (method, url, overrideMimeType, responseType) => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open(method, url);
		if ( overrideMimeType !== undefined ){
			xhr.overrideMimeType(overrideMimeType);
		}

		if (responseType !== undefined){
			xhr.responseType = responseType;
		}

		// xhr.setRequestHeader("Content-Type", "text/plain");
		xhr.onload = () => {
			if (xhr.status >= 200 && xhr.status < 300) {
				resolve(xhr.response);
			} else {
				reject({ status:xhr.status, statusText:xhr.statusText });
			}
		};
		xhr.onerror = () => {
			reject({status:xhr.status, statusText:xhr.statusText });
		};
		xhr.send();
	});
};
export default makeAsyncRequest;