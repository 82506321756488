import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import routes from "../routes";
import {Navigate} from "react-router";


const isLoggedIn = () => sessionStorage.getItem('accessToken');

const PrivateRoute = ({children, from ='/'}) => {
	const {profile} = useSelector((state) => state.profile);
	
	useEffect(()=>{},[profile]);
	
	if (isLoggedIn()){
		if (profile !== null){
			return children
		} else {
			return <div>loading...</div>
		}
	} else {
		return <Navigate to={{pathname: routes.login, state: {from: from}}}/>
	}
};
export default PrivateRoute;