import {useMemo} from "react";
import {useSelector} from "react-redux";
import * as THREE from "three";


const useSurfaceMeshMaterial = () => {
	
	const {wireframeShow, opacity} = useSelector((state) => state.geometry);
	
	const meshMaterial = useMemo(() => new THREE.MeshPhongMaterial({
		side: THREE.DoubleSide,
		vertexColors: true,
		wireframe: wireframeShow,
		transparent: true,
		opacity: opacity
	}), [opacity, wireframeShow]);
	
	return {
		meshMaterial,
	}
};
export default useSurfaceMeshMaterial;