import React, {} from 'react';
import List from "@mui/material/List";
import {useSelector} from "react-redux";

import ProcessingItem from "../ProcessingItem/ProcessingItem";


const ProcessingList = ({dataId}) => {
	
	const {processingsByDataId} = useSelector((state) => state.processing);
	
	return (
		<List dense>
			{processingsByDataId[dataId]?.map((processing, i) =>
				<ProcessingItem processing={processing} key={i}/>
			)}
		</List>
	);
};

export default ProcessingList;