import React from "react";
import {Text} from '@react-three/drei'

import getTextPosition from "./getTextPosition";
import getTextRotation from "./getTextRotation";


const CanvasText = ({point, rN, text, fontSize=10}) => {
	return (
		<Text position={getTextPosition(rN, point)}
		      rotation={getTextRotation(rN)}
		      lineHeight={1}
		      fontSize={fontSize}>{text}</Text>
	)
};
export default CanvasText;