import React, {} from "react";

import PageTitle from "@ui/PageTitle";
import DrawerLayout from "@ui/layout/DrawerLayout";

import PatientMyList from "@Patient/MyList/PatientMyList";
import PatientCreateModal from "@Patient/CreateModal/PatientCreateModal";

import useMyPatientListPage from "./useMyPatientListPage";
import MyPatientListPageSpeedDial from "./components/SpeedDial/MyPatientListPageSpeedDial";


const MyPatientListPage = () => {
	
	const {trans, handleSpeedDialClick, setModalIsOpen, modalIsOpen} = useMyPatientListPage();
	
	return (
		<DrawerLayout>
			<PageTitle text={trans.pageTitle}/>
			
			<PatientMyList/>
			<MyPatientListPageSpeedDial onClick={handleSpeedDialClick}/>
			<PatientCreateModal open={modalIsOpen} setOpen={setModalIsOpen}/>
		</DrawerLayout>
	);
};
export default MyPatientListPage;