import React from "react";

import FileUploadIcon from '@mui/icons-material/FileUpload';

import "./uploadBlock.scss";


const UploadBlock = ({handleUpload}) => {
	return (
		<div className={'uploadBlock'}>
			<input className={'uploadBlock__input'} type="file" onInput={handleUpload} accept="application/zip"/>
			<FileUploadIcon sx={{ fontSize: 40 }}/>
			<div className={'uploadBlock__title'}>Select an archive or move it here</div>
		</div>
	);
};
export default UploadBlock;