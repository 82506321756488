import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PATIENTS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const patientGetDataRequest = async (patientId) => {
	
	const result = await axiosService.get(buildUrl(`/${PATIENTS_ROOT}/${patientId}/raw-data`));
	
	return buildResponse(result);
};
export default patientGetDataRequest;