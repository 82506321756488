import { createAction } from 'redux-actions';

import {
	FAVORITES_SET,
	FAVORITE_DELETE,
	FAVORITE_ADD,
} from './constants';


const favoritesSetAction = createAction(FAVORITES_SET);
const favoriteAddAction = createAction(FAVORITE_ADD);
const favoriteDeleteAction = createAction(FAVORITE_DELETE);

export const favoritesSetRedux = (payload) => async (dispatch) => dispatch(favoritesSetAction(payload));
export const favoriteAddRedux = (payload) => async (dispatch) => dispatch(favoriteAddAction(payload));
export const favoriteDeleteRedux = (payload) => async (dispatch) => dispatch(favoriteDeleteAction(payload));
