export const API_HOST = process.env.REACT_APP_API_HOST || '80.209.227.53'; // new 10.16.88.113 // old 10.16.88.24
// export const API_HOST = 'healthy-brain.pro';
export const API_PORT = process.env.REACT_APP_API_PORT || 3002; // new 8080 // old 5000
// export const API_PORT = ;
export const API_PREFIX = '/api';

export const DOCTORS_ROOT = 'doctors';
export const PATIENTS_ROOT = 'subjects';
export const DATA_ROOT = 'data';
export const RAW_DATA_ROOT = 'raw-data';
export const SYNTHETIC_DATA_ROOT = 'synthetic-data';
export const MEDRECORD_ROOT = 'medrecords';
export const ROI_ROOT = 'rois';
export const PROTOCOLS_ROOT = 'protocols';
export const COMMENTS_ROOT = 'roi_comments';
export const PROCESSINGS_ROOT = 'processings';
export const PROFILE_ROOT = 'profile';


export const PROCESSING_STATES = {
	untouched:"untouched",
	progress:"progress",
	failed:"failed",
	finished:"finished",
};
export const PROCESSING_STATES_LABELS = {
	untouched:"в очереди",
	process:"в процессе",
	failed:"ошибка",
	finished:"завершен",
};
