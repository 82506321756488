export const patientInfoTranslates = {
	en: {
		createDate: 'Creation date',
		diagnosis: 'Diagnosis',
		comorbidity: 'Comorbidity',
	},
	ru: {
		createDate: 'Дата создания',
		diagnosis: 'Диагноз',
		comorbidity: 'Сопутствующий диагноз',
	}
};

export const patientDataTranslates = {
	en: {
		rawData: "raw \n data",
		synthData: 'synth \n data',
		medicalRecords: 'medical \n records',
	},
	ru: {
		rawData: 'сырые \n данные',
		synthData: 'синт. \n данные',
		medicalRecords: 'мед. \n записи',
	}
};