import React, {} from "react";
import {Provider, useSelector} from "react-redux";
import {Canvas} from "@react-three/fiber";
import {MapControls} from "@react-three/drei";

import TomogramMesh from "./components/TomogramMesh";
import Localizer from "./components/Localizer";

import {Store} from "@redux/store";

import useTomogramCanvas from "./useTomogramCanvas";

import LabelsLayer from "./components/LabelsLayer/LabelsLayer";


const TomogramCanvas = ({camera, rN, ...props}) => {
	
	const {filterSyntheticByActiveIndex, slices} = useTomogramCanvas({rN});
	
	const {activeRealIndex, real} = useSelector((state) => state.tomograms);
	const {synthetic, showSynthetic} = useSelector((state) => state.syntheticTomograms);
	const {labelMesh, labelMeshShow} = useSelector((state) => state.labelTomogram);
	/*console.log(synthetic[0][rN].stackHelper.slice.mesh.geometry)
	console.log(synthetic[0][rN].stackHelper.slice.mesh.material)
	const halfDimensionsIJK = synthetic[0][rN].stackHelper.stack.halfDimensionsIJK;
	const position = new THREE.Vector3()
		.copy(halfDimensionsIJK)
		.negate();
	const geom = useMemo(() => {
		const base = synthetic[0][rN].stackHelper.slice.mesh.geometry;
		base.merge(synthetic[2][rN].stackHelper.slice.mesh.geometry)
		return base
	}, [synthetic,rN]);
	
	setUniform(synthetic[2][rN]);
	setUniform(synthetic[0][rN]);*/

	return (
		<Canvas frameloop={"demand"} orthographic camera={camera} id={'test-' + rN} {...props} flat
		        resize={{resize: false}}>
			<Provider store={Store}>

				{/*<mesh position={position} geometry={synthetic[2][rN].stackHelper.slice.mesh.geometry}>
					<meshBasicMaterial attach="material" color={new THREE.Color('hotpink')} />
					<meshBasicMaterial attach="material" color="0xffffff" />
					<shaderMaterial transparent={true} side={THREE.DoubleSide}
					                fragmentShader={synthetic[2][rN].stackHelper.slice.mesh.material.fragmentShader}
					                vertexShader={synthetic[2][rN].stackHelper.slice.mesh.material.vertexShader}
					                uniforms={synthetic[2][rN].stackHelper.slice.mesh.material.uniforms}
					/>
				</mesh>*/}
				
				{real.filter((tm, i) => activeRealIndex === i).map((tm, i) =>
					<TomogramMesh rN={rN} tm={tm} zoom={camera.zoom} isSynthetic={false} key={i}/>
				)}
				{showSynthetic && synthetic.filter(filterSyntheticByActiveIndex).map((tm, i) =>
					<TomogramMesh rN={rN} tm={tm} isSynthetic={true} key={i}/>
				)}
				
				{labelMeshShow && labelMesh !== null &&
					<TomogramMesh rN={rN} tm={labelMesh} zoom={camera.zoom} isSynthetic={true}/>
				}
				
				{slices.map((slice, i) => <Localizer rN={slice} key={i}/>)}
				
				<LabelsLayer rN={rN}/>
				
				<MapControls screenSpacePanning={true} enableDamping={false} enableRotate={false} enableZoom={false}/>
				{/*<OrbitControls/>*/}
			
			</Provider>
		</Canvas>
	);
};
export default TomogramCanvas;