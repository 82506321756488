import React from 'react';
import {useSelector} from "react-redux";

import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import CommentForm from "../Form/CommentForm";
import useCommentUpdateForm from "./useCommentUpdateForm";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const CommentUpdateForm = () => {
	
	const {commentEditable} = useSelector((state) => state.comment);
	const {handleSubmit, fields, handleCancelClick} = useCommentUpdateForm({commentId: commentEditable.id});
	const _translates = useTranslate({translates});
	
	return (
		<Stack direction={"row"} alignItems={'stretch'} spacing={1}>
			<CommentForm initialValues={{comment: commentEditable.comment}} fields={fields} handleSubmit={handleSubmit}
			             messages={_translates} style={{flex: '1 1 auto'}}/>
			<IconButton variant="contained" type={'submit'} size={'small'} color={'error'}
			            onClick={handleCancelClick}><CloseIcon sx={{fontSize: 26, flex: "0 0 auto"}}/></IconButton>
		</Stack>
	);
};
export default CommentUpdateForm;