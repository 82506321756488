import React, {} from "react";
import {useSelector, useDispatch} from "react-redux";

import {createModeToggleRedux} from "@redux/drawLayer/actions";

import ToolbarButton from "./components/ToolbarButton";

import modesSvg from "./modesSvg";

import "./toolbar.scss"


const Toolbar = ({cls, ...props}) => {
	
	const dispatch = useDispatch();
	
	const {modes, createMode} = useSelector((state) => state.drawLayer);
	
	const getActiveClass = (mode) => (createMode === mode ? ' _active' : '');
	const buttonClick = (mode) => dispatch(createModeToggleRedux(createMode === mode ? null : mode));
	
	return (
		<div className={'toolbar ' + cls} {...props}>
			{modes.map((mode, i) => (
				<ToolbarButton key={i} extraClass={getActiveClass(mode)} onClick={buttonClick.bind(null, mode)}
				               title={`draw ${mode}`}
				               icon={modesSvg[mode]}
				/>
			))}
		</div>
	);
};
export default Toolbar;