import patientUpdateRequest from "@api/patient/update";
import {useDispatch} from "react-redux";

import {fields, initialValues} from "./fields";
import {patientUpdateRedux} from "@redux/patient/actions";
import {toast} from "react-toastify";


const usePatientEditForm = ({patientId,setOpen}) => {
	
	const dispatch = useDispatch();
	
	const onSubmit = async (values, {setSubmitting, setStatus}) => {
		
		const {isSuccess,data} = await patientUpdateRequest(patientId, values);
		
		if (isSuccess){
			dispatch(patientUpdateRedux(data));
			toast("информация обновлена!", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});
			setOpen(false)
			// setTimeout(() => setOpen(false), 500);
		}
		
		setStatus({success: isSuccess, text: isSuccess ? "success" : "error"});
		setTimeout(() => setStatus(null), 3000);
		
		setSubmitting(false);
	};
	return {
		onSubmit,
		fields,
		initialValues,
	}
};
export default usePatientEditForm;