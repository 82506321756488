const translates = {
	en: {
		successMessage: 'Patient was successfully created!',
		oneMoreButtonTitle: 'Create one more patient',
		goToPatientButtonTitle: 'Work with patient',
	},
	ru: {
		successMessage: 'Новый пациент создан!',
		oneMoreButtonTitle: 'Создать еще одного',
		goToPatientButtonTitle: 'Работа с пациентом',
	}
};
export default translates;