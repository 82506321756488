import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PROTOCOLS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const protocolUpdateRequest = async (protocolId, values) => {
	
	const result = await axiosService.put(buildUrl(`/${PROTOCOLS_ROOT}/${protocolId}`), JSON.stringify({...values}));
	
	return buildResponse(result);
};
export default protocolUpdateRequest;