import * as THREE from "three";
import {useSelector, useDispatch} from "react-redux";

import {
	activeTmPointSetRedux,
	activeTmSliceIndexesUpdateRedux,
	realUpdateSliceIndexRedux,
} from "@redux/tomograms/actions";
import {rayCastSetRedux} from "@redux/rayCast/actions";
// import getTmDataToUpdate from "../../canvas/TomogramCanvas/components/TomogramMesh/utils/getTmDataToUpdate";
import {labelMeshUpdateIndexRedux} from "@redux/labelTomogram/actions";
import {syntheticUpdateIndexRedux} from "@redux/syntheticTomograms/actions";


const useChangeTomogram = () => {
	
	const dispatch = useDispatch();
	
	const { real, activeRealIndex} = useSelector((state) => state.tomograms);
	const { synthetic} = useSelector((state) => state.syntheticTomograms);
	
	const { labelMesh} = useSelector((state) => state.labelTomogram);
	
	const { rayCast} = useSelector((state) => state.rayCast);
	const { activeTmPoint} = useSelector((state) => state.tomograms);
	
	const changeRealTomogram = (e, name,) => {
		
		if (real.length <=0) return false;

		const rN = real[activeRealIndex][name];
		const rNHelper = rN.stackHelper;

		const {x, y, z} = rayCast;
		
		if (rNHelper.index > 0 && rNHelper.index < rNHelper._orientationMaxIndex) {
			if (e.deltaY < 0 && rNHelper.index !== rNHelper._orientationMaxIndex - 1) {
				dispatch(realUpdateSliceIndexRedux(name, rNHelper.index + 1, activeRealIndex));
				dispatch(activeTmSliceIndexesUpdateRedux({[name]:rNHelper.index + 1}));
				dispatch(activeTmPointSetRedux({...activeTmPoint, [rN.axis]:activeTmPoint[rN.axis]+1}));
				const rayCastPoint = new THREE.Vector3(name === 'r2' ? x - 1 : x, name === 'r3' ? y - 1 : y, name === 'r1' ? z + 1 : z);
				dispatch(rayCastSetRedux(rayCastPoint));
			} else if (e.deltaY > 0 && rNHelper.index !== 1) {
				dispatch(realUpdateSliceIndexRedux(name, rNHelper.index - 1, activeRealIndex));
				dispatch(activeTmSliceIndexesUpdateRedux({[name]:rNHelper.index - 1}));
				dispatch(activeTmPointSetRedux({...activeTmPoint, [rN.axis]:activeTmPoint[rN.axis]-1}));
				const rayCastPoint = new THREE.Vector3(name === 'r2' ? x + 1 : x, name === 'r3' ? y + 1 : y, name === 'r1' ? z - 1 : z
				);
				dispatch(rayCastSetRedux(rayCastPoint));
			}
		}
		
	};
	const changeSyntheticTomogram = (e, name) => {
		
		if (synthetic.length <=0) return false;
		
		synthetic.forEach((item, index)=>{
			
			const rN = item[name];
			const rNHelper = rN.stackHelper;
			const {x, y, z} = rayCast;
			
			if (rNHelper.index > 0 && rNHelper.index < rNHelper._orientationMaxIndex) {
				if (e.deltaY < 0 && rNHelper.index !== rNHelper._orientationMaxIndex - 1) {
					dispatch(syntheticUpdateIndexRedux(name, rNHelper.index + 1, index));
					dispatch(activeTmSliceIndexesUpdateRedux({[name]:rNHelper.index + 1}));
					const rayCastPoint = new THREE.Vector3(name === 'r2' ? x-1 : x, name === 'r3' ? y-1 : y, name === 'r1' ? z+1 : z);
					dispatch(rayCastSetRedux(rayCastPoint));
				} else if (e.deltaY > 0 && rNHelper.index !== 1) {
					dispatch(syntheticUpdateIndexRedux(name, rNHelper.index - 1, index));
					dispatch(activeTmSliceIndexesUpdateRedux({[name]:rNHelper.index - 1}));
					const rayCastPoint = new THREE.Vector3(name === 'r2' ? x+1 : x, name === 'r3' ? y+1 : y, name === 'r1' ? z-1 : z);
					dispatch(rayCastSetRedux(rayCastPoint));
				}
			}
		})
		
		
	};
	const changeLabelTomogram = (e, name) => {
		
		if (labelMesh === null ) return false;
		
			const rN = labelMesh[name];
			const rNHelper = rN.stackHelper;
			const {x, y, z} = rayCast;
			
			if (rNHelper.index > 0 && rNHelper.index < rNHelper._orientationMaxIndex) {
				if (e.deltaY < 0 && rNHelper.index !== rNHelper._orientationMaxIndex - 1) {
					dispatch(labelMeshUpdateIndexRedux(name, rNHelper.index + 1));
					dispatch(activeTmSliceIndexesUpdateRedux({[name]:rNHelper.index + 1}));
					const rayCastPoint = new THREE.Vector3(name === 'r2' ? x-1 : x, name === 'r3' ? y-1 : y, name === 'r1' ? z+1 : z);
					dispatch(rayCastSetRedux(rayCastPoint));
				} else if (e.deltaY > 0 && rNHelper.index !== 1) {
					dispatch(labelMeshUpdateIndexRedux(name, rNHelper.index - 1));
					dispatch(activeTmSliceIndexesUpdateRedux({[name]:rNHelper.index - 1}));
					const rayCastPoint = new THREE.Vector3(name === 'r2' ? x+1 : x, name === 'r3' ? y+1 : y, name === 'r1' ? z-1 : z);
					dispatch(rayCastSetRedux(rayCastPoint));
				}
			}
	};
	return {
		changeRealTomogram,
		changeSyntheticTomogram,
		changeLabelTomogram,
	};
};
export default useChangeTomogram;