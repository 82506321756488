import { createAction } from 'redux-actions';

import {
	RAY_CAST_SET,
	RAY_CAST_INFLATED_SET,
	RAY_CAST_HELPER_SET,
	RAY_CAST_HELPER_TOGGLE,
} from './constants';


const rayCastSetAction = createAction(RAY_CAST_SET);
const rayCastInflatedSetAction = createAction(RAY_CAST_INFLATED_SET);
const rayCastHelperSetAction = createAction(RAY_CAST_HELPER_SET);
const rayCastHelperToggleAction = createAction(RAY_CAST_HELPER_TOGGLE);

export const rayCastSetRedux = (payload) => async (dispatch) => dispatch(rayCastSetAction(payload));
export const rayCastInflatedSetRedux = (payload) => async (dispatch) => dispatch(rayCastInflatedSetAction(payload));
export const rayCastHelperSetRedux = (payload) => async (dispatch) => dispatch(rayCastHelperSetAction(payload));
export const rayCastHelperToggleRedux = (payload) => async (dispatch) => dispatch(rayCastHelperToggleAction(payload));
