import {useState} from "react";


const useMedicalRecordCreateModal = () => {
	
	const [isOpen, setIsOpen] = useState(false);
	
	return {
		isOpen,
		setIsOpen,
	};
};
export default useMedicalRecordCreateModal;