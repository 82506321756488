export const REAL_CREATE = 'REAL_CREATE';
export const REAL_CLEAR = 'REAL_CLEAR';
export const REAL_UPDATE_SLICE_INDEX = 'REAL_UPDATE_SLICE_INDEX';
export const REAL_UPDATE_ACTIVE_INDEX = 'REAL_UPDATE_ACTIVE_INDEX';
export const REAL_SLICE_UPDATE_ALL_PERSPECTIVES_INDEX = 'REAL_SLICE_UPDATE_ALL_PERSPECTIVES_INDEX';

export const LUT_COUNTER_SET = 'LUT_COUNTER_SET';
export const ZOOM_SIZE_SET = 'ZOOM_SIZE_SET';
export const ACTIVE_TM_POINT_SET = 'ACTIVE_TM_POINT_SET';
export const ACTIVE_TM_SLICE_INDEXES_UPDATE = 'ACTIVE_TM_SLICE_INDEXES_UPDATE';
