import React from 'react';
import {useNavigate} from "react-router-dom";

import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import useTranslate from "@hooks/useTranslate";

import routes from "../../../../routes";

import translates from "./translates";


const HeaderBackButton = () => {
	
	const navigate = useNavigate();
	const {buttonTitle} = useTranslate({translates});
	
	const handleBackClick = () => navigate(-1);
	
	return (
		<>
			{window.location.pathname !== routes.mainMenu && (
				<Button variant="contained" style={{backgroundColor: 'white', color: "#1976d2"}} size={'small'}
			        onClick={handleBackClick} startIcon={<ArrowBackIosNewIcon/>}>{buttonTitle}</Button>)}
		</>
	);
};

export default HeaderBackButton;