import React from 'react';

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";


const HeadRow = ({title:{text="", variant="h6"}, btn, ...rest}) => {
	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" {...rest}>
			<Typography variant={variant} component={variant}>{text}</Typography>
			<Button size="small" variant="contained" onClick={btn.onClick}>{btn.text}</Button>
		</Stack>
	);
};
export default HeadRow;