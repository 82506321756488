import {parseUChar8} from "./index";


const parseUInt32EndianSwapped = (data, offset) => {
	const b0 = parseUChar8(data, offset);
	const b1 = parseUChar8(data, offset + 1);
	const b2 = parseUChar8(data, offset + 2);
	const b3 = parseUChar8(data, offset + 3);

	return (b0 << 24) + (b1 << 16) + (b2 << 8) + b3;
};
export default parseUInt32EndianSwapped;