import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {RAW_DATA_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const rawDataDeleteRequest = async (dataId) => {
	
	const result = await axiosService.delete(buildUrl(`/${RAW_DATA_ROOT}/${dataId}`));
	
	return buildResponse(result);
};
export default rawDataDeleteRequest;