import React, {} from "react";

import DrawerLayout from "@ui/layout/DrawerLayout";

import FavoriteBlock from "./components/FavoriteBlock/FavoriteBlock";
import ProcessingBlock from "./components/ProcessingBlock/ProcessingBlock";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import PatientCreateModal from "@Patient/CreateModal/PatientCreateModal";


const Home = () => {
	
	const [open, setOpen] = React.useState(false);
	
	const handleOpen = () => setOpen(true);
	
	return (
		<DrawerLayout>
			<FavoriteBlock/>
			<ProcessingBlock/>
			<SpeedDial
				ariaLabel="SpeedDial basic example"
				sx={{ position: 'absolute', bottom: 16, right: 16 }}
				icon={<SpeedDialIcon />}
				onClick={handleOpen}
			/>
			<PatientCreateModal open={open} setOpen={setOpen}/>
		</DrawerLayout>
	);
};
export default Home;
