import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const usePatientListPage = () => {
	const {pageTitle} = useTranslate({translates});
	return {
		pageTitle
	};
};
export default usePatientListPage;