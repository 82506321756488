import React from 'react';

import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

import Header from "@components/Header/Header";

import NavDrawer from "../NavDrawer/NavDrawer";

import {MAIN_DRAWER_WIDTH} from "../../constants";


const DrawerLayout = ({children}) => {
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<Header position="fixed" sx={{ width: `calc(100% - ${MAIN_DRAWER_WIDTH}px)`, ml: `${MAIN_DRAWER_WIDTH}px` }}/>
			<NavDrawer/>
			<Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
				<Toolbar />
				{children}
			</Box>
		</Box>
	);
};
export default DrawerLayout;