import {fields, initialValues} from "./fields";
import {useDispatch} from "react-redux";

import useComment from "@hooks/useComment";
import {commentEditableSetRedux} from "@redux/comment/actions";


const useCommentUpdateForm = ({commentId}) => {
	
	const dispatch = useDispatch();
	const {commentUpdate} = useComment();
	
	const handleSubmit = async (values, {setSubmitting, resetForm}) => {
		
		await commentUpdate(commentId, {...values});
		dispatch(commentEditableSetRedux(null));
		resetForm();
		setSubmitting(false);
	};
	const handleCancelClick = () => {
		dispatch(commentEditableSetRedux(null));
	};
	
	return {
		handleCancelClick,
		handleSubmit,
		fields,
		initialValues,
	};
};
export default useCommentUpdateForm;