import React from 'react';
import {useDispatch} from "react-redux";

import { protocolChangeStatusRedux, protocolDeleteRedux} from "@redux/protocol/actions";
import downloadTextAsFile from "@utils/downloadTextAsFile";
import protocolGetRoiRequest from "@api/protocol/getRoi";
import protocolUpdateRequest from "@api/protocol/update";
import protocolDeleteRequest from "@api/protocol/delete";
import commentGetAllRequest from "@api/comment/getAll";


const useProtocolItem = ({protocol}) => {
	
	const dispatch = useDispatch();
	
	const [anchorEl, setAnchorEl] = React.useState(null);
	
	const link = `${protocol.id}`;
	
	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	const handleSaveAsFile = async () => {
		
		const {isSuccess, data} = await protocolGetRoiRequest(protocol.id);
		const response = await commentGetAllRequest();
		
		const reducedRoi = data.reduce((acc, roiPoint)=>[...acc, {...roiPoint, comments:response.data.filter(comment=>comment.roi_id===roiPoint.id)}],[]);
		
		const str = reducedRoi.map(roi=>[` roi ${roi.id}: x=${roi.coord_x} y=${roi.coord_y} z=${roi.coord_z}`,` roi ${roi.id} comments:`, ...roi.comments.map(comment=>`  - ${comment.comment} (author: ${comment.author_id})`)].join('\n')).join('\n\n');
		
		if (isSuccess) {
			const fileName = `protocol-${protocol.id}(${protocol.state}).txt`;
			const fileText = [
				`protocol ${protocol.id}`,
				`status: ${protocol.state}`,
				``,
				`roi points:`,
				``,
				str,
			].join('\n');
			downloadTextAsFile(fileName, fileText);
			setAnchorEl(null);
		}
	};
	
	const handlePublish = async () => {
		const {isSuccess} = await protocolUpdateRequest(protocol.id, {state:'final'});
		isSuccess && dispatch(protocolChangeStatusRedux({protocolId:protocol.id, state:'final'}));
		setAnchorEl(null);
	};
	
	const handleDelete = async () => {
		const {isSuccess} = await protocolDeleteRequest(protocol.id);
		isSuccess && dispatch(protocolDeleteRedux({protocolId:protocol.id}));
		setAnchorEl(null);
	};
	
	return {
		handleSaveAsFile,
		handlePublish,
		handleDelete,
		handleClose,
		handleMenu,
		anchorEl,
		link,
	};
};

export default useProtocolItem;