import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

import useRoiPoint from "@hooks/useRoiPoint";

import roiCreateRequest from "@api/roi/create";
import protocolUpdateWithRoiRequest from "@api/protocol/updateWithRoi";


const useGltfRoiPointListHead = () => {
	
	const {dataId} = useParams();
	const {createPoint} = useRoiPoint();
	
	const {activeTmPoint, activeTmSliceIndexes} = useSelector((state) => state.tomograms);
	const {editableProtocolId} = useSelector((state) => state.protocol);
	const {rayCast} = useSelector((state) => state.rayCast);
	
	const {activeIndex, pointPos} = useSelector((state) => state.gltf);
	
	
	const handleButtonClick = async () => {
		console.log(pointPos)
		const newPoint = {
			note: `${JSON.stringify({...pointPos})};${JSON.stringify({...activeIndex})}`,
			coord_x: pointPos.x,
			coord_y: pointPos.y,
			coord_z: pointPos.z,
			synthetic_data_id: Number(dataId),
		};
		
		const {isSuccess, data} = await roiCreateRequest(newPoint);
		
		editableProtocolId !== null && await protocolUpdateWithRoiRequest(editableProtocolId,{"rois": [data.id]});
		
		isSuccess && createPoint(data);
	};
	
	return {
		handleButtonClick
	};
};

export default useGltfRoiPointListHead;