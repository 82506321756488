import React from 'react';
import {useSelector} from "react-redux";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const UserInfo = () => {
	
	const {gender:{male,female}} = useTranslate({translates});
	const {patient} = useSelector((state) => state.patient);
	
	return (
		<Stack spacing={0} alignItems={'center'} sx={{mb:2}}>
			<Typography variant="h5" component='h5'>{patient.first_name}</Typography>
			<Typography variant="h5" component='h5'>{patient.last_name}</Typography>
			<Typography variant="body2" component='p'>({patient.gender === 'm' ? male : female})</Typography>
		</Stack>
	);
};
export default UserInfo;