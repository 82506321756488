import {useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import patientGetProtocolsRequest from "@api/patient/getProtocols";
import {protocolListCreateRedux} from "@redux/protocol/actions";


const useProtocolList = () => {
	
	const dispatch = useDispatch();
	const {patientId} = useParams();
	
	const {protocolList} = useSelector(({protocol}) => protocol);
	
	const getProtocols = useCallback (async () => {
		const {isSuccess, data} = await patientGetProtocolsRequest(patientId);
		if (isSuccess){
			dispatch(protocolListCreateRedux(data))
		}
	},[]);
	
	useEffect(() => {
		getProtocols().catch((error) => console.error(error))
	}, []);
	
	return {
		protocolList
	};
};
export default useProtocolList;