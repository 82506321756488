export const PATIENT_CREATE = 'PATIENT_CREATE';
export const PATIENT_UPDATE = 'PATIENT_UPDATE';
export const PATIENT_DELETE = 'PATIENT_DELETE';

export const PATIENT_ARCHIVE_ADD = 'PATIENT_ARCHIVE_ADD';
export const PATIENT_ARCHIVE_DELETE = 'PATIENT_ARCHIVE_DELETE';

export const PATIENT_MEDICAL_RECORD_ADD = 'PATIENT_MEDICAL_RECORD_ADD';
export const PATIENT_MEDICAL_RECORD_DELETE = 'PATIENT_MEDICAL_RECORD_DELETE';

export const PATIENT_LIST_CREATE = 'PATIENT_LIST_CREATE';
export const PATIENT_MY_LIST_CREATE = 'PATIENT_MY_LIST_CREATE';

export const PATIENT_DATA_SET = 'PATIENT_DATA_SET';
export const PATIENT_SYNTH_DATA_SET = 'PATIENT_SYNTH_DATA_SET';