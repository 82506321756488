const translates = {
	en: {
		buttonTitle: 'load one more file',
		blockTitle: 'Medical data',
	},
	ru: {
		buttonTitle: 'загрузить еще файл',
		blockTitle: 'Медицинские данные',
	}
};
export default translates;