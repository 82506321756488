import React, { } from 'react';
import {useSelector} from "react-redux";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import CommentCreateForm from "@components/Comment/CreateForm/CommentCreateForm";
import CommentUpdateForm from "@components/Comment/UpdateForm/CommentUpdateForm";

import CommentsList from "../CommentsList/CommentsList";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";


const CommentsBlock = ({canWriteComments=true}) => {
	
	const {commentsActive, commentEditable} = useSelector((state) => state.comment);
	const {roiPointActive} = useSelector((state) => state.ROIPoint);
	
	const {title, caption} = useTranslate({translates});
	
	return (
		<>
			<Typography variant="h6" component="h2" sx={{mt: 3, mb: 1}}>{title} id={roiPointActive}</Typography>
			{commentsActive ===null && (<CircularProgress size={24}/>)}
			{commentsActive !==null && (<CommentsList comments={commentsActive}/>)}
			
			{commentsActive !==null && canWriteComments && (
				<Box sx={{mt:2}}>
					{commentEditable
						? (<CommentUpdateForm/>)
						: (<CommentCreateForm/>)
					}
				</Box>
			)}
			{!canWriteComments && <Typography variant="caption" display="block" gutterBottom>{caption}</Typography>}
		</>
	);
};

export default CommentsBlock;