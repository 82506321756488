import React, {} from "react";
import {Text} from "@react-three/drei";

import ROIPointCanvasList from "@ROIPoint/CanvasList/ROIPointCanvasList";
import ExtraPoints from "../ExtraPoints";
import getTextRotation from "../../../primitives/CanvasText/getTextRotation";


const LabelsLayer = ({rN}) => {
	return (
		<>
			<Text color="white" fontSize={12} lineHeight={1} rotation={getTextRotation(rN)} position={[-140,0,0]}>R</Text>
			<Text color="white" fontSize={12} lineHeight={1} rotation={getTextRotation(rN)} position={[140,0,0]}>L</Text>
			
			<Text color="white" fontSize={12} lineHeight={1} rotation={getTextRotation(rN)} position={[0,-140,0]}>Front</Text>
			<Text color="white" fontSize={12} lineHeight={1} rotation={getTextRotation(rN)} position={[0,140,0]}>Back</Text>
			
			<Text color="white" fontSize={12} lineHeight={1} rotation={getTextRotation(rN)} position={[0,0,140]}>Up</Text>
			<Text color="white" fontSize={12} lineHeight={1} rotation={getTextRotation(rN)} position={[0,0,-140]}>Down</Text>
			
			<ExtraPoints rN={rN}/>
			
			<ROIPointCanvasList rN={rN}/>
		</>
	);
};
export default LabelsLayer;