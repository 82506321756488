// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropZone {
  position: fixed;
  z-index: 10000000;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 2px solid white;
  outline-offset: -12px;
  font-size: 46px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/Viewer/components/DropZone/DropZone.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,wBAAA;EACA,qBAAA;EACA,eAAA;EACA,YAAA;AACJ","sourcesContent":[".dropZone {\n    position:fixed;\n    z-index:10000000;\n    height:100%;\n    width:100%;\n    background-color:rgba(0,0,0,1);\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    outline: 2px solid white;\n    outline-offset: -12px;\n    font-size: 46px;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
