import update from 'immutability-helper';
import {
	FAVORITES_SET,
	FAVORITE_DELETE,
	FAVORITE_ADD
} from "./constants";


const initialFavoriteState = {
	favorite:[],
};

export const favorite = (state = initialFavoriteState, action) => {
	switch (action.type) {
		
		case FAVORITES_SET:
			return update(state, {favorite: {$set: action.payload}});
			
		case FAVORITE_ADD:
			return update(state, {favorite: {$push: [action.payload]}});
			
		case FAVORITE_DELETE:
			const favoriteIndexToDelete = state.favorite.findIndex(favoriteItem => favoriteItem.id === action.payload);
			return update(state, {favorite: {$splice: [[favoriteIndexToDelete, 1]]}});
		
		default:
			return state;
	}
};
