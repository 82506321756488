class LocalStorage {

	constructor(key = '', defaultValue = '') {
		this.key = key;
		this.defaultValue = defaultValue;

		if (!localStorage.getItem(this.key)) {
			localStorage.setItem(this.key, JSON.stringify(this.defaultValue, null, 2))
		}
	}

	/**
	 *
	 * @param subj
	 * @returns {*}
	 */
	add(subj) {

		const curStore = JSON.parse(localStorage.getItem(this.key) || JSON.stringify(this.defaultValue, null, 2));

		const id = new Date().getTime();

		const joinedStore = [...curStore, {id:id, ...subj}];

		localStorage.setItem(this.key, JSON.stringify(joinedStore, null, 2));

		const updatedStore = JSON.parse(localStorage.getItem(this.key) || JSON.stringify(this.defaultValue, null, 2));

		const filteredByID = updatedStore.filter(item => item.id === id);

		return filteredByID.length !== 0 ? filteredByID[0] : null;
	}
	remove(id) {
		const curStore = JSON.parse(localStorage.getItem(this.key) || JSON.stringify(this.defaultValue, null, 2));
		const filteredByID = curStore.filter(item => item.id !== id);
		const newStore = [...filteredByID];
		localStorage.setItem(this.key, JSON.stringify(newStore, null, 2));
		return newStore;
	}
	
	update(id, newValue) {
		const curStore = JSON.parse(localStorage.getItem(this.key) || JSON.stringify(this.defaultValue, null, 2));
		const objIndex = curStore.findIndex((obj => obj.id === id));
		curStore[objIndex] = newValue;
		const newStore = [...curStore];
		localStorage.setItem(this.key, JSON.stringify(newStore, null, 2));
		return newValue;
	}
	/**
	 *
	 * @returns {any}
	 */
	getList() {
		const curStore = JSON.parse(localStorage.getItem(this.key) || JSON.stringify(this.defaultValue, null, 2));
		return curStore;
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	get(id) {
		const curStore = JSON.parse(localStorage.getItem(this.key) || JSON.stringify(this.defaultValue, null, 2));
		const filteredByID = curStore.filter(item => item.id + '' === id);

		return filteredByID.length !== 0 ? filteredByID[0] : null;
	}
}

export default LocalStorage;