import {useState} from "react";


const useInstrDataCreateModal = () => {
	
	const [isOpen, setIsOpen] = useState(false);
	const [initialOverride, setInitialOverride] = useState({});
	
	return {
		isOpen,
		setIsOpen,
		initialOverride,
		setInitialOverride,
	};
};
export default useInstrDataCreateModal;