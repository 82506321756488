import {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import favoriteGetAllLocal from "../../../../localStore/favorite/getAll";

import useFavorite from "@hooks/useFavorite";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const useFavoriteIconButton = () => {
	
	const {favoriteCreate, favoriteDelete} = useFavorite();
	const {favoriteAdd, favoriteRemove} = useTranslate({translates});
	
	const [isInFav, setIsInFav] = useState(null);
	const [favId, setFavId] = useState(null);
	
	const {patient} = useSelector((state) => state.patient);
	
	
	const toolTipTitle = isInFav ? favoriteRemove : favoriteAdd;
	
	const handleToFavoriteClick = async ()=>{
		if (isInFav === null) return false;
		
		isInFav ? await removeFromFavorite() : await addToFavorite();
	};
	
	const addToFavorite = async ()=>{
		const fav = await favoriteCreate(patient);
		setFavId(fav.id);
		setIsInFav(true);
	};
	
	const removeFromFavorite = async ()=>{
		await favoriteDelete(favId);
		setIsInFav(false);
		setFavId(null)
	};
	
	useEffect(()=>{
		favoriteGetAllLocal().then((res)=>{
			if (res.length === 0){
				setFavId(null);
				setIsInFav(false);
			} else {
				const filteredFav = res.filter(fav=>fav.data_id === patient.id );
				setFavId(filteredFav.length >0 ? filteredFav[0].id : null);
				setIsInFav(filteredFav.length >0);
			}
		})
	},[patient]);
	
	return {
		handleToFavoriteClick,
		toolTipTitle,
		isInFav,
	};
};
export default useFavoriteIconButton;